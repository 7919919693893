const query = (id, isPreview) => `

{
  blockSlider(id:"${id}", preview: ${isPreview}) {
    title
    variant
    internalTitle
    description
    cardsCollection{
      items{
        variant
        heading
        description
        isReversed
        elementId
        showBackground
        variant
        images {
          internalTitle
          altText
          imageDesktop {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          imageMobile {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
        }

        link {
          label
          title
          url
          target
          scrollToElementId
          gaEventClass
          gaEventLabel
          image {
            title
            description
            url
            width
            height
          }
        }
      }
    }
    sliderSettings {
      internalTitle
      slidesToShowDesktop
      slidesToScrollDesktop
      slidesToShowTab
      slidesToScrollTab
      slidesToShowMobile
      slidesToScrollMobile
      
    }
  }
}

  `

module.exports = { query }
