export const LinkProps = {
  label: "MEHR ERFAHREN",
  title: "MEHR ERFAHREN",
  url: "/neues/haselnusscreme",
  target: "_self",
  scrollToElementId: null,
  gaEventClass: null,
  gaEventLabel: null,
  image: null,
  className:
    "linkWrpr p-10 border-[#3b2774] bg-[#93d1f4] px-[30px] md:px-[40px] pt-[10px] pb-[8px] text-[#3b2774] rounded-[22px] center text-[16px] tracking-[2px] leading-[22px] font-IntervogueMedium text-purple",
};
