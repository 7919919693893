import React from "react"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { get } from "../../helpers/utils"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"
import { PRIVACY_NOTICE } from "../../constants/label.constants"

const EXT_BLOCKS = {
  TABLE_BODY: 'table-body'
}

const RichTextModel = props => {
  const { richText, title, link, style } = props
  const { json: jsonData, links } = richText
  const getHeadingId = value => {
    if(title == PRIVACY_NOTICE) {
      const findId = value &&  value.split(".")
      return "C".concat(findId[0])
    }
    return ""
  }
  const getEmbeddedEntryData = id => {
    const inlineData = get(links, "entries.inline", [])
    return inlineData.find(entry => entry?.sys?.id === id)
  }
  const RICHTEXT_OPTIONS = {
    renderText: text =>
      text
        .split("<br>")
        .flatMap((textContent, i) => [
          i > 0 && <br key={`br_${i}`} />,
          <span key={i} dangerouslySetInnerHTML={{__html: `${textContent}`}} />,
        ]),
    RenderMark: {
      [MARKS.CODE]: text => <code className={style?.code}>{text}</code>,
      [MARKS.SUBSCRIPT]: text => <sub className={style?.code}>{text}</sub>,
      [MARKS.SUPERSCRIPT]: text => <sup className={style?.code}>{text}</sup>
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Link url={node?.data?.uri} className={style?.link}>
            {children}
          </Link>
        )
      },

      [INLINES.EMBEDDED_ENTRY]: node => {
        const entryId = get(node, "data.target.sys.id", "")
        const entry = getEmbeddedEntryData(entryId)
        let embedEntry = ""
        switch (entry?.__typename) {
          // case "superScript":
          //   embedEntry = <sup>{entry.superScriptText}</sup>
          //   break
          // case "subScriptText":
          //   embedEntry = <sub>{entry.subScript}</sub>
          //   break
          case "Link": 
            embedEntry = 
            <Link url={entry.url} target={entry.target} className={style?.link}>
              <u><b>{entry?.label}</b></u>
            </Link>
            break
          default:
            break
        }
        return embedEntry
      },

      [BLOCKS.PARAGRAPH]: (node, children) => {
        children =
          children &&
          children.length > 0 &&
          children.filter(child =>
            typeof child === "string" && child === "" ? false : true
          )
        return (
          children &&
          children.length > 0 && <p className={style?.paragraph}>{children}</p>
        )
      },

      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 id={getHeadingId(node?.content[0]?.value.trim())}>{children}</h2>
      },
      [BLOCKS.TABLE]: (node, children) => {
        return <table className={style?.table}>{children}</table>
      },
      [EXT_BLOCKS.TABLE_BODY]: (node, children) => {
        return <tbody className={style?.table}>{children}</tbody>
      },
      [BLOCKS.TABLE]: (node, children) => {
        return <table className={style?.table}>{children}</table>
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
        return <tr className={style?.tRow}>{children}</tr>
      },

      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        return <th className={style?.tHeader}>{children}</th>
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
        return <td className={style?.tData}>{children}</td>
      },

      [BLOCKS.OL_LIST]: (node, children) => {
        let listItems = []
        if (children.length > 0) {
          listItems = children.map((child, index) => (
            <span key={index} id={index + 1}>
              {child}
            </span>
          ))
        }
        return <ol>{listItems}</ol>
      },
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className={style?.ulList}>{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => {
        //to remove p tage from <li>
        const normalisedListChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => (
              <li className={style?.list}>{children}</li>
            ),
          },
        });
        return normalisedListChildren;
      },
    },
  }



  return (
    <div className={style?.richTextWrapper}>
      {title && <h1 className={style?.title}>{title}</h1>}
      {documentToReactComponents(jsonData, RICHTEXT_OPTIONS)}
      {link && <Link {...link} className={style?.mainLink} />}
    </div>
  )
}
export default RichTextModel

RichTextModel.propTypes = {
  richText: PropTypes.object,
  title: PropTypes.string,
  link: PropTypes.object,
  style: PropTypes.object,
}
