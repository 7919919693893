import React, { useState, useEffect } from "react"
import ModalWrapper from "../Wrappers/ModalWrapper/ModalWrapper"
import SocialLinks from "../UI/SocialLinks/SocialLinks"
import Image from "../UI/Image/Image"
import * as styles from "./IconButton-tw-styles"
import PropTypes from "prop-types"
import { labels } from "../../constants/label.constants"
import useScreenSize from "../../hooks/useScreenSize"
import { imageConstants } from "../../constants/image.constants"

import {
  getIconButtonCollection,
  getbuttonCollection,
  getIconButtonCollectionMobile,
  getURL,
} from "../../helpers/BuildImageCardObject"

const IconButton = props => {
  const screen = useScreenSize()
  const [isModalOpen, setModalState] = useState(false)
  const [printCount, setPrintValue] = useState(0)
  const { keyValue, variant, links } = props
  const { image = {}, socialLinks = [], title } = links || {}

  const iconButtonCollection = getIconButtonCollection(socialLinks)
  const iconButtonCollectionMobile = getIconButtonCollectionMobile(socialLinks)
  const buttonCollection = getbuttonCollection(socialLinks)

  useEffect(() => {
    if (isModalOpen) {
      const body = document.body
      body.classList.add("overflow-hidden")
    }
    return () => {
      document.body.classList.remove("overflow-hidden")
    }
  }, [isModalOpen])

  useEffect(() => {
    if (printCount) {
      // let printContents = document.getElementById(keyValue).innerHTML
      // let originalContents = document.body.innerHTML
      // document.body.innerHTML = printContents
      // window?.print()
      // document.body.innerHTML = originalContents
      if (typeof window !== "undefined") {
        window?.print()
      }
    }
    return ()=>{setPrintValue(0)}
  }, [printCount])

  const getFormattedUrl = icons => {
    let iconCollection =
      icons?.length &&
      icons.map(e => {
        const url = getURL({
          iconName: e.label,
          title: e.label,
          url: e.url,
          gaEventClass: e.gaEventClass,
          gaEventLabel: e.gaEventClass,
        })
        return { ...e, url }
      })
    return iconCollection
  }

  const style = styles[variant] || styles.defaultStyle
  const socialLinksItems = getFormattedUrl(iconButtonCollection)
  const socialLinksItemsMoile = getFormattedUrl(iconButtonCollectionMobile)

  const closeModal = () => {
    setModalState(false)
  }
  const printOnclick = () => {
    setPrintValue(printCount+1)
  }
  const bookmarkOnclick = () => {
    setModalState(true)
  }
  return (
    <div>
      <div className={style?.iconBtnWpr}>
        <Image className={style?.iconBtnimg} src={image?.url} width="55px" />
        {title}
      </div>
      {screen?.isMobile ? (
        <SocialLinks
          socialLinks={socialLinksItemsMoile}
          showLabel={true}
          variant={labels.socialIconVar}
        />
      ) : (
        <>
          <SocialLinks
            socialLinks={socialLinksItems}
            showLabel={true}
            variant={labels.socialIconVar}
          />
          <div className={style?.btnImgwpr}>
            {buttonCollection?.length &&
              buttonCollection.map((btn, index) => (
                <button
                  onClick={() =>
                    btn?.label.toLowerCase() === labels.print
                      ? printOnclick()
                      : bookmarkOnclick()
                  }
                  className={style?.iconBtn}
                  key={index}
                >
                  <Image
                    className={style?.btnImg}
                    src={btn?.image?.url}
                    width="49px"
                  />
                  {btn.label && (
                    <span className={style?.icontxt}>{btn.label}</span>
                  )}
                </button>
              ))}
          </div>
        </>
      )}

      {isModalOpen && (
        <ModalWrapper
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          variant="textPopup"
          image={imageConstants.close}
        >
          <div>
            <div>
              <h2 className="text-3xl leading-10 mx-0 mb-2 mt-px text-center">  
                <span>{labels.modelheading}</span>
              </h2>
            </div>
            <div>
              <p className="mx-0 mt-0 mb-18 leading-[19.8px]">{labels.modelDes1}</p>
              <p className="mx-0 mt-0 mb-18 leading-[19.8px]">
                {labels.modelDes2}
                <span className="leading-[19.8px]"> {labels.modelDes3}</span>
              </p>
              <p className="mx-0 mt-0 mb-18 leading-[19.8px]">
                {labels.modelDes4}
                <span className="leading-[19.8px]"> {labels.modelDes5}</span>
              </p>
            </div>
          </div>
        </ModalWrapper>
      )}
    </div>
  )
}

IconButton.propTypes = {
  keyValue: PropTypes.string,
  variant: PropTypes.string,
  links: PropTypes.object,
}
IconButton.defaultProps = {
  variant: "",
}
export default IconButton
