import React from "react";
import { screen, render, fireEvent, act } from "@testing-library/react";
import CustomDropdown from "./CustomDropdown";
import { CustomDropdownData } from "../../../mocks/CustomDropdown.mock";

describe('CustomDropdown', () => {
  let view = null

  beforeEach(() => {
    view = render(<CustomDropdown {...CustomDropdownData} />)
  })

  test('should render as not required', () => {
    view = render(<CustomDropdown {...CustomDropdownData} isRequired={false} />)
    expect(screen.getAllByText('Customdropdown label'))
  })

  test('should render as required', () => {
    expect(screen.getAllByText('Customdropdown label*'))
  })

  test('should open dropdown on click', () => {
    const { container } = view
    const dropdown_ele = container.querySelectorAll('.customDropdown>div')[0]
    fireEvent.click(dropdown_ele)
    const options_ele = container.querySelectorAll('.sortDropdownOpened')
    expect(options_ele.length).toEqual(1)
  })

  test('should able to select option', () => {
    global.innerWidth = 640
    jest.spyOn(window.screen, "width", "get").mockReturnValue(640);
    view = render(<CustomDropdown {...CustomDropdownData} />)
    act(() => {
      global.dispatchEvent(new Event('resize'));
    })
    const { container } = view
    const dropdown_ele = container.querySelectorAll('.customDropdown>div')[0]
    fireEvent.click(dropdown_ele)
    const option_ele = container.querySelectorAll('.sortDropdownOpened>p')[0]
    fireEvent.click(option_ele)
    // expect(screen.getAllByText('Sort By Option 1'))
  })

  test('should able to render in desktop style', () => {
    global.innerWidth = 1280
    jest.spyOn(window.screen, "width", "get").mockReturnValue(1280);
    view = render(<CustomDropdown {...CustomDropdownData} />)
    act(() => {
      global.dispatchEvent(new Event('resize'));
    })
    const { container } = view
    const dropdown_ele = container.querySelectorAll('.customDropdown>div')[0]
    fireEvent.click(dropdown_ele)
    const option_ele = container.querySelectorAll('.sortDropdownOpened>p')[0]
    fireEvent.click(option_ele)
    fireEvent.click(dropdown_ele)
    const selected_option_ele = container.querySelectorAll('p.selected_option')
    expect(selected_option_ele.length).toEqual(1)
  })

})