const query = (id, isPreview )=> `
{
    breadCrumb( id: "${id}", preview: ${isPreview}) {
     internalTitle
     variant
     navigationCollection(limit: 10) {
      items {
        label
        link {
          label
          title
          url
          target
          scrollToElementId
          image {
            title
            description
            url
            width
            height
          }
        }
      }
    }
 
  }
}
`
module.exports = { query }