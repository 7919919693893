import React from "react";
import { render, screen } from "@testing-library/react";
import CtaBlock from "./CtaBlock";
import { ctaBlockData } from "../../mocks/CtaBlock.mock";

describe("CtaBlock", () => {
  let view = null;
  beforeEach(() => {
    view = render(<CtaBlock {...ctaBlockData} />);
  });

  test("should render component", () => {
    expect(screen.getAllByText(ctaBlockData.heading));
  });

  test("should render background image if passed", () => {
    const { container } = view;
    const div_ele_style = container.querySelectorAll(".socialCtaContainer")[0]
      .style;
    expect(div_ele_style).toHaveProperty(
      "background-image",
      "url(https://testurl.jpg?fm=webp&q=80)"
    );
  });

  test("should render Link", () => {
    const { container } = view;
    const div_ele_style = container.querySelectorAll('a[href="/neues/"]')
    expect(div_ele_style.length).toEqual(1);
  });
});
