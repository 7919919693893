export const ProductListingData = {
  "internalTitle": "Alle Produkte",
  "heading": "Alle Produkte",
  "productFilter": {
    "title": "Filter",
    "filterItemCollection": {
      "items": [
        {
          "title": "Products",
          "apiText": "PRODUKTE",
          "uniqueKey": "FilterProduct",
          "itemCollection": {
            "items": [
              {
                "title": "bars",
                "keyName": "bars",
                "apiText": "Riegel",
                "categoryKey": null,
                "count": 11
              },
              {
                "title": "large panels",
                "keyName": "big_tablets",
                "apiText": "Grosstafeln",
                "categoryKey": null,
                "count": 17
              },
              {
                "title": "sweets",
                "keyName": "sweets",
                "apiText": "Bonbons",
                "categoryKey": null,
                "count": 0
              }
            ]
          }
        },
        {
          "title": "Variety And Ingredients",
          "apiText": "SORTE UND ZUTATEN",
          "uniqueKey": "FilterIngredients",
          "itemCollection": {
            "items": [
              {
                "title": "Mandel",
                "keyName": "almond",
                "apiText": "Mandel",
                "categoryKey": null,
                "count": 9
              },
              {
                "title": "Biscuit",
                "keyName": "biscuit",
                "apiText": "Keks",
                "categoryKey": "schoko-snacks",
                "count": 31
              },
              {
                "title": "Caramel",
                "keyName": "caramel",
                "apiText": "Caramel",
                "categoryKey": null,
                "count": 15
              }
            ]
          }
        },
        {
          "title": "Occasions",
          "apiText": "ANLÄSSE",
          "uniqueKey": "FilterOccasion",
          "itemCollection": {
            "items": [
              {
                "title": "Best wishes from",
                "keyName": "best_wishes",
                "apiText": "Alles Gute Wünschen",
                "categoryKey": null,
                "count": 2
              },
              {
                "title": "Birthday",
                "keyName": "birthday",
                "apiText": "Geburtstag",
                "categoryKey": null,
                "count": 39
              },
              {
                "title": "Our classics",
                "keyName": "classics",
                "apiText": "Unsere Klassiker",
                "categoryKey": null,
                "count": 47
              }
            ]
          }
        }
      ]
    }
  },
  "productList": [
    {
      "ProductID": "30215",
      "ProductImage": [
        {
          "type": "milka_new_thumbnail_253x198",
          "url": "https://images.sweetauthoring.com/product/103489.png"
        },
        {
          "type": "milka_new_large_460x380",
          "url": "https://images.sweetauthoring.com/product/103488.png"
        }
      ],
      "ProductTitle": "Milka Peanut Crisp 90g",
      "ProductName": "",
      "SeoPageName": "milka-peanut-crisp-90g-renovated",
      "ProductLongDescription": "<p>Milka Peanut Crisp ist eine köstliche Mischung aus zartschmelzender Milka Alpenmilch Schokolade mit knackigen Erdnüssen, knusprigen Cornflakes, Rice Crispies und leckeren Karamellstückchen.\r\nEin ganz besonderes Geschmackserlebnis erwartet dich: So zart kann Erdnuss sein.</p>",
      "ProductIngredients": "Zutaten: Zucker, Kakaobutter, <strong>Erdnüsse</strong>, <strong>Magermilchpulver</strong>, Kakaomasse, Süßmolkenpulver (aus <strong>Milch</strong>), <strong>Butterreinfett</strong>, Reismehl, Emulgatoren, (<strong>Sojalecithin</strong>, E 476), Glukosesirup, <strong>Haselnussmasse</strong>, Maismehl, Speisesalz, Aromen, Farbstoff (Carotin, E150d), <strong>Gerstenmalzextrakt</strong>. Kakao: 33% mindestens in der Alpenmilch Schokolade<br>\r\n<strong>Kann enthalten: andere Nüsse und Weizen</strong>\r\n",
      "FilterProduct": "tablets",
      "FilterIngredients": "nut,caramel,peanut,crispy,salty",
      "RelatedProducts": "30241,30299,30271",
      "ProductPrice": "4",
      "PriceInformation": "15,44 € /1kg",
      "FilterType": "purchasable",
      "FilterOccasion": "",
      "MetaDescription": "Milka Peanut Crisp ist eine Tafel Schokolade mit Erdnüssen, Cornflakes, Rice Crispies und Karamellstückchen. Köstlich",
      "SiteCoreContent": "<p>Trocken lagern und vor Wärme schützen.</p>",
      "NutritionalTable": [
        {
          "title": "Pro 100 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "2251 kJ /<br> 539 kcal",
              "gda": "2000",
              "percent": "27.0%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "9.1g",
              "gda": "50g",
              "percent": "18.2%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "54.0g",
              "gda": "260g",
              "percent": "20.8%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "51.0g",
              "gda": "90g",
              "percent": "56.7%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "31.0g",
              "gda": "70g",
              "percent": "44.3%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "15.0g",
              "gda": "20g",
              "percent": "75.0%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "2.7g",
              "gda": "24g",
              "percent": "11.3%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.28g",
              "gda": "6g",
              "percent": "4.7%"
            }
          ]
        },
        {
          "title": "Pro 15 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "338 kJ /<br> 81 kcalg",
              "gda": "2000",
              "percent": "4.0%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "1.4g",
              "gda": "50g",
              "percent": "2.8%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "8.1g",
              "gda": "260g",
              "percent": "3.1%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "7.7g",
              "gda": "90g",
              "percent": "8.6%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "4.7g",
              "gda": "70g",
              "percent": "6.7%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "2.3g",
              "gda": "20g",
              "percent": "11.5%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "0.4g",
              "gda": "24g",
              "percent": "1.7%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.04g",
              "gda": "6g",
              "percent": "0.7%"
            }
          ]
        }
      ],
      "ProductCategoryIds": [
        "24097"
      ],
      "pageSlug": "/produkte/milka-peanut-crisp-90g-renovated-30215/"
    },
    {
      "ProductID": "30215",
      "ProductImage": [],
      "ProductTitle": "Milka Peanut Crisp 90g",
      "ProductName": "",
      "SeoPageName": "milka-peanut-crisp-90g-renovated",
      "ProductLongDescription": "<p>Milka Peanut Crisp ist eine köstliche Mischung aus zartschmelzender Milka Alpenmilch Schokolade mit knackigen Erdnüssen, knusprigen Cornflakes, Rice Crispies und leckeren Karamellstückchen.\r\nEin ganz besonderes Geschmackserlebnis erwartet dich: So zart kann Erdnuss sein.</p>",
      "ProductIngredients": "Zutaten: Zucker, Kakaobutter, <strong>Erdnüsse</strong>, <strong>Magermilchpulver</strong>, Kakaomasse, Süßmolkenpulver (aus <strong>Milch</strong>), <strong>Butterreinfett</strong>, Reismehl, Emulgatoren, (<strong>Sojalecithin</strong>, E 476), Glukosesirup, <strong>Haselnussmasse</strong>, Maismehl, Speisesalz, Aromen, Farbstoff (Carotin, E150d), <strong>Gerstenmalzextrakt</strong>. Kakao: 33% mindestens in der Alpenmilch Schokolade<br>\r\n<strong>Kann enthalten: andere Nüsse und Weizen</strong>\r\n",
      "FilterProduct": "tablets",
      "FilterIngredients": "nut,caramel,peanut,crispy,salty",
      "RelatedProducts": "30241,30299,30271",
      "ProductPrice": "4",
      "PriceInformation": "15,44 € /1kg",
      "FilterType": "purchasable",
      "FilterOccasion": "",
      "MetaDescription": "Milka Peanut Crisp ist eine Tafel Schokolade mit Erdnüssen, Cornflakes, Rice Crispies und Karamellstückchen. Köstlich",
      "SiteCoreContent": "<p>Trocken lagern und vor Wärme schützen.</p>",
      "NutritionalTable": [
        {
          "title": "Pro 100 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "2251 kJ /<br> 539 kcal",
              "gda": "2000",
              "percent": "27.0%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "9.1g",
              "gda": "50g",
              "percent": "18.2%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "54.0g",
              "gda": "260g",
              "percent": "20.8%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "51.0g",
              "gda": "90g",
              "percent": "56.7%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "31.0g",
              "gda": "70g",
              "percent": "44.3%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "15.0g",
              "gda": "20g",
              "percent": "75.0%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "2.7g",
              "gda": "24g",
              "percent": "11.3%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.28g",
              "gda": "6g",
              "percent": "4.7%"
            }
          ]
        },
        {
          "title": "Pro 15 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "338 kJ /<br> 81 kcalg",
              "gda": "2000",
              "percent": "4.0%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "1.4g",
              "gda": "50g",
              "percent": "2.8%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "8.1g",
              "gda": "260g",
              "percent": "3.1%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "7.7g",
              "gda": "90g",
              "percent": "8.6%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "4.7g",
              "gda": "70g",
              "percent": "6.7%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "2.3g",
              "gda": "20g",
              "percent": "11.5%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "0.4g",
              "gda": "24g",
              "percent": "1.7%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.04g",
              "gda": "6g",
              "percent": "0.7%"
            }
          ]
        }
      ],
      "ProductCategoryIds": [
        "24097"
      ],
      "pageSlug": "/produkte/milka-peanut-crisp-90g-renovated-30215/"
    },
    {
      "ProductID": "30215",
      "ProductImage": [
        {
          "type": "milka_new_thumbnail_253x198",
          "url": "https://images.sweetauthoring.com/product/103489.png"
        },
      ],
      "ProductTitle": "Milka Peanut Crisp 90g",
      "ProductName": "",
      "SeoPageName": "milka-peanut-crisp-90g-renovated",
      "ProductLongDescription": "<p>Milka Peanut Crisp ist eine köstliche Mischung aus zartschmelzender Milka Alpenmilch Schokolade mit knackigen Erdnüssen, knusprigen Cornflakes, Rice Crispies und leckeren Karamellstückchen.\r\nEin ganz besonderes Geschmackserlebnis erwartet dich: So zart kann Erdnuss sein.</p>",
      "ProductIngredients": "Zutaten: Zucker, Kakaobutter, <strong>Erdnüsse</strong>, <strong>Magermilchpulver</strong>, Kakaomasse, Süßmolkenpulver (aus <strong>Milch</strong>), <strong>Butterreinfett</strong>, Reismehl, Emulgatoren, (<strong>Sojalecithin</strong>, E 476), Glukosesirup, <strong>Haselnussmasse</strong>, Maismehl, Speisesalz, Aromen, Farbstoff (Carotin, E150d), <strong>Gerstenmalzextrakt</strong>. Kakao: 33% mindestens in der Alpenmilch Schokolade<br>\r\n<strong>Kann enthalten: andere Nüsse und Weizen</strong>\r\n",
      "FilterProduct": "tablets",
      "FilterIngredients": "nut,caramel,peanut,crispy,salty",
      "RelatedProducts": "30241,30299,30271",
      "ProductPrice": "4",
      "PriceInformation": "15,44 € /1kg",
      "FilterType": "purchasable",
      "FilterOccasion": "",
      "MetaDescription": "Milka Peanut Crisp ist eine Tafel Schokolade mit Erdnüssen, Cornflakes, Rice Crispies und Karamellstückchen. Köstlich",
      "SiteCoreContent": "<p>Trocken lagern und vor Wärme schützen.</p>",
      "NutritionalTable": [
        {
          "title": "Pro 100 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "2251 kJ /<br> 539 kcal",
              "gda": "2000",
              "percent": "27.0%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "9.1g",
              "gda": "50g",
              "percent": "18.2%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "54.0g",
              "gda": "260g",
              "percent": "20.8%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "51.0g",
              "gda": "90g",
              "percent": "56.7%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "31.0g",
              "gda": "70g",
              "percent": "44.3%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "15.0g",
              "gda": "20g",
              "percent": "75.0%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "2.7g",
              "gda": "24g",
              "percent": "11.3%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.28g",
              "gda": "6g",
              "percent": "4.7%"
            }
          ]
        },
        {
          "title": "Pro 15 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "338 kJ /<br> 81 kcalg",
              "gda": "2000",
              "percent": "4.0%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "1.4g",
              "gda": "50g",
              "percent": "2.8%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "8.1g",
              "gda": "260g",
              "percent": "3.1%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "7.7g",
              "gda": "90g",
              "percent": "8.6%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "4.7g",
              "gda": "70g",
              "percent": "6.7%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "2.3g",
              "gda": "20g",
              "percent": "11.5%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "0.4g",
              "gda": "24g",
              "percent": "1.7%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.04g",
              "gda": "6g",
              "percent": "0.7%"
            }
          ]
        }
      ],
      "ProductCategoryIds": [
        "24097"
      ],
      "pageSlug": "/produkte/milka-peanut-crisp-90g-renovated-30215/"
    },
    {
      "ProductID": "30216",
      "ProductImage": [
        {
          "type": "milka_new_thumbnail_253x198",
          "url": "https://images.sweetauthoring.com/product/103487.png"
        },
        {
          "type": "milka_new_large_460x380",
          "url": "https://images.sweetauthoring.com/product/103486.png"
        }
      ],
      "ProductTitle": "Milka Oreo Sandwich 92g",
      "ProductName": "",
      "SeoPageName": "milka-oreo-sandwich-92g",
      "ProductLongDescription": "<p>Zusammen unschlagbar wie Milka & Oreo Sandwich Mit Milka Oreo Sandwich bilden zarte Milka Alpenmilch Schokolade und 10 Mini Original Oreo Kekse mit leckerer Oreo-Crème ein unschlagbares Geschmackserlebnis. Die Tafelschokolade überrascht mit innovativer Form und spannender Textur. Der perfekte Snack für zwischendurch. </p>",
      "ProductIngredients": "Zutaten: Zucker, <strong>Weizenmehl</strong>, Kakaobutter, <strong>Magermilchpulver</strong>, pflanzliche Fette (Palm, Palmkern), Kakaomasse, Süßmolkenpulver (aus <strong>Milch</strong>), <strong>Butterreinfett</strong>, fettarmes Kakaopulver (1,5%), Glukose-Fruktose-Sirup, <strong>Weizenstärke</strong>, Emulgator (<strong>Sojalecithine</strong>), Backtriebmittel (E503, E501, E500), Speisesalz, Säureregulator (E524), Aromen. 7% Magermilchpulver in der Milchcrème. <br />Kakao: 30% mindestens in der Alpenmilch Schokolade<br /> <strong>Kann enthalten: Nüsse</strong>",
      "FilterProduct": "tablets",
      "FilterIngredients": "nut,oreo,biscuit,crispy,milk_creme",
      "RelatedProducts": "30242,30894,30225",
      "ProductPrice": "5",
      "PriceInformation": "15,11 € /1kg",
      "FilterType": "purchasable",
      "FilterOccasion": "",
      "MetaDescription": "Schon probiert? Eine Tafel Schokolade aus zarter Milka Alpenmilch Schokolade und 10 Mini Original Oreo Kekse mit leckerer Oreo-Crème",
      "SiteCoreContent": "<p>Trocken lagern und vor Wärme schützen.</p>",
      "NutritionalTable": [
        {
          "title": "Pro 100",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "2168 kJ /<br> 518 kcal",
              "gda": "2000",
              "percent": "25.9%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "5.7g",
              "gda": "50g",
              "percent": "11.4%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "61.0g",
              "gda": "260g",
              "percent": "23.5%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "50.0g",
              "gda": "90g",
              "percent": "55.6%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "27.0g",
              "gda": "70g",
              "percent": "38.6%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "16.0g",
              "gda": "20g",
              "percent": "80.0%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "2.1g",
              "gda": "24g",
              "percent": "8.8%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.51g",
              "gda": "6g",
              "percent": "8.5%"
            }
          ]
        },
        {
          "title": "Pro 18.4 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "399 kJ /<br> 95 kcalg",
              "gda": "2000",
              "percent": "4.8%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "1.1g",
              "gda": "50g",
              "percent": "2.2%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "11.0g",
              "gda": "260g",
              "percent": "4.2%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "9.1g",
              "gda": "90g",
              "percent": "10.1%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "5.0g",
              "gda": "70g",
              "percent": "7.1%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "2.9g",
              "gda": "20g",
              "percent": "14.5%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "0.4g",
              "gda": "24g",
              "percent": "1.7%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.09g",
              "gda": "6g",
              "percent": "1.5%"
            }
          ]
        }
      ],
      "ProductCategoryIds": [
        "24097"
      ],
      "pageSlug": "/produkte/milka-oreo-sandwich-92g-30216/"
    },
    {
      "ProductID": "30221",
      "ProductImage": [
        {
          "type": "milka_new_thumbnail_253x198",
          "url": "https://images.sweetauthoring.com/product/103495.png"
        },
        {
          "type": "milka_new_large_460x380",
          "url": "https://images.sweetauthoring.com/product/103494.png"
        }
      ],
      "ProductTitle": "Milka Bunte Kakaolinsen 100g",
      "ProductName": "",
      "SeoPageName": "milka-bunte-kakaolinsen-100g-renovated",
      "ProductLongDescription": "<p>Die einzigartige Kombination aus bunten Kakaolinsen und zarter Milka Alpenmilch Schokolade sorgt für noch mehr Vielfalt und abwechslungsreichen Schoko-Spaß. <br /><br /><br /><a href=\"http://www.amazon.de/dp/B0073B7V92/ref=nosim?tag=milkatafeln-21\" target=\"_blank\"><img src=\"/milka2/images/dede1/pictures/produkte/milka-amazon_btn.png\" /></a></p>",
      "ProductIngredients": "Zutaten: Zucker, Kakaobutter, Kakaomasse, <strong>Magermilchpulver</strong>, Süßmolkenpulver (aus <strong>Milch</strong>), <strong>Butterreinfett</strong>, fettarmes Kakaopulverr, Emulgatoren (<strong>Sojalecithine</strong>, Sonnenblumenlecithine), Überzugsmittel (Gummi arabicum, Schellack, Bienenwachs, weiß und gelb, Carnaubawachs),  <strong>Haselnussmasse</strong>, Reisstärke, <strong>Vollmilchpulver</strong>, Farbstoffe (Riboflavine, Betanin (Betenrot), Carotin, Anthocyane), Aroma. <br> Kakao: 33% mindestens in der Alpenmilch Schokolade.<br>\r\n<strong>‎Kann enthalten: andere Nüsse und Weizen</strong>\r\n\r\n",
      "FilterProduct": "tablets",
      "FilterIngredients": "nut,crispy",
      "RelatedProducts": "30230,30243,30281",
      "ProductPrice": "2",
      "PriceInformation": "13,90 € /1kg",
      "FilterType": "purchasable",
      "FilterOccasion": "classics",
      "MetaDescription": "Diese Tafel Schokolade bringt Schoko-Spaß. Bunte Kakaolinsen und zarte Milka Alpenmilch Schokolade – jetzt probieren",
      "SiteCoreContent": "<p>Trocken lagern und vor Wärme schützen.</p>",
      "NutritionalTable": [
        {
          "title": "Pro 100 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "2187 kJ/<br>523 kcal",
              "gda": "2000",
              "percent": "26.2%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "5.7g",
              "gda": "50g",
              "percent": "11.4%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "60.0g",
              "gda": "260g",
              "percent": "23.1%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "59.0g",
              "gda": "90g",
              "percent": "65.6%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "28.0g",
              "gda": "70g",
              "percent": "40.0%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "17.0g",
              "gda": "20g",
              "percent": "85.0%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "2.4g",
              "gda": "24g",
              "percent": "10.0%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.25g",
              "gda": "6g",
              "percent": "4.2%"
            }
          ]
        },
        {
          "title": "Pro 16,7 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "365 kJ /<br>87 kcal",
              "gda": "2000",
              "percent": "4.3%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "0.9g",
              "gda": "50g",
              "percent": "1.8%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "10.0g",
              "gda": "260g",
              "percent": "3.8%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "9.8g",
              "gda": "90g",
              "percent": "10.9%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "4.7g",
              "gda": "70g",
              "percent": "6.7%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "2.8g",
              "gda": "20g",
              "percent": "14.0%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "0.4g",
              "gda": "24g",
              "percent": "1.7%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.04g",
              "gda": "6g",
              "percent": "0.7%"
            }
          ]
        }
      ],
      "ProductCategoryIds": [
        "24097"
      ],
      "pageSlug": "/produkte/milka-bunte-kakaolinsen-100g-renovated-30221/"
    },
    {
      "ProductID": "30222",
      "ProductImage": [
        {
          "type": "milka_new_thumbnail_253x198",
          "url": "https://images.sweetauthoring.com/product/103487.png"
        },
        {
          "type": "milka_new_large_460x380",
          "url": "https://images.sweetauthoring.com/product/103486.png"
        }
      ],
      "ProductTitle": "milka tuc cracker 87g",
      "ProductName": "",
      "SeoPageName": "milka-tuc-cracker-87g",
      "ProductLongDescription": "<p>Du liebst dieses Milka Produkt? Dann lass es uns und andere wissen und gib deine Produktbewertung ab.<br /><br />Wenn Kultmarken sich vereinen, entsteht Herausragendes: Milka Alpenmilch Schokolade & TUC Cracker überraschen die Sinne – - mit innovativer Form, spannender Textur und einem einzigartigen Genusserlebnis. Außen 20 knusprige TUC Kult-Cracker und innen zarte Alpenmilch Schokolade - <br> eine außergewöhnliche Komposition. <br><br><br><a href=\"http://www.amazon.de/dp/B00AF2QXTK/ref=nosim?tag=milkatafeln-21\" target=\"_blank\"><img src=\"/milka2/images/dede1/pictures/produkte/milka-amazon_btn.png\" /></a></p>",
      "ProductIngredients": "Zutaten: Zucker, Kakaobutter, <strong>Weizenmehl</strong>, <strong>Magermilchpulver</strong>, Kakaomasse, Süßmolkenpulver (aus <strong>Milch</strong>),<strong> Butterreinfett</strong>, Palmöl, Glukosesirup, <strong>Gerstenmalzextrakt</strong>, Emulgatoren (<strong>Sojalecithine</strong>, E476), Speisesalz, <strong>Weizenstärke</strong>, Backtriebmittel (E503, E500, E450), <strong>Butter</strong>, Aromen, <strong>Vollmilchpulver</strong>, Mehlbehandlungsmittel (<strong>Natriummetabisulfit</strong>).\r\n<br />Kakao: 30% mindestens in der Alpenmilch Schokolade.\r\n<br><strong>Kann enthalten: Ei.</strong>\r\n",
      "FilterProduct": "tablets",
      "FilterIngredients": "nut,biscuit,crispy,salty",
      "RelatedProducts": "30223,30243,30216",
      "ProductPrice": "",
      "PriceInformation": "15,98 € /1kg",
      "FilterType": "purchasable",
      "FilterOccasion": "",
      "MetaDescription": "Außen 20 knusprige TUC Kult-Cracker – innen zarte Alpenmilch Schokolade. Ein Genuss. Probiere diese Tafel Schokolade",
      "SiteCoreContent": "<p>Trocken lagern und vor Wärme schützen.</p>",
      "NutritionalTable": [
        {
          "title": "Pro 100 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "2175 kJ <br />520 kcal",
              "gda": "2000",
              "percent": "26.0%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "6.4g",
              "gda": "50g",
              "percent": "12.8%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "60.0 g",
              "gda": "260g",
              "percent": "23.1%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "50.0g",
              "gda": "90g",
              "percent": "55.6%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "28.0g",
              "gda": "70g",
              "percent": "40.0%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "16.0g",
              "gda": "20g",
              "percent": "80.0%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "1.9g",
              "gda": "24g",
              "percent": "7.9%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.69g",
              "gda": "6g",
              "percent": "11.5%"
            }
          ]
        },
        {
          "title": "Pro 17.4 g",
          "rowData": [
            {
              "id": 0,
              "value": "Energie (Brennwert)",
              "serving": "378 kJ <br />90 kcal",
              "gda": "2000",
              "percent": "4.5%"
            },
            {
              "id": 1,
              "value": "Eiweiß",
              "serving": "1.1g",
              "gda": "50g",
              "percent": "2.2%"
            },
            {
              "id": 2,
              "value": "Kohlenhydrate",
              "serving": "10g",
              "gda": "260g",
              "percent": "3.8%"
            },
            {
              "id": 3,
              "value": "davon Zucker",
              "serving": "8.7g",
              "gda": "90g",
              "percent": "9.7%"
            },
            {
              "id": 4,
              "value": "Fett",
              "serving": "4.8g",
              "gda": "70g",
              "percent": "6.9%"
            },
            {
              "id": 5,
              "value": "davon gesättigte Fettsäuren",
              "serving": "2.8g",
              "gda": "20g",
              "percent": "14.0%"
            },
            {
              "id": 6,
              "value": "Ballaststoffe",
              "serving": "0.3g",
              "gda": "24g",
              "percent": "1.3%"
            },
            {
              "id": 7,
              "value": "Salz",
              "serving": "0.12 g",
              "gda": "6g",
              "percent": "2.0%"
            }
          ]
        }
      ],
      "ProductCategoryIds": [
        "24097"
      ],
      "pageSlug": "/produkte/milka-tuc-cracker-87g-30222/"
    },
  ],
  "categoryData": {},
  "productListingTitle": "Alle Produkte"
}