export const SitemapData = {
  linksCollection: {
    items: [
      {
        link: {
          label: 'Link 1',
          url: '/link-1-href'
        }
      },
      {
        link: {
          label: 'Link 2',
          url: '/link-2-href',
        },
        dropdownItemsCollection: {
          items: [
            {
              label: 'Link 2.1',
              url: '/link-2-1-href'
            }
          ]
        }
      }
    ]
  }
}