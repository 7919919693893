export const defaultStyle = {
  blockSlider: "total-blockslider-area mt-10 md:mt-0 lg:mt-[3px]",
  blockSliderWrapper: "block-slider-area w-full relative mb-70 md:mb-90",
  heading: "text-2xl md:text-4xl font-light mb-60 md:mb-80",
  blockSlide: "pr-30 md:pl-75 md:pr-25",
  subHeading: "text-sm md:text-sm font-light mb-10 md:mb-30",
  description: "text-sm md:text-xl pb-15",
  imgWrpr: "max-w-full h-auto mt-20 md:mt-0",
  // blockSlide:"blockSlide flex",
};
