export const props = {
  headerItems: {
    logo: {
      url: "https://images.ctfassets.net/oqg67vmf6aj9/2QMPHUgDX4OPR02gbua2Ds/43da9b0aea1c8823d98ab8ef3b39f60a/milka-logo-white-desktop.png",
      title: "Milka Desktop - White Logo",
      description: "Milka Desktop White Logo",
      width: 252,
      height: 122,
    },
    mobileLogo: {
      url: "https://images.ctfassets.net/oqg67vmf6aj9/71ZsA2HTzkOrYNjbvz9zt5/0e3c9258726f9e89ff3b81e14bb3a32d/milka-logo-white-mobile.png",
      title: "Milka Mobile - White Logo",
      description: "Milka Mobile - White Logo",
      width: 186,
      height: 90,
    },
    navigationCollection: {
      items: [
        {
          label: "PRODUKTE",
          link: {
            label: null,
            title: null,
            url: "/alle-produkte",
            target: "_self",
            scrollToElementId: null,
            image: null,
          },
        },
        {
          label: "NEUES",
          link: {
            label: null,
            title: null,
            url: "/neues",
            target: "_self",
            scrollToElementId: null,
            image: null,
          },
        },
        {
          label: "ÜBER MILKA",
          link: {
            label: null,
            title: null,
            url: "/uber-milka/",
            target: "_self",
            scrollToElementId: null,
            image: null,
          },
        },
      ],
    },
    icons: {
      label: "Header Icon Container",
      iconLinksCollection: {
        items: [
          {
            label: "Account Icon",
            title: "Account Icon",
            url: "https://www.fcmilka.de/account",
            target: "_self",
            scrollToElementId: null,
            gaEventClass: null,
            gaEventLabel: null,
            image: {
              title: "Account PNG",
              description: "Account PNG",
              url: "https://images.ctfassets.net/oqg67vmf6aj9/1jda1wjhysmchzSgDV3r2p/6da10a459474375f49ad968cd558ccdb/accountIcon.png",
              width: 128,
              height: 128,
            },
          },
          {
            label: "Newsletter Icon",
            title: "Newsletter",
            url: "https://www.milka.de/newsletter",
            target: "_self",
            scrollToElementId: null,
            gaEventClass: null,
            gaEventLabel: null,
            image: {
              title: "News Letter PNG",
              description: "News Lettter PNG",
              url: "https://images.ctfassets.net/oqg67vmf6aj9/1O1knlhQAL8bM0kEGx75em/8b6dade2b954123c18a832a5fe6835e8/icon-newsletter.png",
              width: 37,
              height: 28,
            },
          },
          {
            label: "Contact Icon",
            title: "Contact Icon",
            url: "https://contactus.mdlzapps.com/form?siteId=2LW7sJNrXGKP52yKGxUeMw%3D%3D",
            target: "_blank",
            scrollToElementId: null,
            gaEventClass: null,
            gaEventLabel: null,
            image: {
              title: "Contact SVG",
              description: "Contact SVG",
              url: "https://images.ctfassets.net/oqg67vmf6aj9/1bHZYlas82nMFZEig5dZIS/37865056eb631c54961831899081d3a6/at.svg",
              width: 1000,
              height: 1000,
            },
          },
          {
            label: "Globe Icon",
            title: "Globe Icon",
            url: "https://www.milka.com/",
            target: "_self",
            scrollToElementId: null,
            gaEventClass: null,
            gaEventLabel: null,
            image: {
              title: "Globe SVG",
              description: "Globe SVG",
              url: "https://images.ctfassets.net/oqg67vmf6aj9/2YPyODSxhfOEG0wY2bU5kp/8fd1a4eff2ceadc85f271b8cdee46e87/globe.svg",
              width: 1000,
              height: 1000,
            },
          },
        ],
      },
    },
    mobileIcons: {
      label: "Header Mobile Icon Container",
      iconLinksCollection: {
        items: [
          {
            label: "Account Icon",
            title: "Account Icon",
            url: "https://www.fcmilka.de/account",
            target: "_self",
            scrollToElementId: null,
            gaEventClass: null,
            gaEventLabel: null,
            image: {
              title: "Account PNG",
              description: "Account PNG",
              url: "https://images.ctfassets.net/oqg67vmf6aj9/1jda1wjhysmchzSgDV3r2p/6da10a459474375f49ad968cd558ccdb/accountIcon.png",
              width: 128,
              height: 128,
            },
          },
          {
            label: "Newsletter Icon",
            title: "Newsletter",
            url: "https://www.milka.de/newsletter",
            target: "_self",
            scrollToElementId: null,
            gaEventClass: null,
            gaEventLabel: null,
            image: {
              title: "News Letter PNG",
              description: "News Lettter PNG",
              url: "https://images.ctfassets.net/oqg67vmf6aj9/1O1knlhQAL8bM0kEGx75em/8b6dade2b954123c18a832a5fe6835e8/icon-newsletter.png",
              width: 37,
              height: 28,
            },
          },
        ],
      },
    },
    desktopIconCollection: {
      "items": [
        {
          "label": "Globe Icon",
          "link": {
            "label": "Globe",
            "title": "Globe",
            "url": "https://www.milka.com/",
            "target": "_self",
            "scrollToElementId": null,
            "gaEventClass": "event_button_click",
            "gaEventLabel": "Globe",
            "customClassName": "",
            "image": {
              "title": "Globe Icon",
              "description": "Globe Icon",
              "url": "https://images.ctfassets.net/f5cs11awg9jh/2YPyODSxhfOEG0wY2bU5kp/1574db5a6c5c5944ff3e1af6b7c5901e/globe.svg",
              "width": 1000,
              "height": 1000
            }
          }
        }
      ]
    }
  },
};
