import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import Typography from "../UI/Typography/Typography";
import * as styles from "./BlockSlider-tw-styles";
import ImageWithText from "../ImageWithText/ImageWithText";
import CustomButton from "./CustomButton";
import Link from "../UI/Link/Link";
import {LAPTOP, TAB, MOBILE} from "../../constants/breakPoints.constant"
import { gaLeftlabel, gaRightlabel, gaSliderClass, dotsLabel } from "../../constants/gtm.constants";

const BlockSlider = props => {
  const {
    title,
    cardsCollection,
    description,
    sliderSettings,
    variant,
  } = props;
  const {
    slidesToShowDesktop,
    slidesToScrollDesktop,
    slidesToShowTab,
    slidesToScrollTab,
    slidesToShowMobile,
    slidesToScrollMobile,
  } = sliderSettings || {};
  const style = styles[variant] || styles.defaultStyle;
  
  const settings = {
    arrows: true,
    dots: variant !== 'SLIDER_WITH_PRODUCTCARD' && variant !== 'SLIDER_MIDDLE_ZOOM_CARD' && true,
    fade: variant !== 'SLIDER_WITH_PRODUCTCARD' && variant !== 'SLIDER_MIDDLE_ZOOM_CARD' && true,
    className: variant === 'SLIDER_MIDDLE_ZOOM_CARD' && "center",
    centerPadding: variant === 'SLIDER_MIDDLE_ZOOM_CARD' && "0px",
    centerMode: variant === 'SLIDER_MIDDLE_ZOOM_CARD' && true,
    adaptiveHeight: false,
    infinite: variant === 'SLIDER_WITH_PRODUCTCARD' || variant === 'SLIDER_MIDDLE_ZOOM_CARD' && true,
    autoplaySpeed: 3000,
    slidesToShow: slidesToShowDesktop,
    slidesToScroll: slidesToScrollDesktop,
    nextArrow: <CustomButton  gaEventClass={gaSliderClass} gaEventLabel={gaRightlabel}/>,
    prevArrow: <CustomButton  gaEventClass={gaSliderClass} gaEventLabel={gaLeftlabel}/>,
    customPaging: index => {
      return (
        <button className={gaSliderClass} data-action-detail={dotsLabel}>
          {index + 1}
        </button>  
      ) 
    },
    responsive: [
      {
        breakpoint: LAPTOP,
        settings: {
          slidesToShow: slidesToShowDesktop,
          slidesToScroll: slidesToScrollDesktop,

        },
      },
      {
        breakpoint: TAB,
        settings: {
          slidesToShow: slidesToShowTab,
          slidesToScroll: slidesToScrollTab,
          centerMode: variant === 'SLIDER_WITH_PRODUCTCARD' || variant === 'SLIDER_MIDDLE_ZOOM_CARD' && true,
          centerPadding: variant === 'SLIDER_MIDDLE_ZOOM_CARD' ? "0px" : "80px",
        },
      },
      {
        breakpoint: MOBILE,
        settings: {
          slidesToShow: slidesToShowMobile,
          slidesToScroll: slidesToScrollMobile,
          dots: variant !== 'SLIDER_WITH_PRODUCTCARD' && variant !== 'SLIDER_MIDDLE_ZOOM_CARD' && true,
          arrows: variant == 'SLIDER_MIDDLE_ZOOM_CARD' && true,
          centerMode: variant === 'SLIDER_MIDDLE_ZOOM_CARD' && true,
          centerPadding: variant === 'SLIDER_MIDDLE_ZOOM_CARD' ? "0px": "90px", 
        },
      },
    ],
  };
  return (
    <div className={style?.blockSlider}>
      <div className={style?.blockSliderWrapper}>
        <Slider {...settings}>
          {cardsCollection &&
            cardsCollection?.items?.map((item, index) => (
              <div className={style?.blockSlide} key={`slide_${index}`} id={`slide_${index}`}>
                {item?.link && variant === 'SLIDER_WITH_PRODUCTCARD' ? (
                  <Link {...item?.link}>
                    <ImageWithText {...item} />
                  </Link>
                ): (
                  <ImageWithText {...item} />
                )}
              </div>
            ))}
        </Slider>
        <div className={style?.textContentBox}>
          {title && <h4 className={style?.heading}>{title}</h4>}
          {description && (
            <Typography className={style?.subHeading} content={description} />
          )}
        </div>
      </div>
    </div>
  );
};

BlockSlider.propTypes = {
  title: PropTypes.string,
  cardsCollection: PropTypes.object,
  description: PropTypes.string,
  sliderSettings: PropTypes.object,
  variant: PropTypes.string,
};

export default BlockSlider;
