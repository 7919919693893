import React from "react";
import PropTypes from "prop-types"
import { get } from "../../helpers/utils";
import Link from "../UI/Link/Link";
import * as styles from "./Sitemap-tw-styles"

const Sitemap = props => {
  const { heading, linksCollection, variant } = props
  const mainLinks = get(linksCollection, "items", [])

  const style = styles[variant] || styles.defaultStyle

  return (
    <div>
      {heading && <div className={style.header}>{}</div>}
      {mainLinks && <div className={style.linksWrpr}>
        <ul className={style.listWrpr}> {mainLinks?.map((item, index) => (
          <li className={style.list} key={index}>
            <Link className={style.link} {...item?.link}>{item?.label}</Link>
            {item?.dropdownItemsCollection?.items.length>0 && <ul className={style.innerlistWrpr}>
              {item?.dropdownItemsCollection?.items?.map((link , index) => (
                <li className={style.innerlist} key={index}><Link className={style.innerlink} {...link}>{link?.label}</Link></li>
              ))}
            </ul>}
          </li>
        ))} </ul> 
      </div>}        
    </div>
  )
}

Sitemap.propTypes = {
  heading: PropTypes.string,
  linksCollection: PropTypes.object,
  variant: PropTypes.string
}

export default Sitemap