import React from "react";
import { render, screen, fireEvent } from "@testing-library/react";
import ImageWithText from "./ImageWithText";
import { imageWithTextdata } from "../../mocks/imageWithText.mock";

describe("ImageWithText", () => {
  let view = null;
  beforeEach(() => {
    view = render(<ImageWithText {...imageWithTextdata} />);
  });

  test("should render Intial Data", () => {
    render(<ImageWithText {...imageWithTextdata} />);
  });

  test("should render component", () => {
    expect(screen.getAllByText("It's time to get inspired!")).toBeInTheDocument;
  });
  test("should render Image", () => {
    const {container } = view;
    expect(container.querySelectorAll('img')).toBeInTheDocument;
  });

  test("should render Link", () => {
    expect(screen.getAllByText("MEHR ERFAHREN")).toBeInTheDocument;
  });

  test("should render Image and src to be true", () => {
    const imageElement = document.querySelector("img");
    expect(imageElement.src).toContain(
      "https://images.ctfassets.net/oqg67vmf6aj9/2UBwEiAykZUiWSPLkkiOOl/fce7e1fdc9757d5c123893c936fd71fe/NationalPark.png"
    );
  });
});
