const query =id => `
{
  textContent (id: "${id}") {
      title
      shortDescription
      link {
        label
        title
        url
        target
        scrollToElementId
        image {
          title
          description
          url
          width
          height
        }
        gaEventClass
        gaEventLabel
      }
      variant
  }
}
`

module.exports = {query}