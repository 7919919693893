import React from "react";
import { screen, render, fireEvent, act, waitFor } from "@testing-library/react";
import ModalWrapper from "./ModalWrapper";
import { ModalWrapperProps } from "../../../mocks/ModalWrapper.mock";

describe('SignUp', () => {
  let view = null
  beforeEach(async () => {
    await act(async () =>  view = render(<ModalWrapper {...ModalWrapperProps} />));
  })


  test('should render content', () => {
    expect(screen.getAllByText('Hello')).toBeInTheDocument
  })


  test('should Click Close Button', () => {
    const header_ele = screen.getByTestId('closeBtn')
    fireEvent.click(header_ele)
    expect(screen.getAllByText('Hello')).not.toBeInTheDocument
  })

  
  test('should key Press key: Escape ', () => {
    const { container } = view
    fireEvent.keyDown(container, {
      key: "Escape",
      code: "Escape",
      keyCode: 27,
      charCode: 27
    });
    expect(screen.getAllByText('Hello')).not.toBeInTheDocument
  })

  test('should key Press key: Esc', () => {
    const view2 = render(<ModalWrapper {...ModalWrapperProps} variant={"variant"} />);
    const { container } = view2;
    fireEvent.keyDown(container, {
      key: "Esc",
      code: "Esc",
      keyCode: 27,
      charCode: 27
    });
    expect(screen.getAllByText('Hello')).not.toBeInTheDocument
  })
  
})