
export const Props = {
  "button": {},
  "errorMessage": "",
  "maxLength": 10,
  "type": "",
  "value": "",
  "onChange": () =>{},
  "onBlur": () =>{},
  "id": "email",
  "placeholder": "E-Mail-Adresse*",
  "name": "email",
  "label": "E-Mail-Adresse*",
  "isRequired": true,
  "errorMessage": "",
  "value": "",
  "isValid": false,
  "variant": "emailSignUp"
}
  
export const Props1 = {
  "button": {id: "id", name: 'name'},
  "errorMessage": "",
  "maxLength": 20,
  "type": "",
  "value": "",
  "onChange": () =>{},
  "onBlur": () =>{},
  "id": "email",
  "placeholder": "E-Mail-Adresse*",
  "name": "email",
  "label": "E-Mail-Adresse*",
  "isRequired": true,
  "errorMessage": "",
  "value": "",
  "isValid": true,
  "variant": ""
}