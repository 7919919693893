import React from "react";
import { screen, render, fireEvent } from "@testing-library/react";
import ImageCard from "./ImageCard";
import { ImageCardProps } from "../../../mocks/ImageCard.mocks";

describe("ListComponent", () => {
  let view = null;
  beforeEach(() => {
    view = render(<ImageCard {...ImageCardProps} />);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("showing internalTitle ", () => {
    expect("Shop Card6 Image - NEW").toBeInTheDocument;
  });

  test(" Variant sholuld be TWO_COLUMN_IMAGE ", () => {
    expect("TWO_COLUMN_IMAGE").toBeInTheDocument;
  });

  test("showing video title", () => {
    expect(screen.findByTitle("Star Den"));
  });

  test("should render without variant props", () => {
    view = render(<ImageCard {...ImageCardProps} variant={null} />);
    expect(screen.findByTitle("Star Den"));
  });

  test("should add active class on mouse enter", () => {
    view = render(<ImageCard {...ImageCardProps} 
      variant={'ROW_REVERSE_CARD'} 
      title={'Heading Image'} 
    />);
    const { container } = view
    const header_ele = container.querySelectorAll('.heading')[0]
    fireEvent.mouseEnter(header_ele)
    const active_class_ele = container.querySelectorAll('.transiImg')
    expect(active_class_ele.length).toEqual(1);
  });

  test("should remove active class on mouse leave", () => {
    view = render(<ImageCard {...ImageCardProps} 
      variant={'ROW_REVERSE_CARD'} 
      title={'Heading Image'} 
    />);
    const { container } = view
    const header_ele = container.querySelectorAll('.heading')[0]
    fireEvent.mouseLeave(header_ele)
    const active_class_ele = container.querySelectorAll('.transiImg')
    expect(active_class_ele.length).toEqual(0);
  });

});
