const query = (id, isPreview) => `{
  landingShopCards(id: "${id}", preview: ${isPreview}) {
      title
      backgroundImage{
        title
        description
        url
        width
        height
      }
      cardsCollection {
          items {
              title
              text
              elementId
              variant
              gaEventClass
              gaEventLabel
              images {
                  internalTitle
                  altText
                  imageDesktop {
                    title
                    description
                    url
                    width
                    height
                  }
                  imageMobile {
                    title
                    description
                    url
                    width
                    height
                  }
                  bgDesktop {
                    title
                    description
                    url
                    width
                    height
                  }
                  bgMobile {
                    title
                    description
                    url
                    width
                    height
                  }
                }
              link {
                  label
                  title
                  url
                  target
                  scrollToElementId
                  gaEventClass
                  gaEventLabel
                  image {
                      title
                      description
                      url
                      width
                      height
                  }
              }
          }
      }
      variant
  }
}`
module.exports = { query }
