import React from "react";
import { fireEvent, screen, render, act } from "@testing-library/react";
import SearchInput from "./SearchInput";
import { SearchInputProps } from "../../../mocks/SearchInput.mock";

describe("SearchInput", () => {
  let view = null;
  beforeEach(() => {
    view = render(<SearchInput {...SearchInputProps} onSearchSubmit={() =>{}} onChange={() => {}}/>);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render with on keyDown", async() => {
    const input = screen.getAllByPlaceholderText('ARA')
    fireEvent.keyDown(input[0], {
      key: "Enter",
      code: 13,
      keyCode: 13,
      charCode: 13
    })
  });
});