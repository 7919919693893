import React from "react";
import { screen, render, within } from "@testing-library/react";
import Link from "./Link";
import { LinkProps } from "../../../mocks/Link.mocks";

describe("ListComponent", () => {
  let view = null;
  beforeEach(() => {
    view = render(<Link {...LinkProps} />);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render label", () => {
    let label = screen.getByText("MEHR ERFAHREN");
    expect(label).toBeInTheDocument;
  });

  test("showing Title ", () => {
    let title = screen.getByText("MEHR ERFAHREN");
    expect(title).toBeInTheDocument;
  });
});
