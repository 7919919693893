import PropTypes from "prop-types"
import React from "react"
const SidebarWrapper = ({
  children,
  className,
  sidebartitle,
  footerMenuRightArea,
}) => {
  return (
    <div className={className}>
      {sidebartitle && <h4 className={footerMenuRightArea}>{sidebartitle}</h4>}
      {children}
    </div>
  )
}
SidebarWrapper.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  sidebartitle: PropTypes.string,
  footerMenuRightArea: PropTypes.string,
}
export default SidebarWrapper