import React from "react";
import { screen, render } from "@testing-library/react";
import RichTextModel from "./RichTextModel";
import { RichTextModelData } from "../../mocks/RichTextModel.mock";

describe('RichTextModel', () => {
  let view = null

  beforeEach(() => {
    view = render(<RichTextModel {...RichTextModelData} />)
  })

  test('should render component', () => {
    expect(screen.getAllByText('NUTZUNGSBASIERTE ONLINE-WERBUNG'))
  })

  test('should render link', () => {
    view = render(<RichTextModel {...RichTextModelData} link={{
      label: 'Link-1',
      url: '/link-1-href'
    }} />)
    expect(screen.getAllByText('Link-1'))
  })
})