export const input_type = {
  text: " form-control block w-full px-3 py-1.5 text-base font-normal   text-gray-700  bg-white bg-clip-padding  border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  number:
    "form-control block w-full  px-3  py-1.5  text-base  font-normal  text-gray-700  bg-white bg-clip-padding border border-solid border-gray-300  rounded-full  transition  ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  email:
    "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
}

export const formInputStyle = {
  inputContainer: "lg:mb-3 mb-27 xl:w-96",
  labelStyle: "inline-block p-5 text-lg leading-[22px] text-white  ",
  text: "form-control block w-full p-13 text-base font-normal text-gray-700  bg-white bg-clip-padding  border border-solid  rounded-[25px]  border-solid border-2 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none formInput border-2",
  select:
    "form-select bg-dropDownArrow bg-no-repeat bg-right appearance-none block w-full p-14 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat  border border-solid  rounded-[25px] transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none bg-[94% center]  bg-[0% 96%]",
  chkWrpr: "flex my-20 odd:pb-20 relative",
  checkBox:
    "form-check-input before:h-[35px] before:w-[35px] absolute before:rounded-[0.3rem] before:bg-white custCheckBox before:absolute before:border-2  rounded-[20px] before:content-['']  focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer formInput border-2 before:border-inputBrdr",
  checkBoxText:
    "inline-block p-5 text-[15px] leading-[21px] text-white lg:w-[455px]  lg:pr-0 w-full pl-50 aggrementCheckbox",
  error: "border-2 border-red-600 error",
}

export const defaultStyle = {
  inputContainer: "lg:mb-3 mb-27 xl:w-96 w-[100%] lg:w-[60%]",
  labelStyle: "font-bold text-[16px] text-white",
  text: "form-control block w-full px-3 py-1.5 text-base font-normal   text-gray-700  bg-white bg-clip-padding  border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  select: "",
  chkWrpr: "flex p-5 mb-20 mt-[-41px] lg:mt-[0px] text-white bg-[#7d69ac] text-sm items-start",
  checkBox: "mr-[10px]",
  checkBoxText: "",
  error: "border border-red-500",
}

export const signUpStyle = {
  labelStyle: "inline-block text-sm mb-2 text-[#2b2b2b] font-bold",
  inputContainer: "lg:mb-3 mb-27 mt-40 xl:w-96",
  inputWrpr: " border-transparent border-solid border-2  inputShadow",
  text: "form-control block w-full py-13 px-20 text-base font-normal text-gray-700  bg-white bg-clip-padding   rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  select: "",
  chkWrpr: "flex mb-20",
  checkBox: "mr-[10px]",
  checkBoxText: "ml-[10px]",
  error: "border border-red-500",
  errorCheckbox: "outline-red outline outline-offset-2 outline-red-500"
}

export const emailSignUp = {
  ...defaultStyle,
  select: "rounded-full text-16 leading-[24px] bg-white w-full font-GoodHeadlinePro !bg-none cursor-pointer",
  inputContainer1: "emailSignUp w-full justify-between items-end",
  inputContainer: "NEWSLETTER_FORM  w-full",
  inputWrpr: "signUp border-transparent border-solid border bg-transparent",
  chkWrpr: "mb-20 text-white text-16 leading-[24px] mt-10",
  error: "",
  errorCheckbox: "outline-red outline outline-offset-2 outline-red-500",
  labelStyle: "hidden font-bold text-[16px] text-white flex justify-center md:justify-start lg:justify-start",
  text: "form-control placeholder-[#7d6baa] focus:!placeholder-[#fff] block w-full px-25 py-10 text-16 leading-[24px] placeholder:text-purple bg-white bg-clip-padding border-none rounded-full transition ease-in-out focus:text-purple focus:bg-white focus:border-none focus:outline-none font-GoodHeadlinePro",
  subText: 'text-white text-16 leading-[24px] font-GoodHeadlinePro absolute top-[40%] left-[75%] md:left-[82%]',
  checkBox: "w-[30px] h-[30px] mt-[2px]",
  checkBoxText: "child:w-[calc(100%-40px)]",
  lableTop: "emailSignupSelect select-style bg-white rounded-full px-25 py-10",
  close: "absolute w-[12px] h-[12px] top-[10px] right-[27px] shadow-[2px_2px_rgba(59,39,116,1)] rotate-45 pointer-events-none",
}

export const unSubscribing = {
  ...defaultStyle,
  labelStyle: 'inline-block labelVisible absolute text-[14px] leading-[22px] pr-[5px] pl-[10px] top-[-16%] left-[27px] bg-white ',
  inputContainer: "NEWSLETTER_FORM lg:mb-[20px] mb-5 inputContainer xl:w-[100%] w-[100%] lg:w-[100%] relative",
  inputWrpr: "w-[51%] mt-20 newsletter-unsubscription-input",
  text: "form-control placeholder-[#7d6baa] focus:!placeholder-transparent newsletterInput block py-10 px-[22px] w-full md:w-[360px] lg:w-[395px]  h-[41px] text-base font-normal  rounded-[25px]  bg-white bg-clip-padding  transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none newsletter-input border border-[1px]",
}

export const RECIPE_SORTING = {}

export const NEWSLETTER_FORM = {
  // inputContainer: " lg:mb-3 lg:mb-27 mb-15 xl:w-96 text-[15px]",
  labelStyle: "inline-block labelVisible absolute text-[14px] leading-[22px] pr-[5px] pl-[10px] top-[-16%] left-[27px] bg-white ",
  inputContainer: "lg:mb-3 lg:mb-27 mb-15 xl:w-96 text-[15px] NEWSLETTER_FORM inputContainerNews xl:w-96 ",
  inputWrpr: "newsletterInputBlock border-transparent border-solid border bg-transparent",
  text: "form-control placeholder-[#7d6baa] focus:!placeholder-transparent newsletterInput block py-10 px-[22px] w-full md:w-[360px] lg:w-[395px]  h-[41px] text-base font-normal  rounded-[25px]  bg-white bg-clip-padding  transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none newsletter-input border border-[1px]",
  select: "optionField relative !bg-none cursor-pointer",
  chkWrpr: "flex mb-20",
  checkBox: "mr-[10px]",
  checkBoxText: "ml-[10px]",
  error: "border border-red-500",
  errorCheckbox: "outline-red outline outline-offset-2 outline-red-500",
  lableTop: "select-style newsletter-select bg-white rounded-full border-[#7d6baa] py-[7px] px-25 block border cursor-pointer w-[190px]",
}