export const defaultStyle = {
  layoutContainer: "mb-40 lg:w-[950px] w-full lg:mx-auto",
  textContainer: "",
  heading: "",
  description: "",
  linkWrpr: "pl-[20px] mt-[20px] underline",
  list: " pl-20 md:pl-20 flex md:pl-0 pr-10 md:pr-0 flex-wrap flex-row mt-30 gap-20 lg:w-[950px] w-full lg:mx-auto md:justify-start md:pl-[20px] lg:justify-start md:items-center md:text-center",
  // imageWrapperTitle: '',
  // imageWrapperHeading: '',
  imgMain:"imgMain h-[25px] w-auto md:w-[54px] md:h-[54px]  lg:w-[114px] lg:h-[114px]",
  listItem:"listItem  h-[27px] w-auto md:h-[60px] md:w-[60px] lg:h-[120px] lg:w-[120px] ",
  imageWrapperTitle:"imageWrapperTitle relative block w-full h-full translate-y-[-60%] md:translate-y-0",
  imageWrapperHeading:"imageWrapperHeading pl-30 md:pl-0 md:pt-10 nowrap cursor-pointer md:text-[12px] md:leading-[12px] text-[12px] leading-[12px] font-IntervogueSoftRegular"
}
