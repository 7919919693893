export const ProductSearchData = {
  "filter": {
    "search": "milka",
    "sortOption": "",
    "pageNo": 0,
    "classFilters": {},
    "clafeObj": {},
    "filtersArr": [],
    "hitsPerPage": 4
  }
}

export const algoliaResponse = {
  "hits": [
    {
      "ProductID": "30296",
      "ProductTitle": "Donut mit Milka und Daim 68g",
      "ProductImage": [
        {
          "type": "milka_new_thumbnail_253x198",
          "url": "https://images.sweetauthoring.com/product/103675.png"
        },
        {
          "type": "milka_new_large_460x380",
          "url": "https://images.sweetauthoring.com/product/103674.png"
        }
      ],
      "ProductName": "",
      "ProductCategoryIds": [
        "24097"
      ],
      "SeoPageName": "donut-mit-milka-und-daim-68g",
      "pageSlug": "/produkte/donut-mit-milka-und-daim-68g-30296/",
      "id": "30296",
      "objectID": "30296",
      "_highlightResult": {
        "ProductTitle": {
          "value": "Donut mit <em>Milka</em> und Daim 68g",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "milka"
          ]
        },
        "SeoPageName": {
          "value": "donut-mit-<em>milka</em>-und-daim-68g",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "milka"
          ]
        }
      }
    },
    {
      "ProductID": "30887",
      "ProductTitle": "",
      "ProductImage": null,
      "ProductName": "",
      "ProductCategoryIds": [
        "24097"
      ],
      "SeoPageName": "i-love-milka-erdbeer-110g-liebste-mama",
      "pageSlug": "/produkte/i-love-milka-erdbeer-110g-liebste-mama-30887/",
      "id": "30887",
      "objectID": "30887",
      "_highlightResult": {
        "ProductTitle": {
          "value": "I love <em>Milka</em> Erdbeer 110g „Liebste Mama“",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "milka"
          ]
        },
        "SeoPageName": {
          "value": "i-love-<em>milka</em>-erdbeer-110g-liebste-mama",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "milka"
          ]
        }
      }
    },
    {
      "ProductID": "30258",
      "ProductTitle": "I love Milka Erdbeer Pralinés 110g",
      "ProductImage": [
        {
          "type": "milka_new_thumbnail_253x198",
          "url": "https://images.sweetauthoring.com/product/103531.png"
        },
        {
          "type": "milka_new_large_460x380",
          "url": "https://images.sweetauthoring.com/product/103530.png"
        }
      ],
      "ProductName": "",
      "ProductCategoryIds": [
        "24097"
      ],
      "SeoPageName": "i-love-milka-erdbeer-pralines-110g",
      "pageSlug": "/produkte/i-love-milka-erdbeer-pralines-110g-30258/",
      "id": "30258",
      "objectID": "30258",
      "_highlightResult": {
        "ProductTitle": {
          "value": "I love <em>Milka</em> Erdbeer Pralinés 110g",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "milka"
          ]
        },
        "SeoPageName": {
          "value": "i-love-<em>milka</em>-erdbeer-pralines-110g",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "milka"
          ]
        }
      }
    },
    {
      "ProductID": "30261",
      "ProductTitle": "I love Milka Geschenkherz 165g",
      "ProductImage": [
        {
          "type": "milka_new_thumbnail_253x198",
          "url": "https://images.sweetauthoring.com/product/103541.png"
        },
        {
          "type": "milka_new_large_460x380",
          "url": "https://images.sweetauthoring.com/product/103540.png"
        }
      ],
      "ProductName": "",
      "ProductCategoryIds": [
        "24097"
      ],
      "SeoPageName": "i-love-milka-geschenkherz-165g",
      "pageSlug": "/produkte/i-love-milka-geschenkherz-165g-30261/",
      "id": "30261",
      "objectID": "30261",
      "_highlightResult": {
        "ProductTitle": {
          "value": "I love <em>Milka</em> Geschenkherz 165g",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "milka"
          ]
        },
        "SeoPageName": {
          "value": "i-love-<em>milka</em>-geschenkherz-165g",
          "matchLevel": "full",
          "fullyHighlighted": false,
          "matchedWords": [
            "milka"
          ]
        }
      }
    }
  ],
  "nbHits": 154,
  "page": 0,
  "nbPages": 39,
  "hitsPerPage": 4,
  "exhaustiveNbHits": true,
  "exhaustiveTypo": true,
  "exhaustive": {
    "nbHits": true,
    "typo": true
  },
  "query": "milka",
  "params": "query=milka&page=0&hitsPerPage=4",
  "renderingContent": {},
  "processingTimeMS": 1,
  "processingTimingsMS": {
    "getIdx": {
      "total": 1
    },
    "request": {
      "roundTrip": 179
    },
    "total": 1
  },
  "serverTimeMS": 1
}