import React from "react";
import { screen, render, fireEvent } from "@testing-library/react";
import NavigationItem from "./NavigationItem";


const NavigationItemProps = {
  item: {
    link: {},
    label: "label",

  },
}
describe("NavigationItem", () => {
  let view = null;
  beforeEach(() => {
    view = render(<NavigationItem {...NavigationItemProps} />);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should click Close Button", () => {
    const anchorTag = document.querySelector("a");
    expect(anchorTag).toBeInTheDocument;
  });

  test("showing video title", () => {
    expect(screen.findByTitle(NavigationItemProps?.item?.label));
  });

  test("should render with title null", () => {
    const currentProps = {
      item: {
        label: "MEHR ERFAHREN",
        title: "MEHR ERFAHREN",
        url: "/neues/haselnusscreme",
        target: "_self",
        scrollToElementId: null,
        gaEventClass: null,
        gaEventLabel: null,
        image: null,
        className: "linkWrpr",
      },
    }
    view = render(<NavigationItem {...currentProps} title={null} />);
  });

  test("should render with false value", () => {
    view = render(<NavigationItem {...NavigationItemProps} label={null} />);
  });

});
