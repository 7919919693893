export const ProductFilterData = {
  "filterItemCollection": {
    "title": "Filter",
    "filterItemCollection" :{
      "items": [
        {
          "title": "Products",
          "apiText": "PRODUKTE",
          "uniqueKey": "FilterProduct",
          "itemCollection": {
            "items": [
              {
                "title": "bars",
                "keyName": "bars",
                "apiText": "Riegel",
                "categoryKey": null,
                "count": 11
              },
              {
                "title": "large panels",
                "keyName": "big_tablets",
                "apiText": "Grosstafeln",
                "categoryKey": null,
                "count": 17
              },
              {
                "title": "sweets",
                "keyName": "sweets",
                "apiText": "Bonbons",
                "categoryKey": null,
                "count": 0
              },
              {
                "title": "naps",
                "keyName": "naps",
                "apiText": "Naps",
                "categoryKey": "neu",
                "count": 3
              }
            ]
          }
        },
        {
          "title": "Variety And Ingredients",
          "apiText": "SORTE UND ZUTATEN",
          "uniqueKey": "FilterIngredients",
          "itemCollection": {
            "items": [
              {
                "title": "Mandel",
                "keyName": "almond",
                "apiText": "Mandel",
                "categoryKey": null,
                "count": 9
              },
              {
                "title": "Biscuit",
                "keyName": "biscuit",
                "apiText": "Keks",
                "categoryKey": "schoko-snacks",
                "count": 31
              },
              {
                "title": "Caramel",
                "keyName": "caramel",
                "apiText": "Caramel",
                "categoryKey": null,
                "count": 15
              }
            ]
          }
        },
        {
          "title": "Occasions",
          "apiText": "ANLÄSSE",
          "uniqueKey": "FilterOccasion",
          "itemCollection": {
            "items": [
              {
                "title": "Best wishes from",
                "keyName": "best_wishes",
                "apiText": "Alles Gute Wünschen",
                "categoryKey": null,
                "count": 2
              },
              {
                "title": "Birthday",
                "keyName": "birthday",
                "apiText": "Geburtstag",
                "categoryKey": null,
                "count": 39
              },
              {
                "title": "Our classics",
                "keyName": "classics",
                "apiText": "Unsere Klassiker",
                "categoryKey": null,
                "count": 47
              }
            ]
          }
        }
      ]
    }
  },
  "onFilterChange": () => {},
  "categoryData": {
    "title": "Neu",
    "typeName": "Products"
  }
}