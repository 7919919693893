import React from "react";
import { fireEvent, render, screen } from "@testing-library/react";
import Video from "./Video";
import { videoProps } from "../../../mocks/Video.mock";

describe("Video", () => {
  let view = null;
  beforeEach(() => {
    view = render(<Video {...videoProps} />);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render Intial Props", () => {
    render(<Video {...videoProps} siteCoreVideo={{url: "videoLink"}} youtubeVideoId={''}/>);
  });

  test("validate image url", () => {    
    const imageElement = document.querySelector('img')
    expect(imageElement.src).toContain(videoProps.posterImage.url)
  });

  test("Click Play Button", () => {
    fireEvent.click(screen.getByAltText('Play Button'));
  });
});