import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import * as styles from "./Signup-tw-styles";
import Image from "../UI/Image/Image";
import Button from "../UI/Button/Button";
import Link from "../UI/Link/Link";
import { TOGGLE_PASSWORD } from "../../helpers/rules.constants";
import RenderField from "../../helpers/RenderField";
import { validate } from "../../helpers/FormValidations";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "../UI/Loader/Loader";
import {formSubmitObj} from "../../constants/gtm.constants"
const sitekey = process.env.GATSBY_APP_SITE_KEY;
const SignUp = props => {
  const {
    heading,
    text,
    fields,
    termsText,
    signUp,
    createAccount,
    forgetPassword,
    variant,
    image,
    description,
    term,
    isReCaptch = true,
    onSubmitAPI,
    onRedirect,
    eventLabel
  } = props;

  const style = styles[variant] || styles.defaultStyle;
  const [form, setForm] = useState(fields);
  const [hidePassword, setHidePassword] = useState(true);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const reCaptchaRef = useRef();
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [recaptchaDone, setRecaptchaDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCapatchaChange = value => {
    setRecaptchaValue(value);
    const errors = {
      ...error,
      captcha: "",
    }
    setError(errors);
    hasAnyErrors(errors);
    value ? setRecaptchaDone(true) : setRecaptchaDone(false);
  };

  const handleChange = (event, id) => {
    if (id === TOGGLE_PASSWORD) {
      setHidePassword(!hidePassword);
    } else {
      const validateField = validate({
        ...form[id],
        value: event.target.value,
      });
      const errors = {
        ...error,
        [validateField.name]: validateField.error
      }
      const fields = {
        ...form,
        [id]: { ...form[id], value: event.target.value }
      }
      setError(errors);
      setForm(fields);
      hasAnyErrors(errors);
    }
  };

  const handleSubmit = async () => {
    const isValid = checkFormValidStatus(true)
    if (isValid) {
      // TO DO
      const formData = Object.values(form).reduce((a, b) => {
        a[b.name] = b.value;
        return a;
      }, {});
      submitData(formData);
    } else {
      setEnableSubmit(false)
    }
  };

  const hasAnyErrors = (errs) => {
    if (Object.values(errs).filter(e => e).length === 0) {
      setEnableSubmit(true)
    } else {
      setEnableSubmit(false)
    }
  }

  const submitData = formData => {
    try {
      if (onSubmitAPI) {
        setLoading(true);
        onSubmitAPI(formData, recaptchaValue)
          .then(res => {
            if (res.data.toLowerCase() === "success") { 
              setLoading(false);
              // success: add custom object to window datalyer obj when form is submitted
              formSubmitObj.GAeventAction = { ...formData, submit: "success" }
              if (location && location.href) {
                formSubmitObj.GAeventLabel = location.href
              }
              if (window !== "undefined" && window.dataLayer) {
                window.dataLayer.push({ ...formSubmitObj })
              }   
            }
            if (onRedirect) {
              onRedirect(true);
            }
          })
          .catch(() => {
            setLoading(false);
            //failure: add custom object to window datalyer obj when form is submitted
            formSubmitObj.GAeventAction = { ...formData, submit: "failure" }
            if (location && location.href) {
              formSubmitObj.GAeventLabel = location.href
            }
            if (window !== "undefined" && window.dataLayer) {
              window.dataLayer.push({ ...formSubmitObj })
            }
            if (onRedirect) {
              onRedirect(false);
            }
          });
      }
    } catch (err) {
      setLoading(false);
      if (onRedirect) {
        onRedirect(false);
      }
    }
  };

  const checkFormValidStatus = (appyToState = false) => {
    const validateField = form;
    const errors = {}
    const isFormValid = Object?.entries(form)?.map(([key, value]) => {
      validateField[key] = validate(value);
      if (!appyToState && !validateField[key].isValid) {
        validateField[key].isValid = true
      }
      errors[value.name] = value.error
      return validateField[key].isValid;
    });

    if (!recaptchaValue && isReCaptch) {
      isFormValid.push(false);
      errors['captcha'] = 'required'
    }

    if (appyToState) {
      setError({
        ...errors
      });
    }

    return !isFormValid.includes(false)
  }

  return (
    <div className={`${style?.wrapper} ${loading ? style?.isLoading : ""}`}>
      {image && (
        <div className={style?.imageWrapper}>
          <Image contentfulImage={image} className={style.imageStyle} alt={image?.title} />
        </div>
      )}

      <div className={style?.contentWrapper}>
        {heading && <div className={style?.heading}>{heading}</div>}

        {description && <p className={style?.description}>{description}</p>}

        <div className={style?.formField}>
          {Object?.entries(form)?.map(([key, value]) => (
            <div
              key={key}
              className={`form-fields-test-cls relative mb-25 newsletterItems ${style?.fieldWrpr} ${
                error[value?.name] ? style?.errorWrpr : ""
              }`}
            >
              <RenderField
                id={key}
                field={{...value}}
                onBlur={handleChange}
                handleChange={handleChange}
                hidePassword={hidePassword}
                error={{...error}}
                style={style}
                variant={variant}
              />
            </div>
          ))}
        </div>
        {forgetPassword && (
          <Link {...forgetPassword} className={style?.resetPassword} />
        )}

        {term && (
          <div
            className={style?.term}
            dangerouslySetInnerHTML={{ __html: term }}
          ></div>
        )}
        {isReCaptch && sitekey && (
          <div className={`${style?.captchaWrpr}`}>
            <ReCAPTCHA
              className={`${error?.captcha ? style?.captchaError : ""}`}
              style={{ display: "inline-block" }}
              theme="light"
              ref={reCaptchaRef}
              sitekey={sitekey}
              onChange={handleCapatchaChange}
            />
          </div>
        )}
        {signUp && (
          <div className={style.signUpTop}>
            <Button
              text={signUp?.label}
              type="secondary"
              onClick={handleSubmit}
              className={`${style?.signUpBtn} ${!enableSubmit ? style?.notValid : ''}`}
              dataActionDetail = {eventLabel}
            />
          </div>
        )}
        {createAccount && (
          <div>
            <p>{text}</p>
            <Link {...createAccount} className={style?.createAcc} />
          </div>
        )}
        {termsText && (
          <div
            className={style?.term}
            dangerouslySetInnerHTML={{ __html: termsText }}
          ></div>
        )}
      </div>
      {loading && <Loader />}
    </div>
  );
};

SignUp.propTypes = {
  heading: PropTypes.string,
  fields: PropTypes.object,
  text: PropTypes.string,
  termsText: PropTypes.string,
  signUp: PropTypes.object,
  createAccount: PropTypes.object,
  forgetPassword: PropTypes.object,
  term: PropTypes.string,
  image: PropTypes.object,
  variant: PropTypes.string,
  description: PropTypes.string,
  isReCaptch: PropTypes.bool,
  onSubmitAPI: PropTypes.func,
  onRedirect: PropTypes.func,
  eventLabel: PropTypes.string
};

export default SignUp;
