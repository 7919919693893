import React from "react";
import { screen, render, within } from "@testing-library/react";
import ListComponent from "./ListComponent";
import { ListComponentProps } from "../../../mocks/ListComponent.mocks";

describe("ListComponent", () => {
  test("should render label", () => {
    render(<ListComponent {...ListComponentProps} />);
    expect(screen.getByText("List Component"));
  });

  test("links count", () => {
    render(<ListComponent {...ListComponentProps} />);
    const list = screen.getByRole("list");
    const { getAllByRole } = within(list);
    const items = getAllByRole("listitem");
    expect(items.length).toBe(3);
  });

  test("Check for Anchor tag", () => {
    render(<ListComponent {...ListComponentProps} />);
    expect(screen.getByText("Link 1").href).toBe("https://localhost:8000/");
    expect(screen.getByText("Link 1").title).toBe("Link 1");
    expect(screen.getByText("Link 2").href).toBe("https://localhost:8000/");
    expect(screen.getByText("Link 2").title).toBe("Link 2");
    expect(screen.getByText("Link 3").href).toBe("https://localhost:8000/");
    expect(screen.getByText("Link 3").title).toBe("Link 3");
  });
});
