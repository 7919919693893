const query = () => `{
  footerCollection(limit: 1) {
    items {
      note
      copyRight
      footerLinksCollection(limit: 15) {
        items {
          label
          labelLink {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
          }
          linksCollection {
            items {
              label
              title
              url
              target
              scrollToElementId
              gaEventClass
              gaEventLabel
              image {
                title
                description
                url
                width
                height
              }
            }
          }
        }
      }
      socialLinksCollection(limit: 10) {
        items {
          label
          title          
          url
          target
          imageType
          scrollToElementId
          gaEventClass
          gaEventLabel
          customClassName
          image {
            title
            description
            url
            width
            height
          }
        }
      }
      mainLinksCollection(limit: 15) {
        items {
          label
          linksCollection {
            items {
              label
              title
              url
              target
              scrollToElementId
              gaEventClass
              gaEventLabel
              image {
                title
                description
                url
                width
                height
              }
            }
          }
        }
      }
      footerLogo{
        url
        title
        description
        width
        height
      }
      brandLinksCollection {
        items {
          label
          title
          url
          target
          scrollToElementId
          gaEventClass
          gaEventLabel
          image {
            url
            title
            description
            width
            height
          }
        }
      }
      images {
        internalTitle
        altText
        imageDesktop {
          title
          description
          url
          width
          height
        }
        imageMobile {
          title
          description
          url
          width
          height
        }
        bgDesktop {
          title
          description
          url
          width
          height
        }
        bgMobile {
          title
          description
          url
          width
          height
        }
      }
      upliftButton {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
            image {
              title
              description
              url
              width
              height
            }
          }
    }
  }
}

  `
module.exports = { query }