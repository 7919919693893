export const textContentData = {
    "title": "Wie hat das Milka Skisponsoring begonnen?",
    "shortDescription": "Wäre die FIS Alpine Ski WM in Sierra Nevada 1995 nicht abgesagt worden",
    "link":{
        "label":"MEHR ERFAHREN",
        "title":"MEHR ERFAHREN",
        "url":"/neues/zarteste-Milka"
    } ,
    "variant": "TEXT_CONTENT_CENTER_ALLIGNED_MILKA_SKI"
  }
