export const SocialLinksProps ={
    icon: '',        
    name: 'salutation',
    error: '',
    label: 'Salutation',
    id: 'selectId',
    rules: [
      { type: 'REQUIRED', errorMessage: 'Salutation is Required' },
      { type: 'text', errorMessage: '' }
    ],
    value: '1',
    isValid: true,
    options: [ { label: 'Herr', value: '1' }, { label: 'Frau', value: '2' } ],
    fieldType: 'select',
    isRequired: true,
    submittion: true,
    elementType: 'select',
    elementConfig: { type: 'text', placeholder: 'Salutation*' },
    style: { error: 'error' },
    showInput: true,
    selectedValue: '',
    input: {
      type: 'text',
      id: 'input',
      placeholder: 'Salutation',
      name: 'name',
      value: 'value'
    },
    onChange: () =>{},
    showLabel:true
  }
