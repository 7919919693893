import axios from "axios"

export const singupNewsLetter = (formData, gCaptchaResponse) => {
  try {
    return axios.post(
      `${process.env.GATSBY_FORM_API}`,
      { 
        "requestDomain": "www.milka.de",
        "requestCountry": "de",
        "requestLanguage": "de",
        "requestCampaign": "MKZZ200101_NLReg_Community_Milka_Website",
        "requestTransaction": "MKZZ200101 Milka Newsletter Subscription (IN)",
        "requestLegalListName": "Privacy_Policy_DE",
        "requestLegalTextName": "PrivacyPolicy_DE",
        "g-recaptcha-response": gCaptchaResponse,
        "requestBody": {
          ...formData
        }
      }
    )
  } catch(err) {
    throw err
  }
}

export const unsubscribeNewsLetter = (formData, gCaptchaResponse) => {
  try {
    return axios.post(
      `${process.env.GATSBY_FORM_API}`,
      { 
        "requestDomain": "www.milka.de",
        "requestCountry": "de",
        "requestLanguage": "de",
        "requestCampaign": "MKZZ200101_NLUnsubscription_Community_Milka_Website",
        "requestTransaction": "MKZZ200101 Milka Newsletter Unsubscription (IN)",
        "requestLegalListName": "Privacy_Policy_DE",
        "requestLegalTextName": "PrivacyPolicy_DE",
        "g-recaptcha-response": gCaptchaResponse,
        "requestBody": {
          ...formData,
          "reason": 4,
          "reasonOther": "no reason"
        }
      }
    )
  } catch(err) {
    throw err
  }
}