export const TimelineWrapperData = {
  "timelineContentsCollection": {
    "items": [
      {
        "title": "1826",
        "description": "Philippe Suchard begann Suchard Schokolade in seiner Heimatstadt Serrieres nahe Neuenburg zu produzieren.",
        "image": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/3unjjav3pxFOpsE4j2GxkS/7f80b2a2b5c0bc278ea71f8b1a8d07ac/1826.png"
        },
        "backgroundImage": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/33yYvZ8ZM1tNank1o0aBgO/8ec01cb6e1d68b15ea28824fd7979ef9/master-paper-texture_white.jpg"
        },
        "variant": "TEXT_BLUE"
      },
      {
        "title": "1901",
        "description": "Die Geburt von Milka: Am 24. April wurde der Markenname “Milka” als Marke eingetragen. “Milka” ist eine Kombination aus Milch und Kakao. 1901 wurde die erste Milka Tafel in das berühmte lila Papier eingepackt.",
        "image": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/21udYPwUyBrO9uz7alttKq/0f6726b40eb5cb1e2c347ba6756977fe/geschichte-tafel-1901.png"
        },
        "backgroundImage": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/7oTAtM2ZpVzjra9R7vOqU4/4264e40bad37a1fd1abecb9df52f7948/torn-lilac.jpg"
        },
        "variant": "TEXT_WHITE"
      },
      {
        "title": "1901",
        "description": "MILKA KOMMT AUF DEN DEUTSCHEN MARKT.",
        "image": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/m8chw8PeJ1TiT5nFRR6jk/161b5f032fb4dc1fe874c5f971efe0bc/geschichte-1901-2.png"
        },
        "backgroundImage": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/2ZpEE7fcdhsBiC9BKJ6a5y/e2bb8dc7e8c4f7adce7f6f45ecf56074/master-paper-texture_grey.jpg"
        },
        "variant": "TEXT_BLUE"
      },
      {
        "title": "1909",
        "description": "1909 WURDE MILKA ERSTMALS IN ÖSTERREICH HERGESTELLT UND VERKAUFT.",
        "image": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/4d3qj9eQPrf1wVgivMvlyK/ae1749c28c83f4d009a94912bf8f4151/geschichte-1901-3.png"
        },
        "backgroundImage": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/33yYvZ8ZM1tNank1o0aBgO/8ec01cb6e1d68b15ea28824fd7979ef9/master-paper-texture_white.jpg"
        },
        "variant": "TEXT_BLUE"
      },
      {
        "title": "1913",
        "description": "Die Beliebtheit von Milka steigt mehr und mehr. 1913 wird im Werk Lörrach 18mal mehr Schokolade produziert als noch im Jahr 1880.\n",
        "image": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/6KdQBgQr4b4sPASsxKhacp/4ac9554e726dc0fe7c78fd98b8ee7d91/1913-Factory.jpg"
        },
        "backgroundImage": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/4TjunHOGXJp09BPuh0pPqL/8a01d1443411de6a3d9bdf7260af6fa1/torn-milka-lilac.jpg"
        },
        "variant": "TEXT_BLUE"
      }
    ]
  }
}