import React from "react"
import Link from "../Link/Link"
import * as styles from "./SocialLinks-tw-styles"
import PropTypes from "prop-types"
import Icon from "../Icon/Icon"
import { socialMedia } from "../../../constants/gtm.constants"

const SocialLinks = props => {
  const { socialLinks, variant, showLabel } = props
  const style = styles[variant] || styles.defaultStyle
  return (
    <div className={style.socialWpr}>
      {socialLinks?.length &&
        socialLinks.map((link, index) => {
          return (
            <div className={style.socialIcon} key={index}>
              <Link className={style.externalLink} {...link}>
                {link.imageType === "svg" ? (
                  <Icon
                    name={link.title}
                    className={`${style?.socialImg} ${socialMedia}`}
                  />
                ) : (
                  ""
                )}
              </Link>
              {showLabel && <span className={style.icontxt}>{link.title}</span>}
            </div>
          )
        })}
    </div>
  )
}

SocialLinks.propTypes = {
  socialLinks: PropTypes.array,
  variant: PropTypes.string,
  showLabel: PropTypes.bool,
}

export default SocialLinks
