const query = (id, isPreview) => `
{
  articleVideo (id: "${id}", preview: ${isPreview}) {
    description
    variant
    displayName
    heading
    cardsCollection {
      total
      items {
        displayName
        title
        text
        elementId
        gaEventClass
        gaEventLabel
        variant
        image {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        images {
          internalTitle
          altText
          imageDesktop {
            title
            description
            url
            width
            height
          }
          imageMobile {
            title
            description
            url
            width
            height
          }
          bgDesktop {
            title
            description
            url
            width
            height
          }
          bgMobile {
            title
            description
            url
            width
            height
          }
        }
        link {
          name
          url
          label
          title
          target
          scrollToElementId
          imageType
          gaEventClass
          gaEventLabel
          separatedBy
         url
          image {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
        }
      }
    }
    video {
      internalTitle
      title
      isModal
      isIframe
      isImageToggle
      youtubeVideoId
      siteCoreVideo {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      isAutoPlay
      posterImage {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }   
    }
    images {
          internalTitle
          altText
          imageDesktop {
            title
            description
            url
            width
            height
          }
          imageMobile {
            title
            description
            url
            width
            height
          }
          bgDesktop {
            title
            description
            url
            width
            height
          }
          bgMobile {
            title
            description
            url
            width
            height
          }
        }
}
}
`
module.exports = { query }
