import React from "react";
import PropTypes from "prop-types";
import Typography from "../UI/Typography/Typography";
import * as styles from "./TextContent-tw-styles";
import Link from "../UI/Link/Link";

const TextContent = props => {
  const { title, shortDescription, link , variant} = props;
  const style = styles[variant] || styles?.defaultStyle
  return (
    <div className={style?.wrapper}>
      <div className={style?.textContent}>
        {title && <Typography className={style?.title} allow_br_tag={true} content={title} />}
        {shortDescription && 
          <Typography
            content={shortDescription}
            allow_br_tag={true}
            data-testid="typography"
            className={style?.shortDesc}
          />}
        {link && (
          <Link {...link} className={style?.heroLink}/>
        )}
      </div>
    </div>
  );
};

TextContent.propTypes = {
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  link: PropTypes.object,
  variant: PropTypes.string
};

export default TextContent;
