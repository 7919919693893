import React from "react";
import { fireEvent, render, screen } from "@testing-library/react";
import InlineView from "./InlineView";
import { InlineProps } from "../../../mocks/InlineView.mock";

describe("InlineView", () => {
  let view = null;
  beforeEach(() => {
    view = render(<InlineView {...InlineProps} onChange={()=>{}}/>);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render Intial Props", () => {
    render(<InlineView {...InlineProps} onChange={()=>{}} isIframe={false} isAutoPlay={true} />);
  });

  test("Button Click with Intial Props", () => {
    const { container } = view;
    const div_ele = container.querySelectorAll(`button`)
    fireEvent.click(div_ele[0], {event: {preventDefault: ()=>{} } })
  });
});