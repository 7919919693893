import React from "react"
import PropTypes from "prop-types"

export default function Select(props) {
  const {
    options,
    label,
    id,
    name,
    onChange,
    selectedValue,
    isValid,
    isRequired,
    ariaLabel,
    showInput,
    input,
    style
  } = props

  return (
    <div>
      <div className={style.inputContainer}>
        <label htmlFor={id} className={style.labelStyle}>
          {label}
          {`${isRequired ? "*" : ""}`}
        </label>
        <select
          className={`${style.select} ${!isValid ? style.error : ""}`}
          aria-label={ariaLabel}
          id={id}
          data-testid={"selectId"}
          name={name}
          value={selectedValue}
          onChange={event => onChange(event, id)}
        >
          {options?.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </select>
        {showInput && (
          <input
            type={input.type}
            className={`${style.text} ${!isValid ? style.error : ""}`}
            id={input.id}
            placeholder={input.placeholder}
            name={input.name}
            onChange={event => onChange(event, input.id)}
            value={input.value}
          />
        )}
      </div>
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  showInput: PropTypes.bool,
  input: PropTypes.object,
  style: PropTypes.object,
}