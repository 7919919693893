import React from "react";
import { screen, render, fireEvent } from "@testing-library/react";
import ProductListing from "./ProductListing";
import { ProductListingData } from "../../mocks/ProductListing.mock";

describe("ProductListing", () => {
  let view = null
  beforeEach(() => {
    view = render(<ProductListing {...ProductListingData} />)
  })
  afterEach(() => {
    jest.clearAllMocks();
  })

  test("should render component", () => {
    expect(screen.getByText("Alle Produkte"));
  });

  test("should render component without props", () => {
    view = render(<ProductListing />)
    expect(screen.getByText("Alle Produkte"));
  });

  test('should list the Product Items', () => {
    const { container } = view
    const div_ele = container.querySelectorAll('li[class*="ProductListingBlock"]')
    expect(div_ele.length).toEqual(4);
  })

  test('should filter the Product Items based on category data props', () => {
    const categoryData = {
      "title": "salty",
      "keyName": "salty",
      "apiText": "Salzig",
      "categoryKey": "neu",
      "typeName": "FilterIngredients",
      "groupName": "Variety And Ingredients"
    };
    view = render(<ProductListing {...ProductListingData} categoryData={categoryData} />)


    const { container } = view
    const div_ele = container.querySelectorAll('li[class*="ProductListingBlock"]')
    expect(div_ele.length).toEqual(2);
  })

  test('should open sort options when sort dropdown clicked', () => {
    const { container } = view
    const sort_btn = container.querySelectorAll('div[id="product-list-sorting-btn"]')
    fireEvent.click(sort_btn[0])
    const sort_options = container.querySelectorAll('div[class*="sortDropdownOpened"]')
    expect(sort_options.length).toEqual(1)
  })

  test('should sort by A-Z', () => {
    const { container } = view
    const sort_btn = container.querySelectorAll('div[id="product-list-sorting-btn"]')
    fireEvent.click(sort_btn[0])
    const sort_options = container.querySelectorAll('.sortDropdownOpened>p')
    fireEvent.click(sort_options[2])
    const product_names = container.querySelectorAll('.product_foot')
    expect(product_names[0].textContent.includes('milka tuc cracker 87g')).toBe(true)
  })

  test('should sort by Price Asc', () => {
    const { container } = view
    const sort_btn = container.querySelectorAll('div[id="product-list-sorting-btn"]')
    fireEvent.click(sort_btn[0])
    const sort_options = container.querySelectorAll('.sortDropdownOpened>p')
    fireEvent.click(sort_options[1])
    const product_names = container.querySelectorAll('.product_foot')
    expect(product_names[0].textContent.includes('Milka Bunte Kakaolinsen 100g')).toBe(true)
  })


  test('should Load More button will not be visible if all items shown', () => {
    const { container } = view
    const loadMoreBtn = container.querySelectorAll('.loadmore-btn')
    expect(loadMoreBtn.length).toEqual(0)
  })

  test('should Load More button need to be visible if items greater than 20', () => {
    const newProps = {
      ...ProductListingData,
      productList: [
        ...ProductListingData.productList,
        ...ProductListingData.productList,
        ...ProductListingData.productList,
        ...ProductListingData.productList,
        ...ProductListingData.productList,
        ...ProductListingData.productList
      ]
    }
    view = render(<ProductListing {...newProps} />)
    const { container } = view
    const loadMoreBtn = container.querySelectorAll('.loadmore-btn')
    expect(loadMoreBtn.length).toEqual(1)
  })

  test('should Load More items when load more button is clicked', () => {
    const newProps = {
      ...ProductListingData,
      productList: [
        ...ProductListingData.productList,
        ...ProductListingData.productList,
        ...ProductListingData.productList,
        ...ProductListingData.productList,
        ...ProductListingData.productList,
        ...ProductListingData.productList
      ]
    }
    view = render(<ProductListing {...newProps} />)
    const { container } = view
    const loadMoreBtn = container.querySelectorAll('.loadmore-btn')
    fireEvent.click(loadMoreBtn[0])
    const div_ele = container.querySelectorAll('li[class*="ProductListingBlock"]')
    expect(div_ele.length).toEqual(24);
  })

})