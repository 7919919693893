import React from "react"
import * as styles from "./Input-tw-styles"
import { imageConstants } from "../../../constants/image.constants"
import PropTypes from "prop-types"

export default function Select(props) {
  const {
    options,
    label,
    id,
    name,
    variant,
    onChange,
    selectedValue,
    isValid,
    isRequired,
    ariaLabel,
    showInput,
    input,
    onFocus,
    gaEventClass,
    subText,
    errorMessage,
    placeholder
  } = props
  const style = styles[variant] || styles?.defaultStyle;
  return (
    <div>
      <div className={style?.inputContainer1}>
        <label htmlFor={id} className={style?.labelStyle}>
          {label}
          {`${isRequired ? "*" : ""}`}
        </label>
        <div className={style.lableTop}>
          {placeholder ? <span className={style?.placeholder}>{placeholder}</span> : 
          <span className={style.close}> </span>}
          <select
            className={`${style?.select} ${
              !isValid ? style?.error : ""
            } ${gaEventClass}`}
            aria-label={ariaLabel || name}
            id={id}
            name={name}
            value={selectedValue}
            onChange={event => onChange(event, id)}
            data-action-detail={selectedValue || "SORT BY"}
            style={{
              backgroundImage: `url(${imageConstants.arrowDown.url})`,
              backgroundPosition: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "16px"
            }}
          >
            {options?.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {
          subText && (
            <div className={style?.subText}>{subText}</div>
          )
        }
        {showInput && (
          <input
            type={input.type}
            className={`${style?.text} ${
              !input.isInputValid ? style?.error : "border-2 border-inputBrdr"
            }`}
            id={input.id}
            placeholder={input.placeholder}
            name={input.name}
            onFocus={() => onFocus(input.id)}
            onChange={event => onChange(event, input.id)}
            value={input.value}
          />
        )}
        {errorMessage && <div className={style?.errMsg}>{errorMessage}</div>}
      </div>
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  selectedValue: PropTypes.string,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  ariaLabel: PropTypes.string,
  showInput: PropTypes.bool,
  input: PropTypes.object,
  gaEventClass: PropTypes.string,
  subText: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
}
