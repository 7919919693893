import React from 'react';
import ProductListing from './ProductListing';
import ProductListingCards from './ProductListCards';
import { PRODUCT_VARIENT } from './constants';
import PropTypes from "prop-types";


const ProductListingEntry = props =>{
  const {variant} = props;
  switch(variant){
    case PRODUCT_VARIENT.DEFAULT_BLOCK:
      return <ProductListing {...props}/>
    default:
      return  <ProductListingCards {...props}/>
  }  
};

ProductListingEntry.propTypes = {
  variant:PropTypes.string,

}
export default ProductListingEntry