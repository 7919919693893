const query = (id, isPreview) => `
{
  imageWithText(id: "${id}", preview: ${isPreview}) {
    heading
    description
    isReversed
    elementId
    showBackground
    variant
    arrowIcon {
      title
      description
      url
      width
      height
    }
    images {
      internalTitle
      altText
      imageDesktop {
        title
        description
        url
        width
        height
      }
      imageMobile {
        title
        description
        url
        width
        height
      }
      bgDesktop {
        title
        description
        url
        width
        height
      }
      bgMobile {
        title
        description
        url
        width
        height
      }
      link {
        label
        title
        url
        target
        scrollToElementId
        gaEventClass
        gaEventLabel
        image {
          title
          description
          url
          width
          height
        }
      }
    }
    link {
      label
      title
      url
      target
      scrollToElementId
      gaEventClass
      gaEventLabel
      image {
        title
        description
        url
        width
        height
      }
    }
  }
}

`
module.exports = { query }