const query = (id, isPreview )=> `
{
    imageHeroBanner(id: "${id}", preview: ${isPreview}) {
        heading
        shortDescription
        description
        backLink {
          label
          title
          url
          target
          scrollToElementId
          image {
            title
            description
            url
            width
            height
          }
          gaEventClass
          gaEventLabel
        }
        link {
          label
          title
          url
          target
          scrollToElementId
          image {
            title
            description
            url
            width
            height
          }
          gaEventClass
          gaEventLabel
        }
        variant
        images {
          internalTitle
          altText
          imageDesktop {
            title
            description
            url
            width
            height
          }
          imageMobile {
            title
            description
            url
            width
            height
          }
          bgDesktop {
            title
            description
            url
            width
            height
          }
          bgMobile {
            title
            description
            url
            width
            height
          }
        }
        cameoImage {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        }
}
`
module.exports = { query }
