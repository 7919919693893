import React from "react";
import { screen, render, fireEvent } from "@testing-library/react";
import VideoModal from "./VideoModal";


const VideoModalProps = {
  isModalOpen: true,
  closeVideoModal: () =>{},
  isIframe: true,
  src: "https://www.youtube.com/",
  title: "title"
}
describe("VideoModal", () => {
  let view = null;
  beforeEach(() => {
    view = render(<VideoModal {...VideoModalProps} />);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render Image and src to be true", () => {
    const imageElement = document.querySelector("iframe");
    expect(imageElement.src).toContain(VideoModalProps.src);
  });

  test("should click Close Button", () => {
    const closeBtn = screen.getByTestId("closeBtn");
    fireEvent.click(closeBtn)
  });

  test("showing video title", () => {
    expect(screen.findByTitle(VideoModalProps?.title));
  });

  test("should render with title null", () => {
    view = render(<VideoModal {...VideoModalProps} title={null} />);
  });

  test("should render with false value", () => {
    view = render(<VideoModal {...VideoModalProps} isModalOpen={false} isIframe={false} />);
    expect(screen.findByTitle(VideoModalProps?.title));
  });

});
