export const ImageLayoutData = {
  "heading": "Image Layout Test Heading",
  "description": "Image Layout Test Description",
  "link": {
    "label": " Zurück zur Übersicht",
    "title": "Zurück zur Übersicht",
    "url": "/uber-milka/hohe-tauern",
    "target": "_self",
    "scrollToElementId": null,
    "gaEventClass": null,
    "gaEventLabel": null,
    "image": null
  },
  "variant": "SIX_IMAGE_LAYOUT_SECTION",
  "imagesCollection": {
    "items": [
      {
        "gaEventClass": null,
        "gaEventLabel": null,
        "image": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/1aoyEV1AtTuxNw1urTKAhs/beba6346dfcefe9f6cb1abf34f592a98/nav-almauftrieb.jpg",
          "title": "nav-almauftrieb",
          "description": "",
          "width": 120,
          "height": 120
        },
        "link": {
          "label": "Almauftrieb",
          "title": "Almauftrieb",
          "url": "/uber-milka/hohe-tauern/almauftrieb",
          "target": "_self",
          "scrollToElementId": null,
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": null
        }
      },
      {
        "gaEventClass": null,
        "gaEventLabel": null,
        "image": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/sLCp4nkqFppTwAUKxPtTj/06d525b9970646a71f388e0278d53fc3/nav-leben-auf-der-alm.jpg",
          "title": "leben-auf-der-alm",
          "description": "",
          "width": 120,
          "height": 120
        },
        "link": {
          "label": "Leben auf der Alm",
          "title": "Leben auf der Alm",
          "url": "/uber-milka/hohe-tauern/leben-auf-der-alm",
          "target": "_self",
          "scrollToElementId": null,
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": null
        }
      },
      {
        "gaEventClass": null,
        "gaEventLabel": null,
        "image": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/6FCYu86Nf2ZRq3bNJF33lB/2a6f6f9289ae3657cfae5fc5f2b811df/nav-artenvielfalt.jpg",
          "title": "artenvielfalt",
          "description": "",
          "width": 120,
          "height": 120
        },
        "link": {
          "label": "Artenvielfalt",
          "title": "Artenvielfalt",
          "url": "/uber-milka/hohe-tauern/artenvielfalt",
          "target": "_self",
          "scrollToElementId": null,
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": null
        }
      },
            
      {
        "gaEventClass": null,
        "gaEventLabel": null,
        "image": {
          "url": "https://images.ctfassets.net/oqg67vmf6aj9/tlOFSpHI3P6TcynLMUELb/6e2130e622418e79c26e763b1ead83b6/nav-the-big-5.jpg",
          "title": "the-big-5-im-nationalpark",
          "description": "",
          "width": 120,
          "height": 120
        },
        "link": {
          "label": "The Big 5 im Nationalpark",
          "title": "The Big 5 im Nationalpark",
          "url": "/uber-milka/hohe-tauern/the-big-5-im-nationalpark",
          "target": "_self",
          "scrollToElementId": null,
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": null
        }
      }
    ]
  }
}
