export const BreadCrumbProps ={
  variant: "PRODUCT_DETAIL",
  navigationCollection: {
    items: [
      {
        label: "Start",
        link: {
          label: "Start",
          title: "START",
          url: "/alle-produkte",
          target: "_self",
          scrollToElementId: null,
          image: null
        }
      },
      {
        label: "Produkte",
        link: {
          label: "produkte",
          title: "PRODUKTE",
          url: "/alle-produkte",
          target: "_self",
          scrollToElementId: null,
          image: null
        }
      }
    ]
  }
}