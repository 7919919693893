export const ImageHeroBannerProps = {
        "heading": "Test Heading",
        "shortDescription": "Test Short Description",
        "description": "Test Description",
        "backLink": null,
        "link": null,
        "variant": "PRODUCT_CATEGORY_PAGE",
        "images": {
          "internalTitle": "All Product Common Banner Image",
          "altText": null,
          "imageDesktop": {
            "title": "neu",
            "description": "",
            "url": "https://images.ctfassets.net/oqg67vmf6aj9/2yyM6Uabw3DnnetSQPCeYG/1210ed910032764125a3a0539eace8dc/nue_BG.png"
          },
          "imageMobile": {
            "title": "neu",
            "description": "",
            "url": "https://images.ctfassets.net/oqg67vmf6aj9/2yyM6Uabw3DnnetSQPCeYG/1210ed910032764125a3a0539eace8dc/nue_BG.png?fm=webp&q=80"
          },
          "bgDesktop": {
            "title": "neu",
            "description": "",
            "url": "https://images.ctfassets.net/oqg67vmf6aj9/2yyM6Uabw3DnnetSQPCeYG/1210ed910032764125a3a0539eace8dc/nue_BG.png?fm=webp&q=80"
          },
          "bgMobile": {
            "title": "neu",
            "description": "",
            "url": "https://images.ctfassets.net/oqg67vmf6aj9/2yyM6Uabw3DnnetSQPCeYG/1210ed910032764125a3a0539eace8dc/nue_BG.png?fm=webp&q=80"
          },
        },
        "cameoImage": null
      
}