export const blocksliderdata = {
  componentName: "BlockSlider",
  title: "Uns verbindet viel mehr, als uns trennt",
  variant: "SLIDER_WITH_IMAGECARDS",
  internalTitle:
    "There is much more that unites us than divides us - zarte-botschaft",
  description:
    "Milka zeigt, dass der Austausch zwischen den Generationen zu einem empathischeren Miteinander beitragen kann. Dafür sollten wir uns alle bewusst mehr Zeit nehmen. Denn auch wenn die Unterschiede zwischen Jung und Alt auf den ersten Blick groß wirken: Uns verbindet viel mehr, als uns trennt.\n\nWenn du dir Zeit nimmst, dich mit Menschen aus anderen Generationen auszutauschen, trägst auch du einen Teil dazu bei, dass wir alle empathischer miteinander umgehen. Machst du mit?",
  cardsCollection: {
    items: [
      {
        variant: "CARDS_WITH_TXT_SLIDER",
        heading: null,
        description: null,
        isReversed: null,
        elementId: null,
        showBackground: false,
        images: {
          internalTitle: "Slider01 Image - zarte-botschaft",
          imageDesktop: {
            title: "Slide01",
            description: "",
            contentType: "image/png",
            fileName: "Learnings-Walter-v2.png",
            size: 101058,
            url: "https://images.ctfassets.net/oqg67vmf6aj9/5houzCyr4gxm6HOWW7S2H8/52123fb59c5a1e6b5c81bfe1091ea8b1/Learnings-Walter-v2.png",
            width: 450,
            height: 600,
          },
          imageMobile: {
            title: "Slide01",
            description: "",
            contentType: "image/png",
            fileName: "Learnings-Walter-v2.png",
            size: 101058,
            url: "https://images.ctfassets.net/oqg67vmf6aj9/5houzCyr4gxm6HOWW7S2H8/52123fb59c5a1e6b5c81bfe1091ea8b1/Learnings-Walter-v2.png",
            width: 450,
            height: 600,
          },
        },
        link: null,
      },
      {
        variant: "CARDS_WITH_TXT_SLIDER",
        heading: null,
        description: null,
        isReversed: null,
        elementId: null,
        showBackground: false,
        images: {
          internalTitle: "Slider02 - zarte-botschaft",
          imageDesktop: {
            title: "Slide02",
            description: "",
            contentType: "image/png",
            fileName: "Learnings-Bernd.png",
            size: 136716,
            url: "https://images.ctfassets.net/oqg67vmf6aj9/1Q72Ofx36RhbZOz2vs8Chl/a676fb35b32efc9eb6d09bd57f62a15a/Learnings-Bernd.png",
            width: 450,
            height: 600,
          },
          imageMobile: {
            title: "Slide02",
            description: "",
            contentType: "image/png",
            fileName: "Learnings-Bernd.png",
            size: 136716,
            url: "https://images.ctfassets.net/oqg67vmf6aj9/1Q72Ofx36RhbZOz2vs8Chl/a676fb35b32efc9eb6d09bd57f62a15a/Learnings-Bernd.png",
            width: 450,
            height: 600,
          },
        },
        link: null,
      },
      {
        variant: "CARDS_WITH_TXT_SLIDER",
        heading: null,
        description: null,
        isReversed: null,
        elementId: null,
        showBackground: false,
        images: {
          internalTitle: "Slider03 - zarte-botschaft",
          imageDesktop: {
            title: "Slide03",
            description: "",
            contentType: "image/png",
            fileName: "Learnings-Ricco.png",
            size: 136887,
            url: "https://images.ctfassets.net/oqg67vmf6aj9/745jBDozAUJayc1CaBc0aI/82a3b1670c96c82f8168b5aad64db534/Learnings-Ricco.png",
            width: 450,
            height: 600,
          },
          imageMobile: {
            title: "Slide03",
            description: "",
            contentType: "image/png",
            fileName: "Learnings-Ricco.png",
            size: 136887,
            url: "https://images.ctfassets.net/oqg67vmf6aj9/745jBDozAUJayc1CaBc0aI/82a3b1670c96c82f8168b5aad64db534/Learnings-Ricco.png",
            width: 450,
            height: 600,
          },
        },
        link: null,
      },
      {
        variant: "CARDS_WITH_TXT_SLIDER",
        heading: null,
        description: null,
        isReversed: null,
        elementId: null,
        showBackground: false,
        images: {
          internalTitle: "Slider04 - zarte-botschaft",
          imageDesktop: {
            title: "Slider image01",
            description: "",
            contentType: "image/png",
            fileName: "Learnings-Aleksandra.png",
            size: 116222,
            url: "https://images.ctfassets.net/oqg67vmf6aj9/4wq4NrS8bsh5lnKHah6UH/25dfbea3536243632847c1f8bb1bc7bd/Learnings-Aleksandra.png",
            width: 450,
            height: 600,
          },
          imageMobile: {
            title: "Slider image01",
            description: "",
            contentType: "image/png",
            fileName: "Learnings-Aleksandra.png",
            size: 116222,
            url: "https://images.ctfassets.net/oqg67vmf6aj9/4wq4NrS8bsh5lnKHah6UH/25dfbea3536243632847c1f8bb1bc7bd/Learnings-Aleksandra.png",
            width: 450,
            height: 600,
          },
        },
        link: null,
      },
    ],
  },
  sliderSettings: {
    internalTitle: "Slider Settings",
    slidesToShowDesktop: 1,
    slidesToScrollDesktop: 1,
    slidesToShowTab: 1,
    slidesToScrollTab: 1,
    slidesToShowMobile: 1,
    slidesToScrollMobile: 1,
  },
  pageType: "Gray Texture Page",
  style: {
    blockSlider: "img-slider-with-text pt-20 pb-40 mx-auto",
    blockSliderWrapper:
      "block-slider-area slider-leftImage-rightText w-full flex md:flex-row flex-col relative",
    textContentBox:
      "textContentBox lg:px-0 md:px-35 mx-auto order-first md:order-last lg:w-full w-[80%]",
    heading:
      "font-RecoletaMedium text-[36px] lg:text-[48px] pt-40 leading-[1.2] mb-5 text-center lg:text-left",
    blockSlide: "",
    subHeading:
      "font-RecoletaRegular text-center lg:text-left mx-auto font-thin text-18 lg:text-20 leading[1.4] md:pb-60",
    description: "",
    imgWrpr: "",
  },
};

export const blocksliderimagesdata = {
  title: "Awards",
  slidesCollection: {
    title: "Awards",
    items: [
      {
        image: {
          title: "Top Employer Europe",
          description: "",
          contentType: "image/jpeg",
          fileName: "Top-Employer-Europe.png",
          size: 22860,
          src: "https://www.mondelezinternational.com/-/media/Mondelez/Country/France/Top-Employer-Europe.png",
          width: 200,
          height: 200,
        },
      },
      {
        image: {
          title: "Top Employer France",
          description: "",
          contentType: "image/jpeg",
          fileName: "Top-Employer-France.png",
          size: 22860,
          src: "https://www.mondelezinternational.com/-/media/Mondelez/Country/France/Top-Employer-France.png",
          width: 200,
          height: 200,
        },
      },
    ],
  },
  styles: {
    blockSlider: "total-blockslider-area",
    blockSliderWrapper: "block-slider-area px-15 md:px-55 mb-70 md:mb-90",
    heading: "text-4xl md:text-6xl font-light mb-60 md:mb-80",
    blockSlide: "pr-30 md:pl-75 md:pr-25",
    subHeading: "text-2xl md:text-5xl font-light mb-10 md:mb-30",
    description: "text-sm md:text-xl pb-15",
    imgWrpr: "max-w-full h-auto mt-20 md:mt-0",
  },
};
