import React from "react";
import { screen, render } from "@testing-library/react";
import Button from "../Button/Button";
import { ButtonData } from "../../../mocks/Button.mock";

// describe("Button", () => {
//   render(<Button {...mockProps} />);
//   test("should render component", () => {
//     expect(screen.getByText("Submit"));
//   });
// });
describe("Button", () => {
  let view = null;
  beforeEach(() => {
    view = render(<Button {...ButtonData} />);
  });
  test("should render Intial Data", () => {
    view
  });

  test("should render Button Text", () => {
    expect(screen.getByText(ButtonData.text))
      .toBeInTheDocument;
  });
})