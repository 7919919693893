import React from "react";
import { screen, render } from "@testing-library/react";
import LandingShopCards from "./LandingShopCards";
import { LandingShopCardsPros } from "../../mocks/LandingShopCards.mock";

describe("LandingShopCards", () => {
  let view = null;
  beforeEach(() => {
    view = render(<LandingShopCards {...LandingShopCardsPros} />);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render Header", () => {
    expect(screen.getAllByText(LandingShopCardsPros.title));
  });
});
