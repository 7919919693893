import React from "react";
import { fireEvent, screen, render, act } from "@testing-library/react";
import SocialLinks from "./SocialLinks";
import { SocialLinksProps } from "../../../mocks/SocialLinks.mock";

describe("SocialLinksProps", () => {
  let view = null;
  beforeEach(() => {
    view = render(<SocialLinks {...SocialLinksProps} onChange={()=>{}}/>);
  });

  test("should render Intial Data", () => {
    view;
  });

});