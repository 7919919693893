import React from "react"
import { render, screen, fireEvent, act } from "@testing-library/react"
import ImageHeroBanner from "./ImageHeroBanner";
import { ImageHeroBannerProps } from "../../mocks/ImageHeroBanner.mock";
describe("ImageHeroBanner", () => {
  let view = null
  beforeEach(() => {
    view = render(<ImageHeroBanner {...ImageHeroBannerProps} />)
  })

  test('should render Intial Data', () => {
    view
  })
    
  test("should render component", () => {
    expect(screen.getAllByText(ImageHeroBannerProps.heading));
  });
    
  test('should render Image', () => {
    const {container } = view;
    expect(container.querySelectorAll('img')).toBeInTheDocument;
  })

  test('should render description', () => {
    expect(screen.getByText(ImageHeroBannerProps.description))
  })

  test('should render Image and src for desktop to be true', () => {
    act(() => {

      window.innerWidth = 1200

      fireEvent(window, new Event("resize"))
    })
    const imageElement = document.querySelector('img')
    expect(imageElement.src).toContain("https://images.ctfassets.net/oqg67vmf6aj9/2yyM6Uabw3DnnetSQPCeYG/1210ed910032764125a3a0539eace8dc/nue_BG.png?fm=webp&q=80")
  })

  test('should render Image and src for mobile to be true', () => {
    act(() => {

      window.innerWidth = 600

      fireEvent(window, new Event("resize"))
    })
    const imageElement = document.querySelector('img')
    expect(imageElement.src).toContain(ImageHeroBannerProps.images.imageMobile.url)
  })

})