import React from "react";
import { fireEvent, screen, render, act } from "@testing-library/react";
import Select from "./Select";
import { SelectProps } from "../../../mocks/Select.mock";

describe("SearchInput", () => {
  let view = null;
  beforeEach(() => {
    view = render(<Select {...SelectProps} onChange={()=>{}}/>);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render with on keyDown", async() => {
    const input = screen.getByPlaceholderText(/Salutation/)
    fireEvent.change(input, { target: { value: "abc" } })
  });
  test("should render with on keyDown", async() => {
    const input = screen.getByTestId('selectId')
    fireEvent.change(input, { target: { value: "abc" } })
  });
});