/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import {
  componentApiIntegrator,
  overrideComponentProps,
} from "../constants/pageType.constants";

const ComponentWrapper = props => {
  const { componentName } = props;
  //const packages = require(`../adapters/package-module`)
  let Component = null;
  // access and render component
  try {
    //check from the package module first
    //Component = packages[componentName]
    // if(!Component) {
    //   // components which are not from the package
    //   Component = require(`../components/${componentName}/${componentName}`).default
    // }
    //add tailwind styles for the components
    Component =
      require(`../components/${componentName}/${componentName}`).default;
    const styles = require(`../components/${componentName}/${componentName}-tw-styles.js`);
    var style = styles[props.variant] || styles["defaultStyle"];
  } catch {
    console.warn(`Component ${componentName} doesn't exist`);
    console.log(componentName);
    Component = null;
  }
  return Component ? <Component {...props} style={style} /> : null;
};

// components wrapper : maps and renders all components with their respective props from contentful
const ContentfulComponents = props => {
  const { componentsProps = [], pageType, products, ...rest } = props;
  if (componentsProps.length === 0) {
    return null;
  }
  return (
    <>
      {componentsProps.map((prop, index) => {
        if (!prop) {
          return null;
        }
        const componentName = Object?.keys(prop)[0];

        // overriding props
        let isOverrideProp = { ...prop[componentName] };
        if (
          overrideComponentProps[pageType] &&
          overrideComponentProps[pageType][componentName]
        ) {
          isOverrideProp = overrideComponentProps[pageType][componentName](
            isOverrideProp,
            products || {},
            rest
          );
        }

        return (
          <ComponentWrapper
            componentName={componentName}
            {...isOverrideProp}
            pageType={pageType}
            key={index}
            {...(componentApiIntegrator.productApi.includes(componentName)
              ? { productList: products }
              : {})}
          />
        );
      })}
    </>
  );
};

ContentfulComponents.propTypes = {
  componentsProps: PropTypes.array,
  pageType: PropTypes.string,
  products: PropTypes.array,
};

ComponentWrapper.propTypes = {
  componentName: PropTypes.string,
};

export default ContentfulComponents;
