export const RichTextModelData = {
  "title": "NUTZUNGSBASIERTE ONLINE-WERBUNG",
  "richText": {
    "json": {
      "nodeType": "document",
      "data": {},
      "content": [
        {
          "nodeType": "heading-2",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "INFORMATIONEN ZU NUTZUNGSBASIERTER ONLINE-WERBUNG\n",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "Das Internet-Angebot bzw. die Website, von der aus Sie gerade verlinkt worden sind, erhebt und verarbeitet anonym Ihr Nutzungsverhalten. Dadurch profitieren Sie als Nutzer, indem Sie Werbung erhalten, die eher zu Ihren Interessensgebieten passt und dadurch, dass insgesamt weniger zufällig gestreute Werbung ausgeliefert wird. Zur Erfassung Ihres Nutzungsverhaltens wird ein Cookie auf Ihrem Rechner gespeichert. Cookies sind kleine Textdateien, die auf der Festplatte Ihres Computers abgelegt werden und die Wiedererkennung ermöglichen, aber keine persönliche Identifikation Ihrer Person zulassen.<br><br>",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "Für die Datenerhebung zuständig ist Mondelez Deutschland Services GmbH & Co. KG, Konsul-Smidt-Straße 21, 28217 Bremen",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "heading-2",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "WELCHE DATEN WERDEN ERFASST?",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "Erfasst werden Informationen über Ihre Aktivitäten auf den von Mondelez Deutschland Services GmbH & Co. KG betriebenen Websites (z.B. Surfverhalten, besuchte Unterseiten der Internet-Angebote, etc.). Da nach geltender Rechtslage nicht völlig ausgeschlossen werden kann, dass die erhobenen Daten einen mittelbaren Personenbezug zulassen, werden sämtliche Nutzungsdaten unter Verwendung eines Pseudonyms gespeichert, so dass eine persönliche Identifikation ausgeschlossen ist. Die aus technischen Gründen übermittelte IP Adresse Ihres Rechners wird dabei nicht gespeichert und nicht zur gesteuerten Einblendung von Werbung verwendet.",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "heading-2",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "WIDERSPRUCH",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "Wenn Sie der entsprechenden Datenerhebung nicht (mehr) zustimmen wollen, können Sie das Speichern von Cookies auf Ihrer Festplatte verhindern, indem Sie in Ihren Browser-Einstellungen \"keine Cookies akzeptieren\" wählen. Sie können Ihren Browser auch so einstellen, dass dieser Sie vor dem Setzen von Cookies fragt, ob Sie einverstanden sind. Schließlich können Sie auch einmal gesetzte Cookies jederzeit wieder löschen. Wie all das im Einzelnen funktioniert, entnehmen Sie bitte der Anleitung Ihres Browser-Herstellers. Wenn Sie keine Cookies akzeptieren, kann dies im Einzelfall zu Funktionseinschränkungen der Webseite führen. Bitte beachten Sie, dass Sie für den Fall einer Cookie-Löschung auch den Opt Out Link erneut aktivieren müssen.",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "heading-2",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "SELBSTREGULIERUNG DDOW",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "Als Unternehmen haben wir uns zudem freiwillig der Selbstregulierung des Deutschen Datenschutzrat Online-Werbung (DDOW) unterworfen. Den für uns gültigen Selbstregulierungskodex finden Sie unter dem Link: www.meine-cookies.org/ddow.html.<br><br>",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "Um Werbung für Sie anhand Ihrer Nutzungsinteressen zu optimieren, haben wir es folgenden Unternehmen gestattet, Nutzungsdaten zu erheben:<br><br>",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          "nodeType": "paragraph",
          "data": {},
          "content": [
            {
              "nodeType": "text",
              "value": "LISTE MIT OBA-DIENSTLEISTERNmehr Infos & deaktivieren",
              "marks": [],
              "data": {}
            }
          ]
        },
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: 'Hello',
              marks: [{ type: 'subscript' }],
            },
            {
              nodeType: 'text',
              value: ' world!',
              marks: [{ type: 'superscript' }],
            },
            {
              nodeType: 'text',
              value: '<html></html>',
              marks: [{ type: 'code' }],
            },
          ],
        },
        {
          "nodeType": "document",
          "data": {},
          "content": [
            {
              "nodeType": "table",
              "data": {},
              "content": [
                {
                  "nodeType": "table-body",
                  "data": {},
                  "content": [
                    {
                      "nodeType": "table-row",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "table-header-cell",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "paragraph",
                              "data": {},
                              "content": [
                                {
                                  "nodeType": "text",
                                  "data": {},
                                  "marks": [],
                                  "value": "Countries"
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "nodeType": "table-row",
                      "data": {},
                      "content": [
                        {
                          "nodeType": "table-cell",
                          "data": {},
                          "content": [
                            {
                              "nodeType": "paragraph",
                              "data": {},
                              "content": [
                                {
                                  "nodeType": "text",
                                  "data": {},
                                  "marks": [],
                                  "value": "Germany"
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          nodeType: 'embedded-entry-inline',
          data: {
            target: {
              sys: {
                type: 'Link',
                linkType: 'Entry',
                id: '3vNbx1hjcsESSICSu2KuWs',
              },
            },
          },
          content: [],
        },
        {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'ordered-list',
              data: {},
              "content": [
                {
                  "nodeType": "text",
                  "data": {},
                  "marks": [],
                  "value": "Germany"
                }
              ]
            }
          ],
        },
        {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'unordered-list',
              data: {},
              "content": [
                {
                  "nodeType": "text",
                  "data": {},
                  "marks": [],
                  "value": "Germany"
                }
              ]
            }
          ],
        },
        {
          nodeType: 'list-item',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  "nodeType": "text",
                  "data": {},
                  "marks": [],
                  "value": "List Item Text"
                }
              ]
            }
          ]
        },
      ]
    },
    "links": {
      "entries": {
        "inline": [
          {
            "sys": {
              "id": "3vNbx1hjcsESSICSu2KuWs",
              "__typename": "Link",
              "url": "/embedd-entry-url",
              "target": "_blank",
              "label": "Embedd Entry Link"
            }
          }
        ]
      }
    }
  },
  "link": null,
  "pageType": "Purple Texture Page",
  "style": {
    "richTextWrapper": "richTextWrapper text-left pt-[150px] relative lg:max-w-[1170px] mx-auto pb-50 px-20 lg:px-0 ",
    "title": "title mx-0 text-white font-RecoletaMedium !text-[30px] leading-[1.25em] md:!text-[48px] md:leading-[1.05em]",
    "mainLink": "mainLink text-white"
  }
}