
export const defaultStyle = {
  contentWrapper: "w-[340px] mx-auto",
  heading: "text-[#da2b1f] mb-[20px] text-4xl font-bold mb-20 ",
  resetPassword: "text-base block mt-2 text-right capitalize text-gray-700",
  term: "term",
  signUpBtn: "signUpBtn w-[25%]",
  labelStyle: "inline-block text-sm mb-2 text-[#2b2b2b] font-bold",
  inputContainer: "lg:mb-3 mb-27 xl:w-96",
  text: "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  select: "",
  chkWrpr: "flex mb-20",
  checkBox: "mr-[10px]",
  checkBoxText: "text-base",
  error: "border-[1px] border-red-500",
  formField:"lg:max-w-[98%]"
}

export const emailSignUp = {
  wrapper: "bg-[#7d69ac] flex lg:flex-row flex-col-reverse w-full",
  contentWrapper: "md:px-[20px] w-full mt-40 md:mt-50 homeSignup",
  imageWrapper: 'imageWrapper px-[20px] w-full mt-30',
  imageStyle: "mx-auto lg:mr-auto max-w-[75%] lg:max-w-[100%]",
  heading: "text-white bg-[#7d69ac] mt-[50px] text-1xl lg:text-3xl p-10 mb-20 IntervogueSoftMedium",
  description: "text-[#2b2b2b] mb-[20px] ml-[5px]",
  resetPassword: "text-base block mt-2 text-right capitalize text-gray-700",
  term: 'term text-white mt-[22px] text-16 leading-[24px] underline text-center lg:max-w-[83%] font-GoodHeadlinePro',
  signUpTop: "lg:max-w-[100%] text-center",
  signUpBtn: "emailSignUpBtn text-center text-16 tracking-[4px] leading-[15px]  font-GoodHeadlinePro font-bold text-[#3B2774] uppercase bg-[#8BD1F0] py-[12px] px-20 rounded-[22px] mt-[8px] w-[241px] h-[40px]",
  labelStyle: "inline-block text-sm mb-2 text-[#2b2b2b] font-bold hidden",
  inputContainer: "lg:mb-3 mb-27 xl:w-96",
  text: "form-control block w-full px-3 py-10 px-25 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  // captchaError: '',
  fieldWrpr: 'mb-[24px]',
  captchaWrpr: "captchaWrpr pl-40",
  captchaError: 'border-2 border-red-500',
  errorWrpr: 'has-error',
  formField:"lg:max-w-[98%] homeSignup",
  notValid: 'notvalid pointer-events-none opacity-[0.7]'
}

