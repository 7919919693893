import React from "react";
import { screen, render } from "@testing-library/react";
import Icon from "./Icon";

const iconProps = {
  name: "InstagramIcon", 
  className: "inst_class"
}

const iconProps1 = {
  name: "",
  className: "inst_class"
}
const iconProps2 = {
  name: "facebookIcon",
  className: "inst_class"
}

describe("Icon", () => {
  test("should render component", () => {
    render(<Icon {...iconProps} />);
  });
  
  test("should render component false name", () => {
    render(<Icon {...iconProps1} />);
  });
  
  test("should render component false name", () => {
    render(<Icon {...iconProps2} />);
  });
});
