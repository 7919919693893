export const LandingShopCardsPros ={
    "componentName": "LandingShopCards",
    "title": 'LandingShopCards',
    "backgroundImage": {
        "title": "lilac-texture-square",
        "description": "lilac-texture-square",
        "url": "https://images.ctfassets.net/oqg67vmf6aj9/1mp0Od53V0YWvam7UnC1Kw/f8a485322de2f8843df6b7553808711c/lilac-texture-square.jpg"
    },
    "cardsCollection": {
        "items": [
            {
                "title": null,
                "text": null,
                "elementId": null,
                "variant": "TWO_COLUMN_IMAGE",
                "gaEventClass": null,
                "gaEventLabel": null,
                "images": {
                    "internalTitle": "Shop Card1 Image - NEW",
                    "altText": "img",
                    "imageDesktop": {
                        "title": "shopCard1",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/4GP124tARCBzYG2Llmtb9n/d5370e53403791c4b1c3f431ce215e9f/shopCard1.jpg"
                    },
                    "imageMobile": {
                        "title": "shopCard6",
                        "description": null,
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/4fqMU2y5AwKjyavOY4mdfL/4a89e3cf0711254d0b4ed5bda9d47734/shopCard1.jpg"
                    },
                    "bgDesktop": null,
                    "bgMobile": null
                },
                "link": {
                    "label": "MEHR ERFAHREN",
                    "title": "MEHR ERFAHREN",
                    "url": "/neues/osteraktionen",
                    "target": "_self",
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            },
            {
                "title": null,
                "text": null,
                "elementId": null,
                "variant": "TWO_COLUMN_IMAGE",
                "gaEventClass": null,
                "gaEventLabel": null,
                "images": {
                    "internalTitle": "Shop Card2 Image - NEW",
                    "altText": "img",
                    "imageDesktop": {
                        "title": "Entedecke Die",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/3SM02to0YaTSYxqaajHBbQ/34515dc072dca8e1620599787ed8327a/shopCard2.jpg"
                    },
                    "imageMobile": {
                        "title": "Entedecke Die",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/2ThPuALb3ct49RyIPnGkhf/f9d1a1faa6f4fa882301f5e435216dac/shopCard2.jpg"
                    },
                    "bgDesktop": null,
                    "bgMobile": null
                },
                "link": {
                    "label": "ZUM GEWINNSPIEL",
                    "title": "ZUM GEWINNSPIEL",
                    "url": null,
                    "target": "_self",
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            },
            {
                "title": null,
                "text": null,
                "elementId": null,
                "variant": "TWO_COLUMN_IMAGE",
                "gaEventClass": null,
                "gaEventLabel": null,
                "images": {
                    "internalTitle": "Shop Card3 Image - NEW",
                    "altText": "img",
                    "imageDesktop": {
                        "title": "meister",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/1TpmYvbZhTO7SCmHujHG2S/b9d067a2b7de2b0ef52fd1a9eada25fd/shopCard3.jpg"
                    },
                    "imageMobile": {
                        "title": "meister",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/3dcjLGCf1igYVGIS8LfrJd/1dd5c1b4a4437fa2853a1852514e8ced/shopCard3.jpg"
                    },
                    "bgDesktop": null,
                    "bgMobile": null
                },
                "link": {
                    "label": "JETZT MITMACHEN",
                    "title": "JETZT MITMACHEN",
                    "url": "https://buzzer.fcmilka.de/",
                    "target": "_self",
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            },
            {
                "title": null,
                "text": null,
                "elementId": null,
                "variant": "TWO_COLUMN_IMAGE",
                "gaEventClass": null,
                "gaEventLabel": null,
                "images": {
                    "internalTitle": "Shop Card4 Image - NEW",
                    "altText": "img",
                    "imageDesktop": {
                        "title": "Was HABT",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/4h59drFevTTwyTNopPnaLf/eda1b4f915b2ab9a94d8ddd781f44a7f/shopCard4.jpg"
                    },
                    "imageMobile": {
                        "title": "Was HABT",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/3nYQQDm194DYfz16LBaIUG/a85d831c4bd924af194e0284969cd963/shopCard4.jpg"
                    },
                    "bgDesktop": null,
                    "bgMobile": null
                },
                "link": {
                    "label": "MEHR ERFAHREN",
                    "title": "MEHR ERFAHREN",
                    "url": "/neues/zarteste-Milka",
                    "target": "_self",
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            },
            {
                "title": null,
                "text": null,
                "elementId": null,
                "variant": "TWO_COLUMN_IMAGE",
                "gaEventClass": null,
                "gaEventLabel": null,
                "images": {
                    "internalTitle": "Shop Card5 Image - NEW",
                    "altText": "img",
                    "imageDesktop": {
                        "title": "milka MMMAX",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/1X8qJ77TSwztoxQmMt5E5U/ede5f256eb3049974d4cc77f6c29424c/shopCard5.jpg"
                    },
                    "imageMobile": {
                        "title": "milka MMMAX",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/3jRzuC716K3lLd0QGwRAyM/9721fd03fe94d1f8d0d6e05a23adda0b/shopCard5.jpg"
                    },
                    "bgDesktop": null,
                    "bgMobile": null
                },
                "link": {
                    "label": "MEHR ERFAHREN",
                    "title": "MEHR ERFAHREN",
                    "url": "https://mmmax.milka.de/",
                    "target": "_self",
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            },
            {
                "title": null,
                "text": null,
                "elementId": null,
                "variant": "TWO_COLUMN_IMAGE",
                "gaEventClass": null,
                "gaEventLabel": null,
                "images": {
                    "internalTitle": "Shop Card6 Image - NEW",
                    "altText": "img",
                    "imageDesktop": {
                        "title": "Star Den",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/2PRuoiSBTf7GTU0WYm1G4n/2ac9f7b071ceb2f147a39a3b7c5e666a/shopCard6.png"
                    },
                    "imageMobile": {
                        "title": "Star Den",
                        "description": "",
                        "url": "https://images.ctfassets.net/oqg67vmf6aj9/7E14suKDcGvoNbiUmAnOyF/3d1c155233d44ec5ae90bb49642d5638/shopCard6.png"
                    },
                    "bgDesktop": null,
                    "bgMobile": null
                },
                "link": {
                    "label": "MEHR ERFAHREN",
                    "title": "MEHR ERFAHREN",
                    "url": "/neues/haselnusscreme",
                    "target": "_self",
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            }
        ]
    },
    "variant": "TWO_COLUMN_IMAGE_CARDS",
    "pageType": "Home Page",
    "style": {
        "topCard": "topCard",
        "title": "title text-center text-2xl lg:text-6xl text-[#3b2774]",
        "container": "block lg:grid lg:grid-cols-2 lg:mx-auto",
        "item": "overflow-hidden min-h-[350px]",
        "imgWrpr": "border-0 rounded-[0]",
        "bgCard": "bgCard pb-[50px]"
    }
}