import React from "react";
import { screen, render, fireEvent, act, waitFor } from "@testing-library/react";
import Signup from "./Signup";
import { SignUpProps } from "../../mocks/SignUp.mock";
import {unsubscribeNewsLetter } from '../../helpers/services'

describe('SignUp', () => {
  let view = null
  beforeEach(async () => {
    await act(async () =>  view = render(<Signup {...SignUpProps}  onSubmitAPI={unsubscribeNewsLetter} />));
  })

  

  test('should render search box with given filter prop', () => {
    expect(screen.getByText('*Pflichtfelder'))
  })

  test('should change search param in url when search button is clicked', async () => {
    const { container } = view
    const input_ele = container.querySelectorAll('input')[1]
    fireEvent.change(input_ele, { target: { value: 'milka new'}})
  })

  test('should click button', () => {
    view = render(<Signup {...SignUpProps} onSubmitAPI={unsubscribeNewsLetter} />)
    const { container } = view
    const btn = container.querySelectorAll('.emailSignUpBtn')
    act(() => {
      fireEvent.click(btn[0])
    })
    waitFor(() => {
      expect(unsubscribeNewsLetter).toBeCalled()
    })
  })


  test('should render Product items first 4', () => {
    render(<Signup {...SignUpProps} onSubmitAPI={unsubscribeNewsLetter} forgetPassword={{label: 'label',url: "url"}} variant={'unSubscribing'}
      heading={"Sign Up"} description={"description"} />)
  })

  test('should render with isReCaptch', () => {
    const {container} = render(<Signup {...SignUpProps} isReCaptch={true} onSubmitAPI={unsubscribeNewsLetter} forgetPassword={{ label: 'Forgot password', url: '/forgot-passsword' }} variant={'unSubscribing'}
      heading={"Sign Up"} description={"description"} />)
  })

  test('should render API error', () => {
    let myPromise = new Promise(function(myResolve, myReject) {
      () =>{throw Error },
      () =>{throw Error }
    });
    const view = render(<Signup{...SignUpProps} onSubmitAPI={()=>myPromise} variant={"variant"} />)
    const { container } = view;
    const btn = container.querySelectorAll('.signUpBtn')
    act(() => {
      fireEvent.click(btn[0])
    })
  })
})