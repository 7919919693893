import React from "react";
import { screen, render, fireEvent,act } from "@testing-library/react";
import Input from "./Input";
import {Props, Props1 } from '../../../mocks/InputProps'

describe("Input", () => {
  let view = null
  test("should render component", async() => {
    await act(async () =>  view = render(<Input {...Props} />));
  });
  
  test("should render component false name", () => {
    render(<Input {...Props1} />);
  });
  
  test("should render component false name", () => {
    render(<Input {...Props} errorMessage={"some error message"} />);
  });

  test("on change", () => {
    const { container } = render(<Input {...Props1} />);
    const input_ele = container.querySelectorAll('input')[0]
    fireEvent.change(input_ele, { target: { value: 'milka new'}})
  });

  test("on blur", () => {
    const { container } = render(<Input {...Props1}  subText={'required'} />);
    const input_ele = container.querySelectorAll('input')[0]
    fireEvent.blur(input_ele, { target: { value: 'milka new'}})
  });

  test("password button", () => {
    const { container } = render(<Input {...Props1}  name={'password'} />);
    const input_ele = container.querySelectorAll('button')[0]
    fireEvent.click(input_ele)
  });

});
