import React from "react";
import { render, screen, fireEvent } from "@testing-library/react";
import DynamicTable from "./DynamicTable";
import { DynamicTableData } from "../../../mocks/DynamicTable.mock";

describe("DynamicTable", () => {
  let view = null;
  beforeEach(() => {
    view = render(<DynamicTable {...DynamicTableData} />);
  });
  test("should render Intial Data", () => {
    view
  });

  test("should render rowHeading", () => {
    expect(screen.getByText("NÄHRWERTE"))
      .toBeInTheDocument;
  });

  test("should render rowData", () => {
    expect(screen.getByText("Energie (Brennwert)"))
      .toBeInTheDocument;
  });
  
})