import React from "react"
import PropTypes from "prop-types"
import Image from "../UI/Image/Image"
import DynamicTable from "../UI/DynamicTable/DynamicTable"
import * as styles from "./Nutrientinfo-tw-styles"

const NutrientInfo = props => {
  const style = styles.nutrientStyles
  const { text, recipeTitle, nutritionTitle, nutrientData, image } = props
  return (
    <>
      <div className={style?.nutrientMainWrpr}>
        <div className={style?.nutrentWrpr}>
          <div className={style?.nutrientTblTxt}>
            <div className={style?.moodTxt}>{text}</div>
            <div className={style?.recipeText}>{recipeTitle}</div>

            <div className={style?.nutrientTblImgMob}>
              <Image contentfulImage={image} className={style?.recipeImage} />
            </div>
            <div className="mx-auto md:w-[437px]">
              <h2 className={style?.nutritionalTitle}>{nutritionTitle}</h2>
              <div className="recipe_nutritions">
                <DynamicTable
                  rowData={nutrientData?.rowData}
                  rowHeading={nutrientData?.rowHeading}
                  variant={"recipe_nutrition"}
                />
              </div>
            </div>
          </div>
          <div className={style?.nutrientTblImg}>
            <Image contentfulImage={image} className={style?.recipeImage} />
          </div>
        </div>
      </div>
    </>
  )
}

NutrientInfo.propTypes = {
  text: PropTypes.string,
  recipeTitle: PropTypes.string,
  nutritionTitle: PropTypes.string,
  nutrientData: PropTypes.object,
  image: PropTypes.object,
}

export default NutrientInfo
