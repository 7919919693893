import React from "react"
import PropTypes from "prop-types"
import Image from "../UI/Image/Image"
import useImage from "../../hooks/useImage"
import Link from "../UI/Link/Link"
import { get } from "../../helpers/utils"
import { convertToWebp } from "../../helpers/ImageHelper"
import * as styles from "./HomePageCtc-tw-styles"

const HomePageCTC = props => {
  const {
    title,
    description,
    buttonText,
    image,
    backgroundImage,
    imagesCollection,
    link,
  } = props
  const style = styles?.defaultStyle
  const { altText,foregroundImg: cowImage } = useImage
  (image)
  const images = get(imagesCollection, "items", [])
  return (
    <div
      className={style?.container}
      style={{ backgroundImage: `url(${convertToWebp(backgroundImage?.url)})` }}
    >
      <div className={style?.imageWrap1}>
        <div className={style?.cowImage}>
          <Image contentfulImage={cowImage} alt={altText}/>
        </div>
      </div>
      <div className={style?.imageWrap2}>
        <h3 className={style?.title}>{title}</h3>
        <p className={style?.description}>{description}</p>

        <Link {...link} className={style?.button}/>
      </div>
      <div className={style?.imageWrap3}>
        {images?.map((img, index) => (
          <div key={`home_page_Ctc_${index}`} className={style?.innerWrap}>
            <Image key={index} contentfulImage={img} alt={img?.title}/>
          </div>
        ))}
      </div>
    </div>
  )
}

HomePageCTC.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  backgroundImage: PropTypes.object,
  image: PropTypes.object,
  imagesCollection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  link: PropTypes.object,
}

export default HomePageCTC
