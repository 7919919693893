const query = id  => `
{
    timelineWrapper(id: "${id}") {
      timelineContentsCollection {
        items {
          title
          description
          image {
            url
            width
            height
          }
          backgroundImage {
            url
          }
          variant
        }
      }
    }
  }
`

module.exports = { query }