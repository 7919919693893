export const defaultStyle = {
  textContent:
    "textContent text-left px-8 lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] lg:mx-auto my-0",
  title: "text-center text-25 leading-25 py-25 tracking-[1px]",
  shortDesc: "shortDesc",
  heroLink: "heroLink",
};

export const TEXT_CONTENT_LEFT = {
  textContent:
    "textContent text-left px-8 lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] lg:mx-auto my-0",
  title: "text-center text-25 leading-25 py-25 tracking-[1px]",
  shortDesc: "shortDesc",
  heroLink: "heroLink",
};

export const TEXT_CONTENT_RIGHT = {
  textContent:
    "textContent text-left px-8 lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] lg:mx-auto my-0",
  title: "text-center text-25 leading-25 py-25 tracking-[1px]",
  shortDesc: "shortDesc",
  heroLink: "heroLink",
};
export const DOWNARROW_TEXT_CONTENT_CENTER_ALIGN = {
  textContent:
    "text-center w-full max-w-[980px] text-[15px] mt-60 mx-auto font-RecoletaRegular",
  title:
    "text-center font-[500] text-30 leading-[32px] md:text-40 md:leading-[46px] tracking-[0.4375px]",
  shortDesc: "shortDesc text-20 md:text-25 leading-[29px] mx-20 mt-20 mb-50",
  heroLink: "heroLink",
};
export const TEXT_CONTENT_CENTER_ALLIGNED = {
  textContent:
    "text-center w-full lg:max-w-[600px] max-w-[980px] text-[15px] pb-30 pt-60 mx-auto",
  title:
    "text-center font-RecoletaMedium font-medium text-[36px] lg:mb-0 lg:text-[48px] lg:leading-[1.3] leading-[1.2] md:text-40 md:leading-[46px]",
  shortDesc:
    "shortDesc text-18 leading-[1.4] md:text-20 md:leading-[29px] mx-20 mt-20 mb-50 lg:mb-60",
  heroLink:
    "heroLink newsletter-img max-w-[150px] block lg:max-w-[200px] text-center mx-auto",
};
export const TEXT_CONTENT_CENTER_ALLIGN_FONTCHANGE = {
  ...TEXT_CONTENT_CENTER_ALLIGNED,
  textContent:
    "text-center w-full lg:max-w-[700px] max-w-[980px] text-[15px] pb-30 pt-60 mx-auto",
  title:
    "text-center font-RecoletaRegular font-bold text-[36px] lg:mb-0 lg:text-[48px] lg:leading-[57px] leading-[43px] tracking-[0.4375px]",
  shortDesc:
    "shortDesc font-RecoletaRegular text-18 leading-[1.4] md:text-20 md:leading-[29px] mx-20 mt-20 mb-50 lg:mb-60",
};
export const TEXT_CONTENT_CENTER_ALLIGN_FONTCHANGE_ZARTE_BOTSCHAFT = {
  ...TEXT_CONTENT_CENTER_ALLIGNED,
  textContent:
    "text-center w-full lg:max-w-[700px] max-w-[980px] text-[15px] pb-30 pt-60 mx-auto relative",
  shortDesc:
    "shortDesc font-RecoletaRegular text-18 leading-[1.4] md:text-20 md:leading-[29px] mx-10 lg:mx-[0px] mt-[12px] mb-50 lg:mb-60",
  heroLink:
    "heroLink newsletter-img max-w-[150px] block lg:max-w-[200px] text-center mx-auto ZARTE_BOTSCHAFT_IMG",
};
export const CHOCOLATE_MAKING_CENTER_ALIGN = {
  textContent: "max-w-[980px] w-full mx-auto md:mb-70 pb-15",
  title: "text-center text-25 leading-25 py-25 tracking-[1px]",
  shortDesc:
    "text-center md:text-left shortDesc text-14 leading-[23px] md:text-20 md:leading-[26px] mt-20 mx-15 pb-50",
  heroLink: "heroLink",
};
export const TEXT_INTRO_CONTENT={
  textContent:"textContent max-w-[900px] mt-0 mx-auto relative lg:py-15 ",
  title: "text-[40px] mt-0 tracking-normal md:pb-15 md:text-[56px] leading-[1.25] md:leading-[65px] text-center font-RecoletaRegular font-medium text-purple w-full mb-0",
  shortDesc: "shortDesc text-[20px] md:text-[25px] leading-[26px] md:leading-[31px] w-[98%] md:w-full font-RecoletaRegular text-center mb-15 lg:mb-50 ",
}

export const PURPLE_BACKGROUND_TEXT={
  textContent:"textContent text-white relative py-15 mx-auto bg-[#7d69ac]",
  title: "max-w-[770px] mt-0 tracking-normal mx-auto text-center md:text-left text-[25px] leading-[31px] md:text-[40px] md:leading-[46px] font-RecoletaRegular font-medium w-full mb-0",
  shortDesc: "shortDesc pb-15 md:pb-25 text-[20px] max-w-[770px] leading-[26px] w-[98%] md:w-full font-IntervogueRegular text-center md:text-left mx-auto "
}
export const TEXT_CONTENT_LEFT_TAUERN = {
  textContent:"textContent text-left lg:pt-0 text-[16px] text-[#3b2774] lg:mx-auto my-0 font-RecoletaRegular",
  title: "text-left text-[30px] lg:text-[48px] lg:max-w-[930px] mx-auto leading-[50px] pt-[60px] tracking-[1px] font-RecoletaMedium",
  shortDesc: "shortDesc text-left pr-[80px] pb-[25px] lg:max-w-[930px] lg:pr-[229px] md:pr-[229px] mx-auto",
  heroLink: "heroLink",
}

export const TEXT_CONTENT_CENTER_ALLIGNED_ZARTE = {
  textContent:"text-center w-full lg:max-w-[1170px] mx-auto text-[15px] mx-auto",
  title: "text-center font-RecoletaRegular font-medium text-[24px] leading-[45px] mb-20 md:text-[60px] md:leading-[65px] tracking-[0.4375px] sm:font-medium",
  shortDesc: "shortDesc shortDesc-zarte font-RecoletaRegular xl:w-[100%] xl:mx-auto text-24 leading-[1.4] md:text-40 md:leading-[46px] mx-15 md:mx-auto mt-20 md:mt-0 mb-[15px] lg:mb-60",
  heroLink: "heroLink newsletter-img max-w-[150px] block lg:max-w-[200px] text-center mx-auto",
}

export const TEXT_CONTENT_CENTER_BGPURPLE = {  /*Uber Milka text banner */
  textContent:
    "text-center w-full text-[15px] mx-auto font-RecoletaMedium centerText heroBannerTextContiner",
  title:
    "text-center text-[24px] lg:text-[43px] md:text-[43px] sm:text-[43px] leading-[52px] mt-0 text-white px-10 pb-20 ",
  shortDesc:
    "shortDesc font-RecoletaRegular text-20 md:text-25 leading-[26px] md:leading-[31px] mb-15 w-[98%] text-center md:w-full md:max-w-[600px] mx-auto text-white",
  heroLink:
    "heroLink uber-milka-heroLink w-auto h-auto text-16 leading-[22px] bg-white px-40 pt-10 pb-8 rounded-[22px] inline-block mb-[48px] text-purple font-GoodHeadlinePro tracking-[4px] font-bold uppercase w-[241px] h-[40px]",
};
export const TEXT_CONTENT_CENTER_ENGAGEMENT = {
  textContent: "text-center w-full max-w-[680px] xl:max-w-[1185px] mb-15 lg:mb-50 mx-auto px-20 md:px-0",
  title:
    "text-center font-RecoletaRegular px-15 md:px-0 mt-[40px] md:mt-0 mb-[15px] text-[26px]leading-[28px] md:text-[40px] lg:leading-[46px]",
  shortDesc:
    "shortDesc text-left text-18 md:text-20 leading-[26px] w-full lg:max-w-[980px] md:px-0 mx-auto pt-0 pb-15 lg:py-15 mb-20 md:mb-50",
  heroLink:
    "heroLink newsletter-img max-w-[150px] block lg:max-w-[200px] text-center mx-auto",
  wrapper: "wrapper max-w-full",
};
export const TEXT_CONTENT_ENGAGEMENT = {
  textContent: "text-center w-full px-15 md:px-0 max-w-[680px] userEngage xl:max-w-[1185px] mt-50 pt-40 lg:pb-40 md:pt-80 mx-auto",
  title:
    "text-center font-RecoletaRegular lg:mb-[15px] text-[25px] leading-[31px] md:text-[40px] md:leading-[46px]",
  shortDesc:
    "shortDesc text-center text-18 md:text-20 leading-[26px] w-auto lg:max-w-[980px] md:px-0 mx-auto py-15 lg:pt-0 engageDesc",
  heroLink:
    "heroLink newsletter-img max-w-[150px] block lg:max-w-[200px] text-center mx-auto",
  wrapper: "wrapper max-w-full white_texture_page",
};
export const TEXT_CONTENT_TOP_ENGAGEMENT = {
  textContent:
    "text-center xl:max-w-[980px] max-w-[680px] w-full text-[15px] md:pt-50 mx-auto",
  title:
    "text-center font-RecoletaRegular mt-[50px] md:mt-0 mb-[15px] text-40 leading-[46px] md:text-[60px] md:leading-[65px] tracking-[0.4375px]",
  shortDesc:
    "shortDesc text-18 md:text-20 leading-[26px] px-[15px] w-full lg:max-w-[980px] md:px-0 mx-auto mb-15 md:mb-50 font-RecoletaRegular",
  heroLink:
    "heroLink newsletter-img max-w-[150px] block lg:max-w-[200px] text-center mx-auto",
};
export const TEXT_CONTENT_BOTTOM_ENGAGEMENT = {
  textContent:
    "textContent text-left max-w-[680px] userEngage xl:max-w-[1185px] text-18 leading-[18px] md:text-[20px] md:leading-[26px] mx-auto px-15 md:px-0 w-full pt-0 pb-40 lg:pt-20 md:pb-[115px] break-words",
  title: "text-center text-25 leading-25 py-25 tracking-[1px]",
  shortDesc: "shortDesc",
  heroLink: "heroLink",
  wrapper: "wrapper max-w-full white_texture_page",
};
export const TEXT_CONTENT_CENTER_HOHE = {
  textContent:
    "textContent text-left pl-20 lg:pt-0 pb-[25px] lg:w-[950px] lg:pl-[50px] mx-auto w-[100%] md:w-[85%] text-[15px] lg:mb-[5px] lg:mx-auto my-0 font-RecoletaMedium",
  title:
    "text-left text-[30px] lg:text-[48px] leading-[1.2] pt-25 pb-[10px]",
  shortDesc: "shortDesc shortDesc-artenvielfalt w-[95%] lg:w-[74%] text-left",
  heroLink: "heroLink",
};
export const STATIC_MDLZ_TITLE = {
  title: "text-white font-GoodHeadlinePro font-bold uppercase text-left mt-15 text-[24px] leading-[1.154em] md:text-[36px] max-w-[1170px] lg:px-0 mx-auto px-20",
}
export const STATIC_MDLZ_WRAPPER_TITLE = {
  textContent:"pt-[150px] font-GoodHeadlinePro",
  title: "title text-white font-GoodHeadlinePro font-bold px-20 !text-[30px] leading-[1.25em] md:!text-[48px] md:leading-[1.05em] max-w-[1170px] lg:px-0 mx-auto",
}

export const TEXT_CONTENT_COOKIE = {
  textContent:
  "richTextWrapper text-left pt-[150px] relative lg:max-w-[1170px] mx-auto pb-50 px-20 lg:px-0 font-GoodHeadlinePro",
  title:
  "title mx-0 text-white font-GoodHeadlinePro !text-[30px] leading-[1.25em] md:!text-[48px] md:leading-[1.05em]",
  shortDesc: "shortDesc shortDesc-artenvielfalt w-[95%] lgw-[75%] text-left",
  heroLink: "heroLink",
}
export const SMALL_TEXT_CONTAINER = {
  textContent:
    "text-center w-full lg:max-w-[600px] max-w-[980px] text-[15px] mb-40 pt-0 mx-auto",
  title:
    "text-center font-RecoletaRegular font-bold text-[29px] lg:mb-0 lg:text-[48px] lg:leading-[1.3] leading-[1.2] md:text-40 md:leading-[46px] tracking-[0.4375px]",
  shortDesc:
    "shortDesc text-[15.3px] leading-[1.4] md:text-17 lg:text-17 md:leading-[23.5px] lg:leading-[23.4px] px-10 lg:mx-0 mt-0 pb-30 lg:mb-40",
  heroLink:
    "heroLink newsletter-img max-w-[150px] block lg:max-w-[200px] text-center mx-auto",
}