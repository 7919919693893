export const ImageCardsLayoutProps = {
  componentName: "ImageCardsLayout",
  title: null,
  text: null,
  variant: "FIVE_COLUMN_CARDS_LAYOUT",
  isSlider: true,
  sliderSettings: null,
  link: {
    label: "testlink",
  },
  cardsCollection: {
    items: [
      {
        title: null,
        text: null,
        elementId: null,
        variant: null,
        gaEventClass: null,
        gaEventLabel: null,
        image: {
          url: "https://images.ctfassets.net/oqg67vmf6aj9/4tK46JG6Q9ym8zv2tdZU1l/c15b6abef76cfcbb229a7988683b0030/almauftrieb3.jpg",
          width: 250,
          height: 250,
          title: "almauftrieb3",
          description: "",
        },
        images: null,
      },
      {
        title: null,
        text: null,
        elementId: null,
        variant: null,
        gaEventClass: null,
        gaEventLabel: null,
        image: {
          url: "https://images.ctfassets.net/oqg67vmf6aj9/6cdGMElXI7pFD6Ry46MnRw/6e90860eeb3e374aa24e920a4f78de38/almauftrieb4.jpg",
          width: 250,
          height: 250,
          title: "almauftrieb4",
          description: "",
        },
        link: null,
        images: null,
      },
      {
        title: null,
        text: null,
        elementId: null,
        variant: null,
        gaEventClass: null,
        gaEventLabel: null,
        image: {
          url: "https://images.ctfassets.net/oqg67vmf6aj9/1rZNtW4q220iQvJD4ek1NS/b3386d7156207446dcb99407022b4b11/almauftrieb5.jpg",
          width: 250,
          height: 250,
          title: "almauftrieb5",
          description: "",
        },
        link: null,
        images: null,
      },
    ],
  },
  pageType: null,
  style: {
    container: "",
    imgWrpr:
      "h-min overflow-hidden rounded-[2rem] px-auto text-sm tracking-wide text-darkBlue font-medium before:pr-5 w-[195px] grid",
    heading: "text-xl text-white text-center",
    description: "text-xl text-white px-auto text-center",
    linkWrpr: "",
    btnStyle: "hidden",
    contentWrpr: "relative",
    img: "rounded-[2rem]",
    cardsSliderWrapr: "",
    cardWrapr:
      "cardWrapr text-white  text-[40px] px-auto w-full text-center pt-50 font-bold",
    cardDesc:
      "cardDesc text-white  text-[18px] px-auto lg:w-1/3 mx-auto w-full text-center pb-40",
    cardWrpr: "lg:w-[1170px] mx-auto max-w-full",
  },
};

export const carditems = [
  {
    title: null,
    text: null,
    elementId: null,
    variant: null,
    gaEventClass: null,
    gaEventLabel: null,
    image: {
      url: "https://images.ctfassets.net/oqg67vmf6aj9/4tK46JG6Q9ym8zv2tdZU1l/c15b6abef76cfcbb229a7988683b0030/almauftrieb3.jpg",
      width: 250,
      height: 250,
      title: "almauftrieb3",
      description: "",
    },
    link: {
      url: "test.com",
      label: "label",
    },
    images: null,
  },
  {
    title: null,
    text: null,
    elementId: null,
    variant: null,
    gaEventClass: null,
    gaEventLabel: null,
    image: {
      url: "https://images.ctfassets.net/oqg67vmf6aj9/6cdGMElXI7pFD6Ry46MnRw/6e90860eeb3e374aa24e920a4f78de38/almauftrieb4.jpg",
      width: 250,
      height: 250,
      title: "almauftrieb4",
      description: "",
    },
    link: {
      url: "test.com",
      label: "label",
    },
    images: null,
  },
  {
    title: null,
    text: null,
    elementId: null,
    variant: null,
    gaEventClass: null,
    gaEventLabel: null,
    image: {
      url: "https://images.ctfassets.net/oqg67vmf6aj9/1rZNtW4q220iQvJD4ek1NS/b3386d7156207446dcb99407022b4b11/almauftrieb5.jpg",
      width: 250,
      height: 250,
      title: "almauftrieb5",
      description: "",
    },
    link: {
      url: "test.com",
      label: "label",
    },
    images: null,
  },
];
