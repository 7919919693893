import React from "react";
import { screen, render } from "@testing-library/react";
import ProductCategoryTags from "./ProductCategoryTags";
import { ProductCategoryTagsData } from "../../mocks/ProductCategoryTags.mock";

describe('ProductCategoryTags', () => {
  let view = null

  beforeEach(() => {
    view = render(<ProductCategoryTags {...ProductCategoryTagsData} />)
  })

  test('should render component', () => {
    expect(screen.getAllByText('Tags'))
  })

  test('should render Product Tags passed', () => {
    const { container } = view
    const list_ele = container.querySelectorAll('.slick-slide')
    expect(list_ele.length).toEqual(7)
  })

  test('should tag contains the link passed', () => {
    const { container } = view
    const list_ele = container.querySelectorAll('a[href="/alle-produkte/tafel-schokolade/"]')
    expect(list_ele.length).toEqual(1)
  })

  test('should accept linksCollection props as array', () => {
    const updatedProps = {
      ...ProductCategoryTagsData,
      linksCollection: [
        ...ProductCategoryTagsData?.linksCollection?.items
      ],
      sliderSettings: null,
      isSlider: false
    }
    view = render(<ProductCategoryTags {...updatedProps} />)
    const { container } = view
    const list_ele = container.querySelectorAll('.linkCardsWrpr')
    expect(list_ele.length).toEqual(7)
  })

  test('should render without props', () => {
    view = render(<ProductCategoryTags title={'Tags'} sliderSettings={{
      hiddenDotes: false
    }} />)
    expect(screen.getAllByText('Tags'))
  })

})