export const nutrientStyles = {
  moodTxt:
    "mood-text md:text-[30px] text-[25px] mb-10 relative flex justify-center md:justify-start leading-[30px]",
  recipeText:
    "recipeText text-[30px] text-center leading-[30px] md:px-0 px-20 lg:px-auto md:mb-[25px] mb-15  tracking-[1px]",
  nutritionalTitle: "nutritionalTitle text-[25px] md:text-[30px] my-20 leading-[40px]",
  nutrientMainWrpr: "bg-darkBlue py-[70px] flex",
  nutrentWrpr:
    "nutrentWrpr  lg:w-[1170px] md:w-[457px] w-full mx-auto flex flex-wrap lg:px-[150px] md:px-10",
  nutrientTblTxt: "nutrientTblTxt text-white lg:w-[42%] w-full md:px-0 px-10 lg:px-auto",
  nutrientTblImg: "nutrientTblImg lg:w-[58%] w-full hidden lg:block",
  recipeImage: "rounded-[20px] md:mx-auto",
  nutrientTblImgMob:
    "nutrientTblImg lg:w-1/2 w-full lg:hidden  justify-center lg:flex",
}
