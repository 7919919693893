import React from "react";
import { screen, render, fireEvent } from "@testing-library/react";
import AccordionMenu from "./AccordionMenu";
import { AccordionMenuData } from "../../../mocks/AccordionMenu.mock";

describe('AccordionMenu', () => {
  let view = null
  beforeEach(() => {
    view = render(<AccordionMenu {...AccordionMenuData} />)
  })

  test('should render component', () => {
    expect(screen.getAllByText('Accordion Menu Heading'))
  })

  test('should open accordion on click', () => {
    const { container } = view
    const acc_ele = container.querySelectorAll('.accordianWrapper')[0].firstChild
    fireEvent.click(acc_ele)
    const class_check = container.querySelectorAll('.contentExpand')
    expect(class_check.length).toEqual(1)
  })

  test('should close accordion when clicked again', () => {
    const { container } = view
    const acc_ele = container.querySelectorAll('.accordianWrapper')[0].firstChild
    fireEvent.click(acc_ele)
    fireEvent.click(acc_ele)
    const class_check = container.querySelectorAll('.contentExpand')
    expect(class_check.length).toEqual(0)
  })

  test('should render children when passed', () => {
    view = render(<AccordionMenu {...AccordionMenuData} ><div>Accordion Content</div></AccordionMenu>)
    expect(screen.getAllByText('Accordion Content'))
  })

})