import React from "react";
import { screen, render } from "@testing-library/react";
import ArticleVideo from "./ArticleVideo";
import { ArticleVideoProps } from "../../mocks/articleVideo.mocks";

describe("ArticleVideo", () => {
  let view = null;
  beforeEach(() => {
    view = render(<ArticleVideo {...ArticleVideoProps} />);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render Header", () => {
    expect(screen.getAllByText("Article Video"));
  });

  test("should render Description", () => {
    expect(
      screen.findByTitle("It is time to get to know the UK no.1 cream cheese")
    );
  });

  test("showing video title", () => {
    expect(screen.findByTitle("sample video"));
  });

  test("showing heading ", () => {
    let heading = screen.getByText("Article Video");
    expect(heading).toBeInTheDocument;
  });

  test("should not add content reveresed style", () => {
    view = render(<ArticleVideo {...ArticleVideoProps} isContentReversed={false} />);
    const { container } = view
    const contnt_rev_ele = container.querySelectorAll('.flex-col-reverse')
    expect(contnt_rev_ele.length).toEqual(0);
  });

});
