import React from "react";
import { screen, render, act, fireEvent } from "@testing-library/react";
import BreadCrumb from "./BreadCrumb";
import { BreadCrumbProps } from "../../mocks/breadcrumb.mocks";

describe("BreadCrumb", () => {
  let view = null;
  beforeEach(() => {
    view = render(<BreadCrumb {...BreadCrumbProps} />);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render Header", () => {
    expect(screen.getAllByText("PRODUKTE"));
  });

  test("should render Description", () => {
    expect(
      screen.findByTitle("It is time to get to know the UK no.1 cream cheese")
    );
  });

  test("showing video title", () => {
    expect(screen.findByTitle("sample video"));
  });

  test("showing heading ", () => {
    let heading = screen.getByText("PRODUKTE");
    expect(heading).toBeInTheDocument;
  });
  test("should render with false value", () => {
    act(() => {
      window.innerWidth = 299
      fireEvent(window, new Event("resize"))
    })
    view = render(<BreadCrumb {...BreadCrumbProps} variant={null} navigationCollection={null}/>);
  });
});
