export const imageWithTextdata = {
    "heading": "It's time to get inspired!",
    "description": "__Für Milka hat der Lebensraum der Alpen mit all seiner Artenvielfalt und seinen Naturschätzen einen ganz besonderen Stellenwert, schließlich ist Alpenmilch ein wichtiger Bestandteil unser Milka Schokolade.__",
    "isReversed": null,
    "elementId": null,
    "showBackground": false,
    "variant": "LEFT_TEXT_CONTAINER_TAUERN",
    "arrowIcon": null,
    "images": {
      "internalTitle": "National Park ",
      "altText": null,
      "imageDesktop": {
        "title": "National Park Image",
        "description": "",
        "url": "https://images.ctfassets.net/oqg67vmf6aj9/2UBwEiAykZUiWSPLkkiOOl/fce7e1fdc9757d5c123893c936fd71fe/NationalPark.png"
      },
      "imageMobile": null,
      "bgDesktop": null,
      "bgMobile": null
    },
    "link": {
        "label":"MEHR ERFAHREN",
        "title":"MEHR ERFAHREN",
        "url":"/neues/zarteste-Milka"
    }
  }
