export const SignUpProps = {
  "image": {
    "title": "Landing page newsletter subscription Left Image",
    "description": "Landing page newsletter subscription Left Image",
    "url": "https://images.ctfassets.net/oqg67vmf6aj9/4EfflbfvZKpnT8sFOTctLW/4c2ea8b024680754be92fd8da9a1ba25/MicrosoftTeams-image__1_.png"
  },
  "signUp": {
    "name": "signUp",
    "label": "JETZT ABONNIEREN"
  },
  "onRedirect": () =>{},
  "variant": "emailSignUp",
  "termsText": "<a to=\"/Newsletter-Repository/newsletter-abbestellen\">Zum Abbestellen hier klicken</a>",
  "fields": {
    "email": {
      "icon": "",
      "name": "email",
      "error": "E-Mail-Adresse* is Required",
      "label": "E-Mail-Adresse*",
      "order": 4,
      "rules": [
        {
          "type": "REQUIRED",
          "errorMessage": "E-Mail-Adresse* is Required"
        },
        {
          "type": "EMAIL",
          "errorMessage": "enthält ungültige Emailadresse."
        }
      ],
      "value": "abc@gmail.com",
      "isValid": false,
      "fieldType": "input",
      "isRequired": true,
      "submittion": true,
      "elementType": "input",
      "elementConfig": {
        "type": "text",
        "placeholder": "E-Mail-Adresse*"
      }
    },
    "lastName": {
      "icon": "",
      "name": "lastName",
      "error": "Nachname* is Required",
      "label": "Nachname*",
      "order": 3,
      "rules": [
        {
          "type": "REQUIRED",
          "errorMessage": "Nachname* is Required"
        },
        {
          "type": "text",
          "errorMessage": ""
        }
      ],
      "value": "ram",
      "isValid": false,
      "fieldType": "input",
      "isRequired": true,
      "submittion": true,
      "elementType": "input",
      "elementConfig": {
        "type": "text",
        "placeholder": "Nachname*"
      }
    },
    "firstName": {
      "icon": "",
      "name": "firstName",
      "error": "Vorname* is Required",
      "label": "Vorname*",
      "order": 2,
      "rules": [
        {
          "type": "REQUIRED",
          "errorMessage": "Vorname* is Required"
        },
        {
          "type": "text",
          "errorMessage": ""
        }
      ],
      "value": "Sri",
      "isValid": false,
      "fieldType": "input",
      "isRequired": true,
      "submittion": true,
      "elementType": "input",
      "elementConfig": {
        "type": "text",
        "placeholder": "Vorname*"
      }
    },
    "salutation": {
      "icon": "",
      "name": "salutation",
      "error": "Anrede* is Required",
      "label": "Anrede*",
      "order": 1,
      "rules": [
        {
          "type": "REQUIRED",
          "errorMessage": "Anrede* is Required"
        },
        {
          "type": "text",
          "errorMessage": ""
        }
      ],
      "value": "1",
      "isValid": false,
      "options": [
        {
          "label": "Anrede*",
          "value": ""
        },
        {
          "label": "Herr",
          "value": "1"
        },
        {
          "label": "Frau",
          "value": "2"
        }
      ],
      "fieldType": "select",
      "isRequired": true,
      "submittion": true,
      "elementType": "select",
      "elementConfig": {
        "type": "text",
        "placeholder": "",
        "requiredText": "*Pflichtfelder"
      }
    },
    "confirmNewsletter": {
      "icon": "",
      "name": "confirmNewsletter",
      "error": "This field is Required",
      "label": "Ich möchte den regelmäßigen Newsletter mit Informationen zu Milka Produkten, Rezepten und Aktionen per E-Mail von der Mondelez Deutschland Services GmbH & Co. KG oder von verbundenen Unternehmen der Mondelez International Unternehmensgruppe erhalten. In diesem Zusammenhang wird auch mein Kauf- und Klickverhalten auf dieser Milka-Website analysiert. Diese Einwilligung kann jederzeit mit zukünftiger Wirkung <a to='/newsletter-abbestellen'>heir</a> widerrufen werden. Bitte beachten Sie auch unsere <a to='/eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteID=7GTws0jSEtgtqGQHH57lZw%3D%3D'>Datenschutzerklärung</a> für weitere Informationen.",
      "order": 5,
      "rules": [
        {
          "type": "CHECKBOX",
          "errorMessage": "This field is Required"
        }
      ],
      "value": "true",
      "isValid": false,
      "fieldType": "checkbox",
      "isRequired": true,
      "submittion": true,
      "elementType": "checkbox",
      "elementConfig": {
        "type": "text",
        "placeholder": ""
      }
    },
    "togglePassword":{
      "icon": "",
      "name": "password",
      "error": "E-Mail-Adresse* is Required",
      "label": "E-Mail-Adresse*",
      "order": 4,
      "button": {
        "id" : "togglePassword",
        "name": "name"
      },
      "rules": [
        {
          "type": "REQUIRED",
          "errorMessage": "E-Mail-Adresse* is Required"
        },
        {
          "type": "EMAIL",
          "errorMessage": "enthält ungültige Emailadresse."
        }
      ],
      "value": "abc@gmail.com",
      "isValid": false,
      "fieldType": "input",
      "isRequired": true,
      "submittion": true,
      "elementType": "input",
      "elementConfig": {
        "type": "text",
        "placeholder": "E-Mail-Adresse*"
      }
    },
  },
  
  "isReCaptch": false,
  "onSubmitAPI": true,
}