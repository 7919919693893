import React from "react";
import { screen, render } from "@testing-library/react";
import Sitemap from "./Sitemap";
import { SitemapData } from "../../mocks/Sitemap.mock";

describe('Sitemap', () => {
  let view = null
  beforeEach(() => {
    view = render(<Sitemap {...SitemapData} />)
  })

  test('should render component', () => {
    expect(screen.getAllByText('Link 1'))
  })

  test('should render links', () => {
    const { container } = view
    const li_ele = container.querySelectorAll('.block>li')
    expect(li_ele.length).toEqual(2)
  })

  test('should render sub links', () => {
    expect(screen.getAllByText('Link 2.1'))
  })

  test('should render with url passed', () => {
    expect(screen.getByText('Link 2.1').href).toContain('/link-2-1-href/')
  })

  test('should render heading div', () => {
    view = render(<Sitemap {...SitemapData} heading={'Some Heading'} />)
    const { container } = view
    const header_ele = container.querySelectorAll('.text-center')
    expect(header_ele.length).toEqual(1)
  })
})