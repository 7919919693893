export const ModalWrapperProps = {
    closeModal: jest.fn(),
    children: 'Hello',
    variant: 'textPopup',
    closeOnBodyclick: true,
    image: {
        "url": "https://images.ctfassets.net/oqg67vmf6aj9/1aoyEV1AtTuxNw1urTKAhs/beba6346dfcefe9f6cb1abf34f592a98/nav-almauftrieb.jpg",
        "title": "nav-almauftrieb",
        "description": "",
        "width": 120,
        "height": 120
    }
}