const query = () => `{
headerCollection(limit: 1) {
    items {
      logo {
        url
        title
        description
        width
        height
      }
      mobileLogo {
        url
        title
        description
        width
        height
      }
      navigationCollection(limit: 10) {
        items {
          label
          link {
            label
            title
            url
            target
            scrollToElementId
            image {
              title
              description
              url
              width
              height
            }
          }
        }
      }
      desktopIconCollection(limit: 10) {
        items {
          label
          link {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
            customClassName
            image {
              title
              description
              url
              width
              height
            }
          }
        }
      }
      mobileIconCollection(limit: 10) {
        items {
          label
          link {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
            customClassName
            image {
              title
              description
              url
              width
              height
            }
          }
        }
      }
    }
  }
}
`

module.exports = { query }
