export const defaultStyle = {
  container:
    "flex items-center imageTopSection boxShadow relative overflow-hidden lg:overflow-visible bg-[#efe9e9]",
  cowImage:
    "w-[55%] h-[60%] lg:w-full lg:h-[45%] child:w-[200%] sm:child:w-[120%] child:max-w-[540px] md:child:max-w-[200%] lg:child:max-w-[540px] child:right-[0] lg:child:right-[20px] child:absolute child:z-[0] md:child:w-[200%] lg:flex lg:items-center",
  imageWrap1:
    "lg:w-1/3 lg:relative flex items-center absolute w-[55%] h-[60%] top-[27%] sm:top-[75px] lg:top-auto lg:h-auto sm:h-[75%] cowSection",
  imageWrap2:
    "w-full px-[35px] py-[50px] lg:px-0 lg:py-0 lg:w-[calc(33.33%-30px)] mx-15 relative cowContentSection",
  imageWrap3:
    "w-full absolute lg:relative imageSection lg:flex-col lg:flex imageWrap3",
  innerWrap: "relative w-full homepageCtcImg",
  button: "Secondarybutton font-GoodHeadlinePro event_button_click w-max ",
  title:
    "text-[30px] leading-[34.2px] lg:text-[60px] lg:leading-[75px] text-[#3b2774] sm:text-40 sm:leading-[50px] font-RecoletaMedium",
  description:
    "text-[17px] leading-[22px] text-[#3b2774] mt-[10px] lg:text-[25px] lg:leading-[32.5px] font-RecoletaRegular",
}
