import React from "react";
import { render, screen, fireEvent } from "@testing-library/react";
import TextContent from "./TextContent";
import { textContentData } from "../../mocks/TextContent.mock";

describe("TextContent", () => {
  let view = null;
  beforeEach(() => {
    view = render(<TextContent {...textContentData} />);
  });
  test("should render Intial Data", () => {
    render(<TextContent {...textContentData} />);
  });

  test("should render component", () => {
    expect(screen.getAllByText("Wie hat das Milka Skisponsoring begonnen?"))
      .toBeInTheDocument;
  });

  test("should render description", () => {
    expect(
      screen.getByText(
        "Wäre die FIS Alpine Ski WM in Sierra Nevada 1995 nicht abgesagt worden"
      )
    ).toBeInTheDocument;
  });

  test("should render Link", () => {
    expect(screen.getAllByText("MEHR ERFAHREN")).toBeInTheDocument;
  });
});
