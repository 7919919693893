import React from "react";
import { fireEvent, screen, render, act } from "@testing-library/react";
import Typography from "./Typography";
import { TypographyProps } from "../../../mocks/Typography.mock";

describe("Typography", () => {
  let view = null;
  beforeEach(() => {
    view = render(<Typography {...TypographyProps} onChange={()=>{}}/>);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render Intial Data", () => {
    render(<Typography {...TypographyProps} content={"<div><h1><a to='#'/>Hi</h1></div>"} onChange={()=>{}}/>);
    expect(screen.getAllByText('Hi'))
  });

  test("should render childdren", () => {
    render(<Typography {...TypographyProps} allow_br_tag={true}  content={"<div><h1><br/></h1></div>"} onChange={()=>{}}/>);
  });

  test("should render with anchor and img", () => {
    view = render(<Typography {...TypographyProps} content={"<a data-testid='anch' to='/new-image'><img src={'imageSRC'} /></a>"} onChange={()=>{}}/>);
    expect(screen.getAllByTestId('anch'))
  });
  test("should render text data", () => {
    render(<Typography {...TypographyProps} br_allowed={true} content={"hello"} onChange={()=>{}}/>);
    expect(screen.getAllByText('hello'))
  });

});