import React from "react";
import PropTypes from "prop-types"

const CustomButton = props => {
  const { className, style, onClick, image ,gaEventLabel,gaEventClass} = props;
  return (
    <div className={""}>
      <div
        className={`${className || ""} ${gaEventClass}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
        data-action-detail={gaEventLabel}
      />
      {image}
    </div>
  );
};

CustomButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  image: PropTypes.object,
  gaEventLabel: PropTypes.string,
  gaEventClass: PropTypes.string
};

export default CustomButton;
