export const videoProps = {
    "internalTitle": "Video",
    "title": "Milka ist jetzt noch schokoladiger!",
    "isModal": null,
    "isIframe": true,
    "isImageToggle": true,
    "youtubeVideoId": "XK1Vd7vYAgc",
    "siteCoreVideo": null,
    "isAutoPlay": null,
    "posterImage": {
        "title": "video-vorschau-schokoladiger",
        "description": "",
        "contentType": "image/jpeg",
        "fileName": "video-vorschau-schokoladiger (1).jpg",
        "size": 38099,
        "url": "https://images.ctfassets.net/oqg67vmf6aj9/6mMqX7b6YeOjlienjXDwEZ/6d95dfa000963b9d275a0b52942b1ded/video-vorschau-schokoladiger__1_.jpg",
        "width": 960,
        "height": 540,
        "altText": "video-vorschau-schokoladiger",
    },
    "style": {
        "aVContainer": "aVContainer flex flex-col relative",
        "videoframe": "videoFrame shrink-1 grow-0 !basis-[100%] w-full min-h-[200px] lg:min-h-[350px] overflow-hidden",
        "videoItem": "w-screen min-h-[200px] md:min-h-[430px] h-full lg:min-h-[650px]",
        "videoCntr": "fullWidthVideo relative w-full h-[200px] md:min-h-[430px] lg:min-h-[650px]",
        "imageCardColumn": "imageCardColumn shrink-1 grow-0 basis-[100%] ",
        "playBtnImg": " cursor-default playBtn w-[100vw] md:min-h-[430px] lg:max-h-[710px]",
        "playButton": "playButton absolute flex h-[55px] w-[40px] items-center justify-center translate-y-[-50%] translate-x-[-50%] left-[50%] top-[50%] z-20 ",
        "showImg": "block",
        "hideImg": "hidden",
        "mainVideo": "mainVideo"
    },
    "variant": "FULL_SCREEN_VIDEO"
}