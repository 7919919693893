export const FooterData = {
  "note": null,
  "copyRight": "Tüm hakları © Mondel z International tarafından saklıdır",
  "brandLinksCollection": {
    "items": [
      {
        "label": null,
        "title": "Mondelez International",
        "url": null,
        "target": "_self",
        "scrollToElementId": "top",
        "gaEventClass": "event_external_link",
        "gaEventLabel": "Mondelez International",
        "image": null
      }
    ]
  },
  "footerLogo": {
    "url": "https://images.ctfassets.net/g1tlso40dg86/4ZhW7VEVJDBzuFmRLVBw6n/515abdd1710e3d2225424d2c68444761/milka-logo-purple.png",
    "title": "Milka Logo",
    "description": "Milka Logo",
    "width": 252,
    "height": 122
  },
  "images": {
    "internalTitle": "Footer Image",
    "altText": "Footer Image",
    "bgDesktop": null,
    "bgMobile": null,
    "imageDesktop": {
      "title": "Footer Banner Image",
      "description": "Footer Banner Image",
      "url": "https://images.ctfassets.net/g1tlso40dg86/2b24exA5H0inJkbyZVAvEs/ba8b079f97d7f71812d3daab7c13f772/footer-bg.png",
      "width": 2895,
      "height": 810
    },
    "imageMobile": {
      "title": "Footer banner mobile image",
      "description": "Footer banner mobile image",
      "url": "https://images.ctfassets.net/g1tlso40dg86/4WAicCgAjNVBnbdymmLmED/f897b56a84191e11a38b44a45efd1c1c/lila-curve-footer-MOB.png",
      "width": 450,
      "height": 916
    }
  },
  "upliftButton": {
    "label": "Uplift Button",
    "title": "Uplift Button",
    "url": null,
    "target": "_self",
    "scrollToElementId": "top",
    "gaEventClass": null,
    "gaEventLabel": null,
    "image": {
      "title": "Uplift Button",
      "description": "",
      "url": "https://images.ctfassets.net/g1tlso40dg86/i9ZqNfiw6zQIVh6hdnLBD/45df36c42471d5074df06fdfc8a45d83/Uplift_Button.png",
      "width": 37,
      "height": 37
    }
  },
  "socialLinksCollection": {
    "items": [
      {
        "label": "Instagram",
        "title": "Instagram",
        "url": "https://www.instagram.com/milka_tr/",
        "target": "_blank",
        "imageType": "regular",
        "scrollToElementId": null,
        "gaEventClass": "event_socialmedia_exit",
        "gaEventLabel": "Instagram",
        "customClassName": "instagram",
        "image": {
          "title": "Instagram",
          "description": "",
          "url": "https://images.ctfassets.net/g1tlso40dg86/4pYlzlWxi7HUfeCB9kt9U2/2cd6aa48bce83d2224efe1ec90d764a9/instagram.png",
          "width": 1000,
          "height": 1000
        }
      },
      {
        "label": "Facebook",
        "title": "Facebook",
        "url": "https://www.facebook.com/MilkaTurkiye/",
        "target": "_blank",
        "imageType": "regular",
        "scrollToElementId": null,
        "gaEventClass": "event_socialmedia_exit",
        "gaEventLabel": "Facebook",
        "customClassName": "facebook",
        "image": {
          "title": "Facebook",
          "description": "",
          "url": "https://images.ctfassets.net/g1tlso40dg86/VWdfoT5sFyS5n9Kri0bAJ/c30323bd05c0d07ca7eac18b67f2dbb9/facebook.png",
          "height": 1000
        }
      }
    ]
  },
  "footerLinksCollection": {
    "items": [
      {
        "label": "MILKA MARKASI",
        "labelLink": {
          "label": "MILKA MARKASI",
          "title": "MILKA MARKASI",
          "url": "/milka-markasi",
          "target": "_self",
          "scrollToElementId": null,
          "gaEventClass": "event_footer_click",
          "gaEventLabel": "MILKA MARKASI"
        },
        "linksCollection": {
          "items": [
            {
              "label": "ÇİKOLATA ÜRETİMİ",
              "title": "ÇİKOLATA ÜRETİMİ",
              "url": "/milka-markasi/cikolata-uretimi/",
              "target": "_self",
              "scrollToElementId": null,
              "gaEventClass": "event_footer_click",
              "gaEventLabel": "ÇİKOLATA ÜRETİMİ",
              "image": null
            },
            {
              "label": "MILKA ZAMAN TÜNELİ",
              "title": "MILKA ZAMAN TÜNELİ",
              "url": "/milka-markasi/milka-zaman-tuneli/",
              "target": "_self",
              "scrollToElementId": null,
              "gaEventClass": "event_footer_click",
              "gaEventLabel": "MILKA ZAMAN TÜNELİ",
              "image": null
            },
            {
              "label": "MILKA REKLAMLARI",
              "title": "MILKA REKLAMLARI",
              "url": "/milka-markasi/engagement/",
              "target": "_self",
              "scrollToElementId": null,
              "gaEventClass": "event_footer_click",
              "gaEventLabel": "MILKA REKLAMLARI",
              "image": null
            }
          ]
        }
      }
    ]
  },
  "mainLinksCollection": {
    "items": [
      {
        "label": "sitemap",
        "linksCollection": {
          "items": [
            {
              "label": "GİZLİLİK POLİTİKASI",
              "title": "GİZLİLİK POLİTİKASI",
              "url": "/Service/gizlilik-politikasi/",
              "target": "_self",
              "scrollToElementId": null,
              "gaEventClass": "event_footer_click",
              "gaEventLabel": "GİZLİLİK POLİTİKASI",
              "image": null
            },
            {
              "label": "KULLANIM KOŞULLARI",
              "title": "KULLANIM KOŞULLARI",
              "url": "/Service/kullanim-kosullari/",
              "target": "_self",
              "scrollToElementId": null,
              "gaEventClass": "event_footer_click",
              "gaEventLabel": "KULLANIM KOŞULLARI",
              "image": null
            },
            {
              "label": "BİZE ULAŞIN",
              "title": "BİZE ULAŞIN",
              "url": "/Service/bize-ulasin/",
              "target": "_self",
              "scrollToElementId": null,
              "gaEventClass": "event_footer_click",
              "gaEventLabel": "BİZE ULAŞIN",
              "image": null
            },
            {
              "label": "ARA",
              "title": "ARA",
              "url": "/Service",
              "target": "_blank",
              "scrollToElementId": null,
              "gaEventClass": "event_footer_click",
              "gaEventLabel": "ARA",
              "image": null
            }
          ]
        }
      }
    ]
  }
}