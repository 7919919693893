const query = (id, isPreview) => `{
    
    productListing(id:"${id}", preview:${isPreview}) {
        internalTitle
        heading
        productFilter {
          title
          filterItemCollection {
            items {
              title
              apiText
              uniqueKey
              canOpen
              itemCollection {
                items {
                  title
                  keyName
                  apiText
                  categoryKey
                }
              }
            }
          }
        } 
     }
    }
  
`;
module.exports = { query };
