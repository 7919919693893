import React, { useState } from "react";
import PropTypes from "prop-types";
import { deslashify, get } from "../../helpers/utils";
import Typography from "../UI/Typography/Typography";
import Link from "../UI/Link/Link";
import Image from "../UI/Image/Image";
import * as styles from "./ImageLayout-tw-styles";
import { gaImageClick } from "../../constants/gtm.constants";
import useScreenSize from "../../hooks/useScreenSize";

const ImageLayout = props => {
  const { heading, description, link, imagesCollection, variant, gaEventLabel} = props;
  const style = styles[variant] || styles.defaultStyle;
  const images = get(imagesCollection, "items", []);
  const [clickedIndex, setClickedIndex] = useState(-1);
  const screen = useScreenSize();

  let activeCard;
  let currentPath = "";
  if (typeof window !== "undefined") {
    currentPath = window?.location?.pathname;
  }
  if (variant === "SIX_IMAGE_LAYOUT_SECTION") {
    activeCard = urlMatchAtIndex(imagesCollection, deslashify(currentPath));
  }

  function urlMatchAtIndex(data, urlToMatch) {
    return data?.items?.findIndex(
      ({ link }) => deslashify(link?.url) === urlToMatch
    );
  }
  const onMouseEnter = (e, index) => {
    if (clickedIndex === index) {
      return setClickedIndex(-1);
    }
    setClickedIndex(index);
  };

  const onMouseLeave = (e, index) => {
    if (clickedIndex === index) {
      return setClickedIndex(-1);
    }
    setClickedIndex(index);
  };

  return (
    <div className={style?.layoutContainer}>
      <div className={style?.textContainer}>
        {heading && <Typography content={heading} className={style?.heading} />}
        {description && (
          <Typography
            content={description}
            className={style?.description}
            br_allowed={true}
            allow_br_tag={true}
          />
        )}
        {link && (
          <Link {...link} className={style?.linkWrpr}>
            {link?.label}
          </Link>
        )}
      </div>
      <ul className={style?.list}>
        {images?.length &&
          images.map((item, index) => {
            const { image, link, gaEventClass, title, gaEventLabel } = item;
            return (
              <li
                className={`${style?.listItem}  ${
                  index === activeCard
                    ? "border-l-[2px] border-b-[2px] md:border-[3px] border-purple"
                    : ""
                } `}
                key={index}
                onMouseEnter={() => onMouseEnter(item, index)}
                onMouseLeave={() => onMouseLeave(item, index)}
              >
                <Link
                  className={style?.anchor}
                  {...link}
                  gaEventClass={gaImageClick}
                  gaEventLabel={gaEventLabel}
                  image={image}
                >
                  <Image className={style?.imgMain} contentfulImage={image} />
                </Link>
                <div className={style?.imageWrapperTitle}>
                  {screen?.isMobile ? (
                    <span>
                      <h3 className={style?.imageWrapperHeading}>
                        {link?.title}
                      </h3>
                    </span>
                  ) : (
                    <span>
                      {(index === clickedIndex || index === activeCard) && (
                        <h3 className={style?.imageWrapperHeading}>
                          {link?.title}
                        </h3>
                      )}
                    </span>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

ImageLayout.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.object,
  imagesCollection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  variant: PropTypes.string,
  gaEventLabel: PropTypes.string
};

export default ImageLayout;
