import React from "react";
import { fireEvent, render, screen } from "@testing-library/react";
import ModalView from "./ModalView";
import { modelViewProps } from "../../../mocks/ModelView.mock";

describe("ModalView", () => {
  let view = null;
  beforeEach(() => {
    view = render(<ModalView {...modelViewProps} onChange={()=>{}}/>);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render Intial Props", () => {
    const { container } = render(<ModalView {...modelViewProps} onChange={()=>{}} isIframe={false} isAutoPlay={true} />);
  });

  test("Button Click with Intial Props", () => {
    const { container } = render(<ModalView {...modelViewProps} onChange={()=>{}} isIframe={false} isAutoPlay={true} />);
    const div_ele = container.querySelectorAll(`button`)
    fireEvent.click(div_ele[0], {event: {preventDefault: ()=>{} } })
  });
});