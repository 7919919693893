export const defaultStyle = {
  container: "container",
  imageContainer: "",
  textContainer: "",
  cardsWrapr: "SINGLE_CARD cardsWrapr ",
  imgWrpr: "imgWrpr",
  heading: "",
  description: "",
  linkWrpr: "",
  contentWrpr: "sdfsfd",
  img: "",
  textTitle: "",
  textSubTitle: "",
  bgImgWrper: "hidden",
}

export const MULTI_SECTION_CAROUSEL_SLIDE_ONE = {
  CarouselWrpr: "transition delay-150 carouselWrpr bg-carouselBg min-h-[500px] bg-cover",
  controlBtn: "MULTI_SECTION_CAROUSEL_SLIDE_ONE relative lg:w-[1170px] mx-auto px-15 lg:px-0",
  carouselInnerWrpr: "carouselInnerWrpr",
  container: "SLIDE_ONE container md:relative static md:top-[-125px] lg:top-[-41px] flex flex-wrap lg:flex-row-reverse flex-col-reverse lg:mx-auto md:pb-0 pb-[10rem] md:max-w-full lg:max-w-[1170px] md:min-h-[661px] lg:min-h-auto lg:pt-[4%]",
  imageContainer: "imageContainer flex lg:w-1/2 w-full",
  textContainer: "SLIDE_ONE textContainer lg:mt-50 md:mt-[-24px] lg:relative md:left-[-30px] w-full lg:w-auto lg:ml-auto",
  textTitle: "title md:text-[45px] lg:text-[64px] text-[39px] lg:justify-start justify-center flex lg:mt-70 mt-0 md:leading-[1em] leading-[39px] welText",
  textSubTitle: "subTitle SLIDE_THREE fourslideSubTtl text-lightGrey leading-[1.5em] md:p-[0%] lg:text-[20px] text-[18px] md:w-[76%] lg:w-[469px]  w-[88%] lg:text-left mx-auto text-center justify-center lg:flex lg:flex-wrap mt-10 lg:mt-6 tracking-[0]",
  bgImgWrper: "hidden",
}
export const MULTI_SECTION_CAROUSEL_SLIDE_TWO = {
  container: "transition delay-150 MULTI_SECTION_CAROUSEL_SLIDE_TWO container mx-auto flex flex-wrap  lg:max-w-[1024px] md:max-w-[80%]",
  imageContainer: "imageContainer SLIDE_TWO lg:w-1/2 w-full",
  textContainer: "textContainer SLIDE_TWO lg:w-1/2 w-full lg:mt-120 lg:pl-50",
  textSectionWrpr: "textSectionWrpr SLIDE_TWO lg:w-1/2 mt-50 lg:pl-45 lg:pt-65",
  heading: "heading SLIDE_TWO",
  description: "descriptionSLIDE_TWO",
  textTitle: " textTitleSLIDE_TWO text-[42px] text-darkBlue leading-[46px] tracking-[0] md:w-3/5 md:mx-auto lg:m-0 w-full text-center lg:text-left",
  textSubTitle: "subTitle SLIDE_THREE fourslideSubTtl text-lightGrey md:w-[80%] tracking-[0] lg:w-[62%] w-full px-15 md:px-0 lg:mx-0 mx-auto text-center lg:text-left text-[20px] mt-20 lg:ml-0",
  bgImgWrper: "hidden",
}

export const MULTI_SECTION_CAROUSEL_SLIDE_THREE = {
  container: " transition delay-150 MULTI_SECTION_CAROUSEL_SLIDE_THREE flex flex-wrap before:bg-no-repeat lg:w-full  lg:before:h-500 before:h-350 before:content-[''] before:w-[100%]  before:absolute",
  imageContainer: "imageContainer lg:w-1/2 w-full",
  textContainer: "textContainer lg:w-1/2 w-full lg:mt-[160px] md:mt-[340px] mt-300  SLIDE_THREE",
  cardsWrapr: "SINGLE_CARD cardsWrapr IMAGECAROUSELfIRST ",
  imgWrpr: "imgWrpr",
  bgImgWrper: "bg-homeSliderBG w-1/2 absolute h-full bg-no-repeat homeCarousel",
  roundLinkWrpr: "roundLinkWrpr relative w-full flex justify-center",
  heading: "",
  description: "",
  linkWrpr: "",
  contentWrpr: "",
  img: "",
  textTitle: "textTitle text-[30px] text-darkBlue boldSpan md:w-[37%] lg:w-[28%] w-[70%] mx-auto lg:ml-[60px] leading-[1.15em] lg:text-left text-center mt-100 md:mt-50 lg:mt-auto md:relative lg:static lg:left-[28%] tracking-[0]",
  textSubTitle: " textSubTitle text-lightGrey text-[19px] lg:w-[45%] md:w-[62%] md:mx-auto md:flex w-full roundedLabel lg:ml-[60px] lg:text-left text-center flex relative tracking-[0]",
}

export const MULTI_SECTION_CAROUSEL_SLIDE_FOUR = {
  container: "TWO_BY_TWO_CARDS flex flex-wrap lg:w-[1170px] mx-auto lg:px-15 transition delay-150 ",
  imageContainer: "imageContainer SLIDE_FOUR md:w-[400px] md:mx-auto lg:w-1/2 justify-center items-center lg:flex lg:min-h-[500px] mt-20 md:mt-0",
  textContainer: "subTitle SLIDE_THREE fourslideSubTtl text-lightGrey text-[20px] lg:w-[47%] flex justify-center flex-col",
  cardsWrapr: "flex hmeSlidFour flex-wrap lg:w-3/5 md:-w-[76%] mx-50 md:mx-0  md:p-40 md:pt-[20px] lg:p-0",
  imgWrpr: "imgWrpr",
  liWrpr: "liWrpr w-1/2 leading-[30px]",
  heading: "heading",
  description: "description",
  linkWrpr: "linkWrpr",
  contentWrpr: "contentWrpr",
  img: "img",
  rightCarouselContent: "rightCarouselContent hidden",
  textTitle: "textTitle lg:text-[50px] text-[40px] text-center lg:text-left text-darkBlue ",
  textSubTitle: "text-center lg:text-left text-[20px] px-[15px] lg:px-[0] md:w-full lg:w-[87%] flex flex-wrap mx-auto lg:mx-0 tracking-[0]",
  bgImgWrper: "hidden",
}