import React from "react";
import { screen, render, act } from "@testing-library/react";

import ImageCardsLayout from "./ImageCardsLayout";
import { ImageCardsLayoutProps, carditems } from "../../mocks/imagecards.mocks";

describe("ImageCardsLayout", () => {
  test("It should not break if not passing props", () => {
    render(<ImageCardsLayout />);
  });

  let view = null;
  beforeEach(() => {
    view = render(<ImageCardsLayout {...ImageCardsLayoutProps} />);
  });
  afterEach(() => {
    jest.clearAllMocks();
  });

  test("should render with Initial Data", () => {
    view;
  });

  test("should render Description", () => {
    expect(
      screen.findByTitle("It is time to get to know the UK no.1 cream cheese")
    );
  });

  test("showing card title", () => {
    expect(screen.findByTitle("sample card"));
  });

  test("should render main Link", () => {
    expect(screen.getAllByText("testlink")).toBeInTheDocument;
  });

  test("showing componentName ", () => {
    expect("ImageCardsLayout").toBeInTheDocument;
  });

  test("should render Image and src to be true", () => {
    const imageElement = document.querySelector("img");
    expect(imageElement.src).toContain(
      "https://images.ctfassets.net/oqg67vmf6aj9/4tK46JG6Q9ym8zv2tdZU1l/c15b6abef76cfcbb229a7988683b0030/almauftrieb3.jpg"
    );
  });

  test("showing componentName ", () => {
    ImageCardsLayoutProps.title = "title";
    ImageCardsLayoutProps.text = "text";
    render(<ImageCardsLayout {...ImageCardsLayoutProps} />);
    expect(screen.getAllByText("title"));
    expect(screen.getAllByText("text"));
  });

  test("should not render when no slider", () => {
    ImageCardsLayoutProps.isSlider = false;
    render(<ImageCardsLayout {...ImageCardsLayoutProps} />);
    expect(screen.findByTitle("sample card"));
  });

  test("should not render when no slider", () => {
    ImageCardsLayoutProps.variant = "FIVE_COLUMN_CARDS_LAYOUT";
    render(<ImageCardsLayout {...ImageCardsLayoutProps} />);
    expect(screen.findByTitle("sample card"));
  });

  test('should render card items', () => {
    global.innerWidth = 640
    global.location = new URL('http://localhost/slide/1')
    jest.spyOn(window.screen, "width", "get").mockReturnValue(640);
    view = render(<ImageCardsLayout {...ImageCardsLayoutProps} 
      carditems={ImageCardsLayout?.cardsCollection?.items} />)
    act(() => {
      global.dispatchEvent(new Event('resize'));
    })
    const { container } = view
    const card_ele = container.querySelectorAll('.imageBoxWrpr')
    expect(card_ele.length).toEqual(3)
  })
  
});
