const query = (id, isPreview )=> `
{
    ctaBlock(id: "${id}", preview: ${isPreview}) {
        heading
        image {
          title
          description
          url
          width
          height
        }
        socialLinksCollection {
          __typename
          ... on CtaBlockSocialLinksCollection {
            items {
              sys{id}
              label
              title
              url
              target
              imageType
              customClassName
              image {
                title
                description
                url
                width
                height
              }
              gaEventClass
              gaEventLabel
            }
          }
        }
        variant
    
  }
}
`
module.exports = { query }
