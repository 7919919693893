import React from "react"
import PropTypes from "prop-types"

const Radio = props => {
  const { value, name, label, onChange, checked, prefixLabel = true } = props
  return (
    <div>
      {prefixLabel && label && <label>{label}</label>}
      <input
        value={value}
        type={"radio"}
        name={name}
        onChange={onChange}
        checked={checked}
      />
      {!prefixLabel && label && <label>{label}</label>}
    </div>
  )
}

Radio.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  prefixLabel: PropTypes.bool,
}

export default Radio
