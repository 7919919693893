import React from "react";
import { Provider } from 'react-redux'
import createStore from "../../redux/store";
import { screen, render, fireEvent, act, waitFor } from "@testing-library/react";
import ProductSearch from "./ProductSearch";
import { ProductSearchData, algoliaResponse } from "../../mocks/ProductSearch.mock";

const store = createStore()
const mockSearchClBk = jest.fn();
ProductSearchData.onSearchTermChange = mockSearchClBk
const mockResponse = () => {
  return {
    ...algoliaResponse
  }
}

jest.mock('algoliasearch', () => () => {
  return {
    initIndex: () => {
      return {
        search: () => Promise.resolve(mockResponse())
      }
    }
  }
})

describe('ProductSearch', () => {
  let view = null
  beforeEach(async () => {
    await act(async () =>  view = render(<Provider store={store}><ProductSearch {...ProductSearchData} /></Provider>));
  })

  test('should render search box with given filter prop', () => {
    expect(screen.getByDisplayValue('milka'))
  })

  test('should able to change search term', () => {
    const { container } = view
    const input_ele = container.querySelectorAll('.search_input')[0]
    fireEvent.change(input_ele, { target: { value: 'milka new'}})
    expect(screen.getByDisplayValue('milka new'))
  })

  test('should change search param in url when search button is clicked', async () => {
    const { container } = view
    const input_ele = container.querySelectorAll('.search_input')[0]
    const submit_ele = container.querySelectorAll('.searchBtn')[0]
    fireEvent.change(input_ele, { target: { value: 'milka new'}})
    fireEvent.click(submit_ele)
    expect(window.location.href).toContain('search=milka new')
  })

  test('should show Load More button', () => {
    const { container } = view
    const loadmore_ele = container.querySelectorAll('.loadmore-btn')
    expect(loadmore_ele.length).toEqual(1)
  })


  test('should render Product items first 4', () => {
    const { container } = view
    const items_ele = container.querySelectorAll('li.ProductListingBlock')
    expect(items_ele.length).toEqual(4)
  })

  test('should render next 4 product items when load more is clicked', async () => {
    const { container } = view
    const loadmore_ele = container.querySelectorAll('.loadmore-btn')[0]
    fireEvent.click(loadmore_ele)
    await waitFor(() => {
      const items_ele = container.querySelectorAll('li.ProductListingBlock')
      expect(items_ele.length).toEqual(8)
    })
  })

  test('should render without props', async () => {
    await act(async () =>  view = render(<Provider store={store}><ProductSearch /></Provider>));
    const { container } = view
    const input_ele = container.querySelectorAll('.search_input')
    expect(input_ele.length).toEqual(1)
  })

})