import React from "react";
import { screen, render, fireEvent } from "@testing-library/react";
import ProductFilter from "./ProductFilter";
import { ProductFilterData } from "../../mocks/ProductFilter.mock";

const mockFunction = jest.fn();
ProductFilterData.onFilterChange = mockFunction

describe("ProductFilter", () => {
  
  let view = null
  beforeEach(() => {
    view = render(<ProductFilter {...ProductFilterData} />)
  })
  afterEach(() => {
    jest.clearAllMocks();
  })

  test("should render component", () => {
    expect(screen.getByText("Filter"));
  });

  test("should render component without props", () => {
    view = render(<ProductFilter />)
    const { container } = view;
    const div_ele = container.querySelectorAll(`div[class*="ProductFilterAccordian"]`)
    expect(div_ele.length).toEqual(1)
  });

  test("should render default selected Active Filters", () => {
    const { getByText } = view
    expect(getByText('Aktive Filters')).toBeInTheDocument
  });

  test("should render filter items list", () => {
    const { container } = view;
    const div_ele = container.querySelectorAll(`div[class*="cursor-pointer"]`)
    expect(div_ele.length).toEqual(9)
  })

  test("should add filter items in Active Filters when filter item is clicked", () => {
    const { container } = view;
    const div_ele = container.querySelectorAll(`div[class*="cursor-pointer"]`)
    fireEvent.click(div_ele[0])
    fireEvent.click(div_ele[1])
    const selected_items_ele = container.querySelectorAll(`li[class*="activelist"]`)
    expect(selected_items_ele[1].textContent.includes('Riegel')).toBe(true)
  })

  test("should call onFilterChange Props when Filter Item Clicked", () => {
    const { container } = view;
    const div_ele = container.querySelectorAll(`div[class*="cursor-pointer"]`)
    fireEvent.click(div_ele[0])
    expect(ProductFilterData.onFilterChange).toHaveBeenCalledTimes(1)
  })

  test("should open when Filter category is clicked", () => {
    const { container } = view;
    const div_ele = container.querySelectorAll(`button[class*="accordianBtn"]`)
    fireEvent.click(div_ele[0])
    const category_ele = container.querySelectorAll(`li[class*="ToggleOpened"]`)
    expect(category_ele.length).toEqual(1)
  })

  test("should close when opened Filter category is clicked", () => {
    const { container } = view;
    const div_ele = container.querySelectorAll(`button[class*="accordianBtn"]`)
    fireEvent.click(div_ele[0])
    fireEvent.click(div_ele[0])
    const category_ele = container.querySelectorAll(`li[class*="ToggleOpened"]`)
    expect(category_ele.length).toEqual(0)
  })

  test("should able to remove the selected Filter Item from Active Filters", () => {
    const { container } = view;
    const div_ele = container.querySelectorAll(`div[class*="cursor-pointer"]`)
    fireEvent.click(div_ele[0])
    const selected_items_ele = container.querySelectorAll(`li[class*="activelist"]`)
    fireEvent.click(selected_items_ele[1])
    const updated_selected_items_ele = container.querySelectorAll(`li[class*="activelist"]`)
    expect(updated_selected_items_ele.length).toEqual(1)
  })

  test("should able to remove the selected Filter Item by clicking again the same item", () => {
    const { container } = view;
    const div_ele = container.querySelectorAll(`div[class*="cursor-pointer"]`)
    fireEvent.click(div_ele[0])
    fireEvent.click(div_ele[0])
    const selected_items_ele = container.querySelectorAll(`li[class*="activelist"]`)
    expect(selected_items_ele.length).toEqual(1)
  })

});
