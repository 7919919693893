export const defaultStyle = {
  timelineWrpr: 'flex flex-col relative z-[2]',
  timelineItemWrpr: 'flex px-[2%] md:pl-[5%] justify-center min-h-[50vh] flex-wrap w-full',
  timelineItemInnerWrpr: 'flex w-full flex-col md:flex-row px-15 md:px-0 justify-between',
  timelineItemLeft: 'flex flex-col w-[100%] md:w-[50%] item-center',
  timelineItemRight: 'flex w-full md:w-[50%] justify-center items-center py-20 pt-0 sm:pt-20 ',
  timelineItemTitle: 'flex text-[30px] lg:text-[48px] lg:leading-[50.4px] border-double md:w-[80%] border-[#684fa3] border-b-[4px] font-RecoletaMedium justify-center md:justify-start pt-[10%]',
  timelineItemDesc: 'flex justify-center md:justify-start text-center md:text-left mt-[2px] pt-[12px] text-xl leading-5 sm:mb-[30px] md:mb-[0] pb-[10%] mb-0',
  timelineItemImgWrpr: 'flex timelineItemImgWrpr',
  timelineItemImg: 'flex',

  // slider buttons
  sliderBtnWrpr: "sliderBtnWrpr hidden md:flex flex-col w-[20px] fixed ml-[2%] py-[10%] lg:py-[6%] xxl:py-[5%] top-0 h-full items-center justify-evenly before:content[''] before:border before:border-[#3b2774] before:absolute before:h-[85%] before:bg-[#3b2774] z-[30] before:left-[50%] before:top-[7%]",
  sliderBtn: 'sliderBtn w-[15px] h-[15px] rounded-[50%] md:mb-[20px] lg:mb-[3px] z-[60]',
  sliderBtnNormal: 'bg-[#afa6d2]',
  sliderBtn_TEXT_BLUE: 'bg-[#3b2774] !w-[23px] !h-[23px]',
  sliderBtn_TEXT_WHITE: 'bg-white !w-[23px] !h-[23px]',
  // Text color
  TEXT_BLUE: 'text-[#684fa3]',
  TEXT_WHITE: 'text-white'
}

