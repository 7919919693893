import React from "react";
import { screen, render } from "@testing-library/react";
import HomePageCTC from "./HomePageCtc";
import { HomePageCTCPros } from "../../mocks/HomePageCtc.mock";

describe("HomePageCTC", () => {
  let view = null;
  beforeEach(() => {
    view = render(<HomePageCTC {...HomePageCTCPros} />);
  });

  test("should render Intial Data", () => {
    view;
  });

  test("should render Header", () => {
    expect(screen.getAllByText(HomePageCTCPros.title));
  });
});
