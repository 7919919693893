export const HomePageCTCPros ={
    "componentName": "HomePageCtc",
    "title": "Über 120 Jahre Milka Geschichte",
    "description": "... und noch immer kommt nur 100% Alpenmilch in unsere zarte Milka Alpenmilch Schokolade.",
    "buttonText": "MEHR ERFAHREN",
    "backgroundImage": {
        "title": "Home CTC Background Image",
        "description": "",
        "contentType": "image/png",
        "fileName": "milka-pattern.png",
        "size": 210549,
        "url": "https://images.ctfassets.net/oqg67vmf6aj9/2pPJfcezdEFrfyq7YS5TxV/f345fac2452bddd7b89fd8b6944f15c4/milka-pattern.png",
        "width": 1467,
        "height": 745
    },
    "image": {
        "internalTitle": "Cow Image",
        "altText": null,
        "imageDesktop": {
            "title": "Cow",
            "description": "",
            "url": "https://images.ctfassets.net/oqg67vmf6aj9/7rQPyJ41pnMclaKYZiFXfd/cb46363cd62fe9498dbfd82ac9d81df2/Cow.png"
        },
        "imageMobile": {
            "title": "Cow",
            "description": "",
            "url": "https://images.ctfassets.net/oqg67vmf6aj9/2nhSVJJ73u05zt8ozBJUg7/1b72b7165bb1b48771529ed56017000b/Cow__1_.png"
        },
        "bgDesktop": null,
        "bgMobile": null
    },
    "imagesCollection": {
        "items": [
            {
                "title": "Image 1",
                "description": "",
                "contentType": "image/png",
                "fileName": "Image 1.png",
                "size": 75923,
                "url": "https://images.ctfassets.net/oqg67vmf6aj9/6RVgcgNnLbkAXGhxy5h26R/7852e85bc1f118a1d6d438a588a42ecf/Image_1.png",
                "width": 418,
                "height": 418
            },
            {
                "title": "Image2",
                "description": "",
                "contentType": "image/png",
                "fileName": "Image2.png",
                "size": 106732,
                "url": "https://images.ctfassets.net/oqg67vmf6aj9/YtRWt5Vd8K6cfRh3H05QN/21e226b690155c057619cb608d169e9b/Image2.png",
                "width": 417,
                "height": 417
            },
            {
                "title": "Image4",
                "description": "",
                "contentType": "image/png",
                "fileName": "Image3.png",
                "size": 51216,
                "url": "https://images.ctfassets.net/oqg67vmf6aj9/1GuvkdEyNb5eMwvO9wE8ei/79fab5083b98fa4f23eac23321088e50/Image3.png",
                "width": 415,
                "height": 415
            }
        ]
    },
    "pageType": "Home Page",
    "style": {
        "container": "flex items-center imageTopSection relative overflow-hidden lg:overflow-visible bg-[#efe9e9]",
        "cowImage": "w-[55%] h-[60%] lg:w-full lg:h-[45%] child:w-[200%] sm:child:w-[120%] child:max-w-[540px] md:child:max-w-[200%] lg:child:max-w-[540px] child:right-[0] lg:child:right-[20px] child:absolute child:z-[0] md:child:w-[200%] lg:flex lg:items-center",
        "imageWrap1": "lg:w-1/3 lg:relative flex items-center absolute w-[55%] h-[60%] top-[27%] sm:top-[75px] lg:top-auto lg:h-auto sm:h-[75%] cowSection",
        "imageWrap2": "w-full px-[35px] py-[50px] lg:px-0 lg:py-0 lg:w-[calc(33.33%-30px)] mx-15 relative cowContentSection",
        "imageWrap3": "w-full lg:w-1/3 absolute lg:relative imageSection lg:flex-col lg:flex",
        "innerWrap": "relative w-full",
        "button": "Secondarybutton font-RecoletaMedium event_button_click",
        "title": "text-[30px] leading-[34.2px] lg:text-[60px] lg:leading-[75px] text-[#3b2774] sm:text-40 sm:leading-[50px] font-RecoletaMedium",
        "description": "text-[17px] leading-[22px] text-[#3b2774] mt-[10px] lg:text-[25px] lg:leading-[32.5px]"
    }
}