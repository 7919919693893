export const defaultStyle = {
  iconBtnWpr: "",
  iconBtnimg: "",
}

export const INLINEFOOTER = {
  footerlinks:
    "footerlink flex text-center text-white flex justify-center py-20",
  footericon: "px-10",
  ExternalLink: "test2",
}

export const FATFOOTER = {
  footerlinks:
    "footerlink flex text-center text-white flex justify-center py-20",
  footericon: "px-10",
  ExternalLink: "test2",
}

export const socialIconVar = {
  iconBtnWpr:
  "text-center mx-auto my-0 relative w-[223px] text-xl leading-[22px] mb-5",
  iconBtnimg: "absolute right-[-28px] top-[-16px]",
  btnImgwpr: "flex justify-center",
  btnImg: "mx-auto",
  icontxt: "text-[#8d8d8d] text-15 text-center leading-[21px] mt-10",
  iconBtn: "px-10",
}
