import React from "react";
import { screen, render, act, fireEvent } from "@testing-library/react";
import Footer from "./Footer";
import { FooterData } from "../../../mocks/Footer.mock";

describe('Footer', () => {
  let view = null

  beforeEach(() => {
    act(() => {
      window.innerWidth = 1025
      fireEvent(window, new Event("resize"))
    })
    view = render(<Footer {...FooterData} />);
  });

  test('should render component', () => {
    
    expect(screen.getAllByText('MILKA MARKASI'))
  })

  test('should list link collection items accordions', () => {
    const { container } = view
    const div_ele = container.querySelectorAll('.productWrapper')
    expect(div_ele.length).toEqual(1)
  })

  test('should list links under accordions', () => {
    const { container } = view
    const div_ele = container.querySelectorAll('.productWrapper')
    const links = div_ele[0].querySelectorAll('.Listcontainer')
    expect(links.length).toEqual(3)
  })

  test('should render footer text if note props passed', () => {
    view = render(<Footer {...FooterData} note="Some Footer Text" />)
    expect(screen.getAllByText('Some Footer Text'))
  })

  test('should render accordions for mobile view', () => {
    global.innerWidth = 640
    jest.spyOn(window.screen, "width", "get").mockReturnValue(640);
    view = render(<Footer {...FooterData} note="Some Footer Text" />)
    act(() => {
      global.dispatchEvent(new Event('resize'));
    })
    const { container } = view
    const div_ele = container.querySelectorAll('.accordianWrapper')
    expect(div_ele.length).toEqual(1)
  })

  test('should not render accordions for desktop view', () => {
    global.innerWidth = 1280
    jest.spyOn(window.screen, "width", "get").mockReturnValue(1280);
    view = render(<Footer {...FooterData} note="Some Footer Text" />)
    act(() => {
      global.dispatchEvent(new Event('resize'));
    })
    const { container } = view
    const div_ele = container.querySelectorAll('.accordianWrapper')
    expect(div_ele.length).toEqual(0)
  })

})
