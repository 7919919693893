import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography";
import * as styles from "./TimelineWrapper-tw-styles"
import Image from "../UI/Image/Image"

const TimelineWrapper = (props) => {

  const { timelineContentsCollection } = props
  const { items: timelineItems } = timelineContentsCollection || { items: [] }
  const [activeTimeline, setActiveTimeline] = useState("")
  const [inView, setInView] = useState(false)

  const style = styles?.defaultStyle
  const timelineWrpr = useRef(null)
  const sliderControl = useRef(null)
  const isWindow = typeof window !== undefined

  useEffect(() => {
    if (isWindow) {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      if (isWindow) {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, []);

  const scrollTo = (key) => () => {
    const ele = document.getElementById(key).getBoundingClientRect()
    window.scrollTo({
      top: window.scrollY + (ele.top - 80)
    })
  }

  const onScroll = () => {
    if (timelineWrpr?.current && sliderControl?.current) {
      const { top: sTop, bottom: sBottom } = sliderControl.current.getBoundingClientRect()
      const { top: tTop, bottom: tBottom } = timelineWrpr.current.getBoundingClientRect()

      let hasInview = false
      if (tTop < sTop && sBottom < tBottom) {
        hasInview = true
      }

      const activeline = timelineItems.reduce((a, m, i) => {
        const ele = document.getElementById(`timelineItem_header_${i}`)
        const { top } = ele.getBoundingClientRect()
        if (top > 0 && top < 150) {
          a.push(`timelineItem_${i}`)
        }
        return a
      }, [])

      if (activeline.length === 0) {
        if (!hasInview) {
          setActiveTimeline(null)
          setInView(hasInview)
        }
      } else {
        setActiveTimeline(activeline[0])
        setInView(hasInview)
      }
    }
  }

  return (
    <div className={style?.timelineWrpr} ref={timelineWrpr}>
      <div
        className={style?.sliderBtnWrpr}
        ref={sliderControl}
        style={{
          opacity: inView ? 1 : 0
        }}
      >
        {
          timelineItems.map((m, i) => {
            const key = `timelineItem_${i}`
            return (
              <button
                key={key}
                className={`
                  ${style?.sliderBtn} 
                  ${activeTimeline === key ?
                      style[`sliderBtn_${m?.variant}`] || "" :
                      style?.sliderBtnNormal
              }`
                }
                onClick={scrollTo(key)}
              >

              </button>
            )
          })
        }
      </div>
      {
        timelineItems.map((m, i) => {
          return (
            <div
              id={`timelineItem_${i}`}
              key={`timeline_content_${i}`}
              className={`
                  timeline-items
                  ${style?.timelineItemWrpr}
                  ${style[m?.variant] || ""}
              `}
              style={{
                backgroundImage: m?.backgroundImage ?
                                    `url(${m?.backgroundImage?.url})` :
                                    'none'

              }}
            >
              <div className={style?.timelineItemInnerWrpr}>
                <div className={style?.timelineItemLeft}>
                  <div
                    id={`timelineItem_header_${i}`}
                    className={style?.timelineItemTitle}
                  >
                    {m?.title}
                  </div>
                  {m?.description && (
                    <Typography content={m?.description} className={style?.timelineItemDesc} />
                  )}
                </div>
                <div className={style?.timelineItemRight}>
                  <div className={style?.timelineItemImgWrpr}>
                    <Image
                      contentfulImage={m?.image}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

TimelineWrapper.propTypes = {
  timelineContentsCollection: PropTypes.object
}

export default TimelineWrapper

