export const ProductCategoryTagsData = {
  "title": 'Tags',
  "isSlider": true,
  "sliderSettings": {
    "slidesToShowDesktop": 7,
    "slidesToScrollDesktop": 0,
    "slidesToShowMobile": 2,
    "slidesToScrollMobile": 1,
    "slidesToShowTab": 2,
    "slidesToScrollTab": 1
  },
  "linksCollection": {
    "items": [
      {
        "label": "Neu",
        "url": "/alle-produkte/neu"
      },
      {
        "label": "Tafel Schokolade",
        "url": "/alle-produkte/tafel-schokolade"
      },
      {
        "label": "Kekse und Küchlein",
        "url": "/alle-produkte/kekse-und-kuechlein"
      },
      {
        "label": "Pralinen",
        "url": "/alle-produkte/pralinen"
      },
      {
        "label": "Brotaufstrich",
        "url": "/alle-produkte/brotaufstrich"
      },
      {
        "label": "Schoko Snacks",
        "url": "/alle-produkte/schoko-snacks"
      },
      {
        "label": "Schokoriegel",
        "url": "/alle-produkte/schokoriegel"
      }
    ]
  },
  "variant": "CATEGORY_TAGS_SLIDER"
}