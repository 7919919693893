import React from "react";
import { screen, render, fireEvent, act } from "@testing-library/react";
import TimelineWrapper from "./TimelineWrapper";
import { TimelineWrapperData } from "../../mocks/TimelineWrapper.mock";

describe('TimelineWrapper', () => {
  let view = null
  beforeEach(() => {
    let wo = Object.assign(window, {
      scrollX: 0,
      scrollY: 100,
      scrollLeft: 0,
      scrollTop: 100,
    })
    view = render(<TimelineWrapper {...TimelineWrapperData} />)
    act(() => {
      wo.dispatchEvent(new window.Event('scroll'));
    })
  })
  test('should render component', () => {
    expect(screen.getAllByText('1826'))
  })

  test('should render timline items', () => {
    const { container } =view
    const div_ele = container.querySelectorAll('div[class*="timeline-items"]')
    expect(div_ele.length).toEqual(5)
  })

  test('should render timline slider buttons', () => {
    const { container } =view
    const div_ele = container.querySelectorAll('.sliderBtn')
    expect(div_ele.length).toEqual(5)
  })

})