import React from "react";
import { screen, render, act, fireEvent } from "@testing-library/react";
import "@testing-library/jest-dom/extend-expect";
import Header from "./Header";
import { props } from "../../../mocks/header.mock";
import createStore from "../../../redux/store";
import { Provider } from "react-redux";

const store = createStore();

describe("Header Component", () => {
  render(
    <Provider store={store}>
      <Header {...props.headerItems} />
    </Provider>
  );
  let view = null;
  beforeEach(() => {
    view = render(
      <Provider store={store}>
        <Header {...props.headerItems} />
      </Provider>
    );
  });
  test("should render header logo", () => {
    expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument;
  });

  test("should render list of links", () => {
    expect(screen.getAllByText(/PRODUKTE/i)).toBeInTheDocument;
    expect(screen.getAllByText(/NEUES/i)).toBeInTheDocument;
    expect(screen.getAllByText(/ÜBER MILKA/i)).toBeInTheDocument;

    let dataTestHeader = screen.getByTestId("headerContainer")
    fireEvent.scroll(window, { target: { scrollY: 300 } })

    expect(dataTestHeader).toHaveClass("transition-all scrolled")

    fireEvent.scroll(window, { target: { scrollY: 0 } })

    expect(dataTestHeader).toHaveClass("transition-all")
  });

  test("should render mobile screen", () => {
    act(() => {
      window.innerWidth = 499
      fireEvent(window, new Event("resize"))
    })
    render(
      <Provider store={store}>
        <Header {...props.headerItems} />
      </Provider>
    );
    expect(screen.getAllByText(/PRODUKTE/i)).toBeInTheDocument;
    expect(screen.getAllByText(/NEUES/i)).toBeInTheDocument;
    expect(screen.getAllByText(/ÜBER MILKA/i)).toBeInTheDocument;
  })

  test('should render  search input for desktop screen', () => {
    global.innerWidth = 1280
    jest.spyOn(window.screen, "width", "get").mockReturnValue(1280);
    view = render(<Provider store={store}>
      <Header {...props.headerItems} />
    </Provider>)
    act(() => {
      global.dispatchEvent(new Event('resize'));
    })
    const { container } = view
    const div_ele = container.querySelectorAll('.searchInput')
    expect(div_ele.length).toEqual(1)
  })

  test('should able to search', () => {
    global.innerWidth = 1280
    jest.spyOn(window.screen, "width", "get").mockReturnValue(1280);
    view = render(<Provider store={store}>
      <Header {...props.headerItems} />
    </Provider>)
    act(() => {
      global.dispatchEvent(new Event('resize'));
    })
    const { container } = view
    const input_ele = container.querySelectorAll('input[name="search-box"]')[0]
    const button_ele = container.querySelectorAll('span[class*="icon-search"]')[0]
    fireEvent.change(input_ele, { target: { value: 'milka' }})
    fireEvent.click(button_ele)
    expect(window.location.href).toContain('search=milka')
  })

  test('should render hamburger menu for mobile', () => {
    global.innerWidth = 640
    jest.spyOn(window.screen, "width", "get").mockReturnValue(640);
    view = render(<Provider store={store}>
      <Header {...props.headerItems} />
    </Provider>)
    act(() => {
      global.dispatchEvent(new Event('resize'));
    })
    const { container } = view
    const button_ele = container.querySelectorAll('span[class*="hamburger-menu"]')
    fireEvent.click(button_ele[0])
    expect(button_ele.length).toEqual(1)
  })

  test('should event fired for blur and onfocus for mobile', () => {
    global.innerWidth = 640
    jest.spyOn(window.screen, "width", "get").mockReturnValue(640);
    view = render(<Provider store={store}>
      <Header {...props.headerItems} />
    </Provider>)
    act(() => {
      global.dispatchEvent(new Event('resize'));
    })
    const { container } = view
    const input_ele = container.querySelectorAll('input[name="search-box"]')[0]
    fireEvent.focus(input_ele)
    fireEvent.blur(input_ele)
  })

});
