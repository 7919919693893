import React from "react";
import { render, screen, fireEvent } from "@testing-library/react";
import ImageLayout from "./ImageLayout";
import { ImageLayoutData } from "../../mocks/ImageLayout.mock";

describe("ImageLayout", () => {
  let view = null;
  beforeEach(() => {
    view = render(<ImageLayout {...ImageLayoutData} />);
  });

  test("should render Intial Data", () => {
    view
  });

  test("should render component", () => {
    expect(screen.getAllByText(ImageLayoutData.heading))
  });

  test("should render description", () => {
    expect(screen.getAllByText(ImageLayoutData.description))
  });

  test("should render Link", () => {
    const { container } = view;
    const div_ele_style = container.querySelectorAll('a[href="/uber-milka/hohe-tauern/"]')
    expect(div_ele_style.length).toEqual(1);
  });
  
  test('should render Image and src to be true', () => {
    const imageElement = document.querySelector('img')
    expect(imageElement.src).toContain("https://images.ctfassets.net/oqg67vmf6aj9/1aoyEV1AtTuxNw1urTKAhs/beba6346dfcefe9f6cb1abf34f592a98/nav-almauftrieb.jpg")
  })

  test("should add active class on mouse enter", () => {
    view = render(<ImageLayout {...ImageLayoutData} />);
    const { container } = view
    const header_ele = container.querySelectorAll('.listItem')[0]
    fireEvent.mouseEnter(header_ele)
    expect(screen.getAllByText('Almauftrieb'))
  });

  test("should add active class based on window location", () => {
    global.location = new URL('http://localhost/uber-milka/hohe-tauern/almauftrieb')
    view = render(<ImageLayout {...ImageLayoutData} />);
    const { container } = view
    const header_ele = container.querySelectorAll('.listItem')[0]
    fireEvent.mouseLeave(header_ele)
    expect(screen.getAllByText('Almauftrieb'))
  });

})