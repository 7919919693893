export const SearchInputProps = {
  "placeholder": "ARA",
  "style": {
    "formContainer": "flex overflow-hidden searchInput print-noshow bg-white rounded-20 border-2 px-8 border-borderGrey py-[0.2rem] -translate-x-[7.5rem] cursor-pointer w-100 ",
    "form": "ml-[7px] w-full",
    "image": "z-[9] lg:h-15",
    "input": "outline-none bg-transparent text-15 placeholder-darkBlue max-w-full z-5 placeholder-opacity-[0.5]"
  },
  "searchTerm": "true",
  "autoComplete": true,
  "showInput": true,
}