export const defaultStyle = {
  richTextWrapper: "richTextWrapper text-left pt-[150px] relative lg:max-w-[1170px] mx-auto pb-50 px-20 lg:px-0 overflow-hidden font-GoodHeadlinePro [&>ul]:pl-20",
  title: "title mx-0 text-white font-GoodHeadlinePro font-bold !text-[30px] leading-[1.25em] md:!text-[48px] md:leading-[1.05em]",
  mainLink: "mainLink text-white",
}

export const STATIC_CONTENT_TEXT = {
  richTextWrapper: "richTextWrapper text-left pt-[150px] relative lg:max-w-[1170px] mx-auto pb-50 px-20 lg:px-0 overflow-hidden",
  title: "title mx-0 text-white font-GoodHeadlinePro !text-[30px] leading-[1.25em] md:!text-[48px] md:leading-[1.05em]",
  mainLink: "mainLink text-white",
}
// export const FOOT_NOTE = {
//   richTextWrapper: "text-left mb-20px lg:w-[972px] lg:my-[45px] lg:mb-[85px] m-10 lg:mx-auto my-0 opacity-50 text-15 tracking-[0] leading-[20px] md:mx-40 ",
// }

// export const INGREDIENTS_TEXT = {
//   richTextWrapper: "text-left ingredientsText mt-40px mb-20px lg:w-[1170px] lg:my-[45px] mx-auto my-0 hidden",
// }
// export const TEXT_CONTAINER_SECTION = {
//   richTextWrapper: "rangeINgredTxt sm:px-8 pt-[50px] lg:pt-[40px] pb-[25px] lg:w-[1170px] text-[15px] lg:my-[45px] mx-auto my-0 text-center ",
//   title: "",
// }
// export const COMPANY_DETAILS = {
//   richTextWrapper: "richTextWrapper text-left sm:px-8 pt-[50px] lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] mx-15 lg:mx-auto my-0",
//   title: "text-center text-25 leading-25 py-25 tracking-[1px]",
//   code: "",
//   paragraph: "paragraph",
//   list: "",
//   ulList: "",
//   mainLink: "",
//   link: "text-errorRed",
// }
// export const CORPORATE_DETAILS = {
//   richTextWrapper:
//     "richTextWrapper COMPANY_DETAILS text-left md:px-0 px-8 lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] mx-15 lg:mx-auto my-0",
//   title: "text-center text-30 leading-30 pb-20 pt-5 tracking-[1px]",
//   code: "code",
//   paragraph: "text-errorRed",
//   list: "",
//   ulList: "",
//   mainLink: "mainLink",
//   link: "text-errorRed",
// }

// export const COOKIES_INFO = {
//   richTextWrapper: "richTextWrapper text-left lg:px-8 px-8 lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] lg:mx-auto my-0 md:px-30 COOKIES_INFO",
//   title: "text-center text-25 leading-25 py-25 tracking-[1px] ",
//   code: "",
//   paragraph: "paragraph",
//   list: "list pb-[1.1em] list-none tracking-[0]",
//   ulList: "listui",
//   mainLink: "",
//   link: "",
//   table: "border-separate border-spacing-0 curved-table lg:mb-30 mb-10",
//   tRow: "rounded-[0px]",
//   tHeader: "bg-greyLightSmoke  text-14 first:rounded-tl-[5px] last:rounded-tr-[5px]",
//   tData: "",
//   mainLink: "mainLink buttonEffect2 text-white",
//   tableCls: "mb-[32px]",
// }

// export const TEXT_BOX_WITH_BUTTON = {
//   richTextWrapper: "sm:px-8 PhillyFiveHdng richTextWrapper pb-[25px] lg:w-[720px] text-[21px] lg:text-[25px] lg:mb-[45px] lg:mx-auto mx-[2%] my-0 text-center",
//   btnWrpr: "mt-20 buttonEffect2 ",
//   title: "title",
//   Heading: "text-[40px]",
//   mainLink: "buttonEffect2 lg:mt-20 ",
//   contentDesc: "lg:text-[25px] text-[21px] leading-8 pt-3 mb-30 text-[#7f7f7f]",
// }

// export const TEXT_BOX_WITH_BUTTON_SPACE = {
//   richTextWrapper: "sm:px-8 PhillyFiveHdng richTextWrapper pb-[25px] lg:w-[720px] text-[21px] lg:text-[25px] lg:mb-[45px] lg:mx-auto mx-[10%] my-0 text-center mt-60",
//   btnWrpr: "mt-20 buttonEffect2 ",
//   title: "title",
//   Heading: "text-[40px]",
//   mainLink: "buttonEffect2 lg:mt-20 ",
//   contentDesc: "lg:text-[25px] text-[21px] leading-8 pt-3 mb-30 text-[#7f7f7f]",
// }
// export const RICH_TEXT_WRAPPER_IMPRESSUM = {
//   //ingredientsText: " ingredientsText md:text-[48px] md:leading-[1.05em] flex flex-wrap text-[15px] ingredientsText mt-[150px] mb-20px bg-white  lg:w-[1170px] mx-auto py-20 opacity-[.4] text-left lg:max-w-[94%] px-[6%]",
//   richTextWrapper: "richTextWrapper richTextWrapperImpressum text-left pt-[150px] relative lg:max-w-[1170px] mx-auto pb-50 px-20 lg:px-0 font-GoodHeadlinePro",
//   title: "title mx-0 text-white font-GoodHeadlinePro !text-[30px] leading-[1.25em] md:!text-[48px] md:leading-[1.05em]",
//   mainLink: "mainLink text-white",
// }