import React from "react";
import {render, screen, fireEvent} from '@testing-library/react'
import { blocksliderdata, blocksliderimagesdata } from "../../mocks/BlockSlider.mocks";
import BlockSlider from "./BlockSlider";

describe('BlockSlider for text variant', ()=>{
  let view = null
  beforeEach(() => {
    view = render(<BlockSlider {...blocksliderdata}/>)
  })

  test('should render title', () => {
    expect(screen.getAllByText('Uns verbindet viel mehr, als uns trennt'))
  })

  test("showing 1 button ID ", () => {
    let buttonId = screen.getByText('1');
    expect(buttonId).toBeInTheDocument;
  });
  test("showing 2 button ID ", () => {
    let buttonId = screen.getByText('2');
    expect(buttonId).toBeInTheDocument;
  });

  test("showing 3 button ID ", () => {
    let buttonId = screen.getByText('3');
    expect(buttonId).toBeInTheDocument;
  });

  test("should render Description", () => {
    expect(
      screen.findByTitle("Milka zeigt, dass der Austausch zwischen den Generationen zu einem empathischeren Miteinander beitragen kann. Dafür sollten wir uns alle bewusst mehr Zeit nehmen. Denn auch wenn die Unterschiede zwischen Jung und Alt auf den ersten Blick groß wirken: Uns verbindet viel mehr, als uns trennt.\n\nWenn du dir Zeit nimmst, dich mit Menschen aus anderen Generationen auszutauschen, trägst auch du einen Teil dazu bei, dass wir alle empathischer miteinander umgehen. Machst du mit?")
    );
  });

  test('Block Slider Component should render previous button on render', () => {
    const prevButton = view.container.querySelector('.slick-prev')
    expect(prevButton).toBeInTheDocument
  })
  test('Block Slider Component should render next button on render', () => {
    const nextButton = view.container.querySelector('.slick-next')
    expect(nextButton).toBeInTheDocument
  })
})

describe('BlockSlider for images variant', ()=>{
  let view = null
  beforeEach(() => {
    view = render(<BlockSlider {...blocksliderimagesdata}/>)
  })

  test('It should render BlockSlider correctly', () => {
    expect(screen.getByText(/Awards/)).toBeInTheDocument
  })

  test('Block Slider Component should render previous button on render', () => {
    const prevButton = view.container.querySelector('.slick-prev')
    expect(prevButton).toBeInTheDocument
  })
  test('Block Slider Component should render next button on render', () => {
    const nextButton = view.container.querySelector('.slick-next')
    expect(nextButton).toBeInTheDocument
  })
})
