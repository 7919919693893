
export const WrapperContainer = {
  listContainer: "container xl:container max-w-[1200px] flex flex-wrap mx-auto justify-center",
}
export const ImageCardStyles = {
  imageBoxWrpr: "imageBoxWrpr w-full m-[15px] bg-[#724e8d0d] rounded-lg overflow-hidden max-w-[360px] relative",
  imgContainer: "imgContainer flex w-full h-full max-h-[290px] items-center justify-center",
  imgWrpr: " imgWrpr w-auto max-h-[78%] mx-auto",
  contentWrapr: "h-[100px] flex justify-center items-center bg-[#4f2170] px-[1rem] text-center",
  heading: "text-white text-2xl font-bold",
  linkWrpr: "absolute w-full h-full left-0 top-0 rounded-lg"
}