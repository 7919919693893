const query = (id, isPreview) => `{
    productCategoryTags(id: "${id}", preview: ${isPreview}) {
        title
        isSlider
        sliderSettings{
          slidesToShowDesktop
          slidesToScrollDesktop
          slidesToShowMobile
          slidesToScrollMobile
          slidesToShowTab
          slidesToScrollTab
        }
       
        linksCollection {
          items {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
          }
        }
        variant
      }
    }
  
  `
module.exports = { query }
  