export const DynamicTableData ={
        "rowHeading": [
            "NÄHRWERTE",
            "Pro 100 g",
            "Pro 15 g"
        ],
        "rowData": [
            {
                "NÄHRWERTE": "Energie (Brennwert)",
                "Pro 100 g": "2251 kJ /<br> 539 kcal",
                "Pro 15 g": "338 kJ /<br> 81 kcalg"
            },
            {
                "NÄHRWERTE": "Eiweiß",
                "Pro 100 g": "9.1g",
                "Pro 15 g": "1.4g"
            },
            {
                "NÄHRWERTE": "Kohlenhydrate",
                "Pro 100 g": "54.0g",
                "Pro 15 g": "8.1g"
            },
            {
                "NÄHRWERTE": "davon Zucker",
                "Pro 100 g": "51.0g",
                "Pro 15 g": "7.7g"
            },
            {
                "NÄHRWERTE": "Fett",
                "Pro 100 g": "31.0g",
                "Pro 15 g": "4.7g"
            },
            {
                "NÄHRWERTE": "davon gesättigte Fettsäuren",
                "Pro 100 g": "15.0g",
                "Pro 15 g": "2.3g"
            },
            {
                "NÄHRWERTE": "Ballaststoffe",
                "Pro 100 g": "2.7g",
                "Pro 15 g": "0.4g"
            },
            {
                "NÄHRWERTE": "Salz",
                "Pro 100 g": "0.28g",
                "Pro 15 g": "0.04g"
            }
        ],
        "variant": "product_detail_table"
    }