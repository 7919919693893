export const ListComponentProps = {
  linksCollection: [
    {
      url: "https://localhost:8000/",
      name: "Link 1",
      label: "Link 1",
      title: "Link 1",
      target: "_Blank",
      isAnchorTag: true,
      scrollToElementId: null,
      imageType: null,
    },
    {
      url: "https://localhost:8000/",
      name: "Link 2",
      label: "Link 2",
      title: "Link 2",
      target: "_Blank",
      isAnchorTag: true,
      scrollToElementId: null,
      imageType: null,
    },
    {
      url: "https://localhost:8000/",
      name: "Link 3",
      label: "Link 3",
      title: "Link 3",
      target: "_Blank",
      isAnchorTag: true,
      scrollToElementId: null,
      imageType: null,
    },
  ],
  label: "List Component",
};
