import React, {useState} from 'react';
import ImageCard  from '../UI/ImageCard/ImageCard'
import PropTypes from "prop-types";
import * as styled from './ProductListingCards-tw-styles';

const ProductListingCards = props =>{
  const { listing, numberOfProducts} =  props;
  const records = [];
  listing?.slice(0,numberOfProducts)?.map((elem,index)=>
    records.push(<ImageCard {...elem} key={index} style={styled.ImageCardStyles}/>)
  ) 
  return(
    <div className={styled.WrapperContainer.listContainer}>
      {records}
    </div>
  )
}

ProductListingCards.propTypes = {
  listing:PropTypes.PropTypes.array,
  numberOfProducts:PropTypes.number
}

export default ProductListingCards;