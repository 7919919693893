const query = (id, isPreview) => `{
    homePageCtc(id: "${id}", preview: ${isPreview}) {
        title
        description
        buttonText
        link {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
            image {
              title
              description
              url
              width
              height
            }
        }
        backgroundImage{
            title
            description
            contentType
            fileName
            size
            url
            width
            height
            
        }
        image {
            internalTitle
            altText
            imageDesktop {
                title
                description
                url
                width
                height
            }
            imageMobile {
                title
                description
                url
                width
                height
            }
            bgDesktop {
                title
                description
                url
                width
                height
            }
            bgMobile {
                title
                description
                url
                width
                height
            }
       }
        imagesCollection {
            items {
                title
                description
                contentType
                fileName
                size
                url
                width
                height
            }
        }
    }
}`
module.exports = { query }
