export const ctaBlockData ={
    
        "heading": "Wie hat das Milka Skisponsoring begonnen?",
        "shortDescription": "Wäre die FIS Alpine Ski WM in Sierra Nevada 1995 nicht abgesagt worden",
        "image": {
            "title": "Blue Background  Image",
            "description": "Blue Background  Image",
            "url": "https://testurl.jpg",
            "width": 1440,
            "height": 165
          },
          "socialLinksCollection": {
            "__typename": "CtaBlockSocialLinksCollection",
            "items": [
                {
                    "sys": {
                        "id": "5h3Ou94ixHFYUKXeFSXvw7"
                    },
                    "label": "NEUES",
                    "title": "NEUES",
                    "url": "/neues",
                    "target": "_self",
                }
            ]
        },
        "variant": "TEXT_WITH_SOCIAL_LINKS"
      
}
