import { labels } from "../constants/label.constants"
import { pageSlugs } from "../constants/pageType.constants"

export function formatPrdName(name) {
  const formatName = name?.split(labels.philadelphia)
  return labels.philadelphia
    .concat("<strong>")
    .concat(formatName && formatName[1])
    .concat("</strong>")
}

const getRecipeName = name => {
  let modifinedname = name.length > 24 ? name.substring(0, 23) + "..." : name
  return modifinedname
}

export const buildCards = (recipeArray, variant) => {
  let items = []
  recipeArray &&
    recipeArray.map((recipe, index) => {
      let cards = {
        title: getRecipeName(recipe.RecipeName),
        variant: variant || "Similar_Recipe_variant",
        image: {
          url: recipe?.PhotoURL || recipe?.PhotoURLAdditional?.PhotoLink,
          width: 400,
          height: 400,
          title: null,
          description: null,
        },
        link: {
          target: "_self",
          url: `${pageSlugs.SEARCH_PAGE}${recipe.SeoPageName}`,
          gaEventLabel: recipe.RecipeName,
        },
      }
      items.push(cards)
    })
  return items
}

export const buildImageCardsObject = (recipeArray, variant = "") => {
  let imageCardsObject = {
    cardsCollection: { items: buildCards(recipeArray, variant) },
  }
  return imageCardsObject
}

export const getIconButtonCollection = socialLinks => {
  return socialLinks.filter(link => {
    return (
      link.label.toLowerCase() == labels.instagram ||
      link.label.toLowerCase() == labels.mail
    )
  })
}

export const getbuttonCollection = socialLinks => {
  return socialLinks.filter(link => {
    return (
      link.label.toLowerCase() == labels.print ||
      link.label.toLowerCase() == labels.bookmark
    )
  })
}

export const getIconButtonCollectionMobile = socialLinks => {
  return socialLinks.filter(link => {
    return (
      link.label.toLowerCase() == labels.instagram ||
      link.label.toLowerCase() == labels.whatsapp ||
      link.label.toLowerCase() == labels.mail
    )
  })
}

export const getURL = ({ iconName, title, url }) => {
  const isMobile =
    typeof window !== "undefined"
      ? window?.navigator?.userAgentData?.mobile
      : ""
  const whatsappUrl = isMobile ? "whatsapp://" : "https://web.whatsapp.com://"
  const location = typeof window !== "undefined" ? window?.location?.href : ""
  let href = ""
  switch (iconName && iconName.toLowerCase()) {
    case "instagram":
      href = `${labels.instagramHref}`
      break
    case "mail":
      href = `mailto:?subject=${labels.mailSubject}&body=${labels.mailBody}${location}`
      break
    case "whatsapp":
      href = `${whatsappUrl}send?text=${labels.mailBody}${location}${labels.happyCooking}`
    default:
      break
  }
  return href
}
