export const ImageCardProps = {
  title: null,
  text: null,
  elementId: null,
  variant: "TWO_COLUMN_IMAGE",
  gaEventClass: null,
  gaEventLabel: null,
  images: {
    internalTitle: "Shop Card6 Image - NEW",
    altText: "img",
    imageDesktop: {
      title: "Star Den",
      description: "",
      url: "https://images.ctfassets.net/oqg67vmf6aj9/2PRuoiSBTf7GTU0WYm1G4n/2ac9f7b071ceb2f147a39a3b7c5e666a/shopCard6.png",
    },
    imageMobile: {
      title: "Star Den",
      description: "",
      url: "https://images.ctfassets.net/oqg67vmf6aj9/7E14suKDcGvoNbiUmAnOyF/3d1c155233d44ec5ae90bb49642d5638/shopCard6.png",
    },
    bgDesktop: null,
    bgMobile: null,
  },
  link: {
    label: "MEHR ERFAHREN",
    title: "MEHR ERFAHREN",
    url: "/neues/haselnusscreme",
    target: "_self",
    scrollToElementId: null,
    gaEventClass: null,
    gaEventLabel: null,
    image: null,
  },
};
